import React from "react";
import { Link } from "react-router-dom";
import Button from "antd/lib/button";
import Modal from "antd/lib/modal";
import Result from "antd/lib/result";

import { DataServiceStatic } from "../../services/DataService";

class DatesOrTooLate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.userId = DataServiceStatic.userGetOnly().id;
  }
  onClose = val => {
    this.setState({ showModal: val });
  };
  onShow = () => {
    this.setState({ showModal: true });
    DataServiceStatic.sendInqueryMsg(
      {
        subject: "Course Inquiry",
        body: "Course Id: " + this.props.id + ", by user:" + this.userId
      },
      (err, ok) => {
        if (ok) {
          setTimeout(() => {
            this.setState({ showModal: false });
          }, 10000);
        }
      }
    );
  };
  render() {
    const { id, tooLate } = this.props;
    //   tooLate = !tooLate;
    return (
      <>
        {!tooLate && (
          <Link to={`/Checkout/${id}`}>
            <div className="PRS-btn">See dates</div>
          </Link>
        )}
        {tooLate && (
          <>
            <p
              style={{
                color: "grey",
                fontSize: "12px",
                marginTop: "15px",
                textAlign: "left",
                lineHeight: "14px"
              }}
            >
              *Currently, the class is closed, but click the button to request
              to open the class
            </p>
            <div className="PRS-btn">
              <Button
                type="link"
                onClick={() => {
                  this.onShow();
                }}
              >
                Request to open
              </Button>
            </div>
          </>
        )}
        <Inquiry
          showModal={this.state.showModal}
          onClose={val => this.onClose(val)}
          id={id}
        />
      </>
    );
  }
}

export default DatesOrTooLate;

class Inquiry extends React.Component {
  onClose = () => {
    this.props.onClose(false);
  };
  render() {
    return (
      <>
        <Modal
          visible={this.props.showModal}
          title="Request to open the class"
          //   onOk={this.handleOk}
          onCancel={this.onClose}
          footer={[
            <Button key="back" onClick={this.onClose}>
              Close
            </Button>
          ]}
        >
          <Result
            status="success"
            title="Thank you!"
            subTitle="We will let teacher know and get back to you!"
          />
        </Modal>
      </>
    );
  }
}
