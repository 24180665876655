import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";

import React from "react";
import "./profileEdit.css";
import EditProfile from "../openCourse/component/EditProfile";
import EditEdu from "../openCourse/component/EditEdu";
import EditSettings from "../openCourse/component/EditSettings";

import history from "../../services/history";

const { Content, Sider } = Layout;

class ProfileEdit extends React.Component {
  constructor() {
    super();
    this.state = {
      collapsed: false,
      selectedSectionKey: "profile"
    };
  }
  handleMenuClick = e => {
    history.push(window.location.pathname + "?section=" + e.key);
    this.setState({ selectedSectionKey: e.key });
  };

  componentDidMount() {
    const search = new URLSearchParams(window.location.search);
    const section = search.get("section");
    if (section) {
      this.setState({ selectedSectionKey: section });
    } else {
      this.setState({ selectedSectionKey: "profile" });
    }
  }

  render() {
    return (
      <div className="profileEditCon container">
        <Layout className="profileEditLayout">
          <Layout>
            <Sider
              style={{ background: "#fff" }}
              breakpoint="md"
              collapsedWidth="0"
              zeroWidthTriggerStyle={{ top: 0 }}
            >
              <Menu
                mode="inline"
                defaultSelectedKeys={this.state.section}
                style={{ height: "", borderRight: 0 }}
              >
                <Menu.Item onClick={this.handleMenuClick} key="profile">
                  <span>Profile</span>
                </Menu.Item>
                <Menu.Item onClick={this.handleMenuClick} key="edu">
                  <span>Occupation</span>
                </Menu.Item>
                <Menu.Item onClick={this.handleMenuClick} key="settings">
                  <span>Settings</span>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout
              key="1"
              style={{ padding: "0px 0 25px 75px", background: "white" }}
            >
              <Content>
                {this.state.selectedSectionKey === "profile" && <EditProfile />}
                {this.state.selectedSectionKey === "edu" && <EditEdu />}
                {this.state.selectedSectionKey === "settings" && (
                  <EditSettings />
                )}
              </Content>
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default ProfileEdit;
