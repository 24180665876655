import React from "react";

class Responsibility extends React.Component {
  render() {
    return (
      <div className="responsibility-con">
        <div className="policy-title">
          <h2>Tutor and Student Responsibility</h2>
        </div>
        <div>
          <h3>Tutors’ Responsibilities </h3>
          <p>
            1) Tutor must follow the terms provided by Donanum. They are
            responsible for updating identity information, class registration,
            the management of transaction processes, the execution of the class
            for the Student— or the refund— in accordance with the terms and
            conditions set forth by the company.
          </p>
          <p>
            2) The Tutor must respond to the member's questions sincerely and
            within a reasonable timeframe.
          </p>
          <p>
            3) The Tutor is responsible for working overtime for the first
            class, if necessary. (Overtime Terms and Conditions > )
          </p>
          <p>
            4) Tutors may cancel transactions if the Student's conduct is
            inappropriate, illegal, or otherwise contradicts the terms and
            conditions. However, if the Tutor cancels the class unexpectedly,
            the Tutor is responsible to notify and refund the tuition fee to the
            Student.
          </p>
          <p>
            5) If the Tutor refuses the Student's request to participate in a
            class, the Tutor must notify the student with the reason for refusal
            within 24 hours.
          </p>
          <p>
            6) Once a Student registers for a class, Tutor must confirm the
            Student's registration within 36 hours.
          </p>
          <p>
            7) Tutors must use the registration form provided by Donanum. In the
            case that there is false information or an invalid registration, the
            class may be deleted or canceled. The Tutor is fully responsible for
            the use of the registration form.{" "}
          </p>
          <p>
            8) A Tutor should not do any of the following:
            <ul>
              <li>Include illegal or profane content on a registration form</li>
              <li> Restrict Student rights (return policy, overtime)</li>
              <li>Include fake information (photos, name, etc.). </li>
              <li>Write fake reviews of Students</li>
              <li>
                Make sudden changes to the class, be it in terms of costs, date,
                or material without contacting any participating Student and
                updating the class listing. If a Tutor violates any of the above
                guidelines, Donanum maintains the right to take action (ex.
                terminate the Tutor’s account)
              </li>
            </ul>
          </p>
          <p>
            9) The Tutor may not use the company name, logo, and member's
            personal information for any purpose other than the company's
            designated purpose, or display it in a place other than the
            designated place. The Tutor must bear any legal liability.
          </p>
          <p>
            10) The Tutor is responsible for keeping any and all
            invoices/receipts and all other important documents.
          </p>
          <p>
            11) The Tutor must verify their phone number. If the Tutor changes
            their phone number, they must update the number and verify their new
            phone number immediately.
          </p>
          <p>
            12) The Tutor cannot use the personal information of their Students
            for their profit. If this term is not followed, the Tutor will have
            full legal responsibility and the Tutor indemnify Donanum for any
            expenses incurred (cost and effort).
          </p>

          <p>
            13) The Tutor is obligated to try their best to teach the Student.
            <ul>
              <li>
                The tutor should teach the students with all their heart and
                with full sincerity in every lesson.
              </li>
              <li>
                The Tutor cannot unilaterally change the condition of the class
                (number of classes, tuition fee, time, etc.) without prior
                consent of the student. If Student fails to agree to said
                changes, the Tutor must refund all tuition fees.
              </li>
              <li>
                If the Tutor’s personal information has changed, the Tutor is
                responsible to immediately correct the information.
              </li>
              <li>
                The Tutor should not:
                <ul>
                  <li>Use another user’s account.</li>
                  <li>
                    Collect and store personal information of other users.
                  </li>
                  <li>
                    Impersonate others, including company employees, operators,
                    etc.
                  </li>
                  <li>
                    Commit any act that may disrupt or otherwise damage the
                    operation of the Donanum service.
                  </li>
                  <li>
                    Commit any other violation of the terms or conditions.
                  </li>
                  <li>
                    Allow any third party person attend the class without the
                    Student’s agreement.
                  </li>
                  <li>
                    Perform any act that is judged to be objectively constitute
                    or be at all related to a crime.
                  </li>
                </ul>
              </li>
            </ul>
          </p>
          <p>
            14) If the Tutor unilaterally terminates a class without reason,
            Donanum maintains the right to investigate, take action, and
            possibly suspend membership of the Tutor. If a Student reports the
            Tutor because the Tutor does not have enough knowledge to teach,
            does not prepare the class to a satisfactory level, does not arrive
            on time, or does not receive any form of communication, Donanum will
            send the warning messages:
            <ul>
              <li>
                <span className="policy-bold">
                  1st warning (more than 2 reports from Students):
                </span>{" "}
                Send a warning message. If the class was displayed in a
                “recommended class” area on the website/app, it will be removed.
              </li>
              <li>
                <span className="policy-bold">
                  2nd warning (more than 3 reports from Students):
                </span>{" "}
                for 14 days, the report message(s) will be displayed on the
                Tutor’s profile and class post pages.
              </li>
              <li>
                <span className="policy-bold">
                  3rd warning (more than 10 reports from Students):
                </span>{" "}
                Temporary suspension of membership.
              </li>
              <li>
                <span className="policy-bold">
                  4th warning (more than 20 reports from Students):{" "}
                </span>
                Permanent suspension of membership.
              </li>
            </ul>
          </p>
          <p>
            15) Tutors are forbidden from sharing/selling their account with
            other people. If a Tutor is caught doing this, the Tutor is
            responsible to pay for obstruction of business, defamation, libel,
            company damages, etc. In this case, the Tutor’s account will be
            terminated without notice.
          </p>
        </div>

        {/*  */}
        <hr />
        <div>
          <h3>Tutor registration and Update </h3>

          <p>
            1) Registration Qualifications Anyone who has talents that can be
            taught to others can open the class with the stipulation that the
            Tutor is over 21 years of age at the time of account creation.
          </p>
          <p>
            2) Tutors can open classes so long as they follow the Terms &
            Conditions. However, in order to maintain consumer protection and
            service levels, the Company may limit the Tutor's ability to enroll
            in classes within some categories.
          </p>
          <div className="ric">
            <h4>Required Information for Classes</h4>
            <div>
              <h5 className="policy-bold">1. Tuition fee</h5>
              <p>Tutor must clearly mention tuition fee on the page.</p>
            </div>
            <div>
              <h5 className="policy-bold">2. Class detail</h5>
              <p>
                Tutor must accurately, clearly, and succinctly write a clear
                description of the class, its structure, and all information and
                limitations that a prospective Student would need to join the
                class. If the information is false or overly-exaggerated,
                Donanum reserves the right to close the class without prior
                notice.
              </p>
              <p>
                If Student(s) have already registered to a closed class, Donanum
                will not refund the service fee. Tutors will be responsible to
                provide a full refund to Student(s). If a class is canceled,
                Donanum will send an email to all affected parties.{" "}
              </p>
            </div>
            <div>
              <h5 className="policy-bold">3. Additional Information</h5>
              <ul>
                <li>
                  If there are any extra fees, either required or optional, the
                  Tutor must clearly state it on the post page.
                </li>
                <li>
                  Tutor may edit the page after create page if necessary to
                  provide updates to either Students or prospective Students.
                </li>
                <li>
                  Tutor cannot create duplicate classes by using alternate
                  accounts owned by others.
                </li>
              </ul>
            </div>

            <p>
              Late policy: Tutor must not be late for class. If the Tutor is
              more than 10 minutes late, they cannot claim the one hour charge.
            </p>
          </div>
        </div>
        <hr />
        <div>
          <h3>Students’ Rights and Responsibilities</h3>
          <div>
            <p>
              1) Students have rights to get protection of their personal
              information.{" "}
            </p>
            <p>
              2) Students can search for Tutors and register classes in Donanum.{" "}
            </p>
            <p>
              3) If Tutor cancel the class with personal reasons, Student have
              rights to get refund.{" "}
            </p>
          </div>
          <h4>Student responsibility </h4>
          <div>
            <p>
              1. All Students are required to abide by all of the Terms &
              Conditions and Privacy Policies outlined on our site.
            </p>
            <p>
              2. Students cannot unilaterally change the time, cost, number of
              classes, etc. without discussing with the Tutor.
            </p>
            <p>
              3. Students must ensure that all of their personal information is
              up to date (eg. Email, contact info, etc.).
            </p>
            <p>
              4. If a Tutor submits a report that a Student engages in
              unpleasant language or behaviour, demands bizarre
              non-class-related requests, or otherwise conducts themselves in a
              manner that is detrimental to the course or threatens the
              wellbeing of the Tutor, Donanum may take one of the below actions.
              However, if the situation is or is related to a serious crime,
              police or another appropriate authority will be contacted and the
              Student may be permanently suspended.
              <ul>
                <li>
                  <span className="policy-bold">After 1st report:</span>{" "}
                  Warning.
                </li>
                <li>
                  <span className="policy-bold">After 2nd report:</span> A
                  permanent warning will be applied to the student, visible by
                  all Tutors when said Student registers for a class.
                </li>
                <li>
                  <span className="policy-bold">After 3rd report:</span>{" "}
                  Permanent suspension of membership.
                </li>
              </ul>
            </p>
          </div>
          <h4>Student Rights</h4>
          <div>
            <p>
              1) Donanum will strive to provide the best service to Student
              except in the following situations:
              <ul>
                <li>Service, maintenance, or other exceptional case</li>
                <li>
                  Unforeseen errors due to electrical or communication system
                  failures
                </li>
                <li>Natural disaster, war, or national emergency</li>
                <li>
                  System error due to an unexpectedly high number of Users
                  trying to access service at once
                </li>
              </ul>
            </p>
            <p>
              2) Donanum’s security system maintains User information, but we
              only use that information for running the service. We don’t share
              information except these situations. Please see the Privacy Policy
              for more details.
            </p>
          </div>
        </div>
        <hr />
        <div>
          <h3>Donanum’s Rights </h3>
          <div>
            <p>
              1) Donanum reserves the right to demand the Tutor show an employee
              enrollment, student ID card, degree, diploma, certification, or
              other equivalent credential to ensure proper qualifications for
              teaching a course.
            </p>
            <p>
              2) Donanum reserves the right to terminate an account for these
              reasons:
              <ul>
                <li>User’s personal information is fake</li>
                <li>The account was created by a different person </li>
                <li>If User is caught attempting to share/sell the account </li>
                <li>
                  Donanum will send 3 warning messages before the account is
                  suspended.
                  <ul>
                    <li>
                      <span className="policy-bold">1st warning:</span>{" "}
                      Notification to change the information
                    </li>
                    <li>
                      <span className="policy-bold">2nd warning:</span> Final
                      notice to change the information
                    </li>
                    <li>
                      <span className="policy-bold">3rd warning:</span>{" "}
                      Suspension of account for 30 days
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default Responsibility;
