import React from "react";
import PropTypes from "prop-types";
import Icon from "antd/lib/icon";
import Modal from "antd/lib/modal";
import Upload from "antd/lib/upload";
import notification from "antd/lib/notification";

import { DataServiceStatic } from "../../services/DataService";

const MAX_IMAGE_COUNT = 5;
const MBinBYTES = 1048576;

class UploadWall extends React.Component {
  constructor(props) {
    super(props);
    const fileList = [];
    this.filesNotWelcome = [];
    this.props.files.forEach(file => {
      fileList.push({
        url: file.image,
        status: "done",
        uid: file.id,
        id: file.id
      });
    });

    this.state = {
      previewVisible: false,
      fileList: fileList
      // fileList: [
      // {
      //   previewImage: '',
      //   uid: '-1',
      //   name: 'xxx.png',
      //   status: 'done',
      //   url:
      //     'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
      // },
      // ],
    };
  }
  uploadedImages(data) {
    const fileList = [...this.state.fileList];
    const last = fileList.pop();
    last.url = data.image;
    last.id = data.id;
    last.status = "done";
    fileList.push(last);
    this.setState({ fileList });
  }

  handleCancel = () => this.setState({ previewVisible: false });

  handleRemove = file => {
    console.log("[UploadWall.jsx] remove", file);
    DataServiceStatic.deleteCourseImage(file.id, (err, data) => {
      console.log("[UploadWall.jsx] done", data);
    });
  };
  handlePreview = file => {
    this.setState({
      previewImage: file.url || file.thumbUrl,
      previewVisible: true
    });
  };

  handleChange = ({ fileList }) => this.setState({ fileList });

  render() {
    const { previewVisible, previewImage, fileList } = this.state;
    const uploadButton = (
      <div>
        <Icon type="plus" />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    console.info("xxx fl", fileList);

    const filteredFiles = [];
    fileList.forEach(f => {
      if (f.url) {
        filteredFiles.push(f);
      } else if (f.uid && !this.filesNotWelcome.includes(f.uid)) {
        filteredFiles.push(f);
      }
    });

    console.info("xxx fl filtered", filteredFiles);

    return (
      <div className="clearfix">
        <Upload
          listType="picture-card"
          fileList={filteredFiles}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          onRemove={this.handleRemove}
          beforeUpload={file => {
            if (file.size > MBinBYTES) {
              notification["error"]({
                message: "File to large",
                description: "Please select file smaller than 1MB"
              });
              this.filesNotWelcome.push(file.uid);
              console.log("file", file);
              console.info("filelist", this.state.fileList);
              return false;
            }
            return true;
          }}
          customRequest={crData => {
            DataServiceStatic.uploadCourseImage(
              crData.file,
              this.props.courseId,
              (err, data) => {
                if (err) {
                  console.log(err);
                } else {
                  this.uploadedImages(data);
                }
              }
            );
          }}
        >
          {filteredFiles.length >= MAX_IMAGE_COUNT ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </div>
    );
  }
}

export default UploadWall;

UploadWall.defaultProps = {
  files: []
};

UploadWall.propTypes = {
  courseId: PropTypes.number.isRequired,
  files: PropTypes.array
};
