import React from "react";
import { CookieBanner } from "@palmabit/react-cookie-law";

const CookieText = () => (
  <div>
    <CookieBanner
      message="By clicking “Accept, you agree to the storing of cookies on your device to enhance site navigation,
      analyze site usage, and assist in our marketing efforts. For more information, see our Privacy Policy."
      onAccept={() => {}}
      showPreferencesOption={false}
      showStatisticsOption={false}
      onAcceptPreferences={() => {}}
      onAcceptStatistics={() => {}}
      onAcceptMarketing={() => {}}
      policyLink={"/Policy"}
      styles={{
        dialog: { top: "initial" },
        button: { top: "initial" }
      }}
    />
  </div>
);

export default CookieText;
