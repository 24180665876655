/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Drawer from "antd/lib/drawer";
import { connect } from "react-redux";
import { Link, Route, Switch } from "react-router-dom";
import LoginLink from "../../components/modals/Login";
import RegisterLink from "../../components/modals/Register";
import AboutUs from "../aboutUs/AboutUs";
import ApproveMark from "../approveMark/ApproveMark";
import GetApproveMark from "../approveMark/GetApproveMark";
import BeTutorInfo from "../beTutorInfo/BeTutorInfo";
import Checkout from "../checkout/Checkout";
import Confirmation from "../confirmationPage/ConfirmationPage";
import ContactUs from "../contactus/ContactUs";
import CourseList from "../courseList/CourseList";
import ErrorPage from "../errorPage/404";
import EventPage from "../eventPage/EventPage";
import Policy from "../footer/Policy";
import Help from "../help/Help";
import Logo from "../../images/logo/donanum-logo-v4.png";

import IndexPage from "../indexPage/IndexPage";
import MessageIndex from "../message/MessageIndex.1";
import MessageThread from "../message/MessageThread.1";
import Curriculum from "../openCourse/component/Curriculum";
import CourseType from "../openCourse/CourseType";
import OpenCourse from "../openCourse/OpenCourse";
import OpenMentoring from "../openCourse/OpenMentoring";
import PostPage from "../postPage/PostPage";
import ProfileEdit from "../profile/ProfileEdit";
import UserProfile from "../profile/UserProfile";
import Search from "../search/Search";
// import Library from "../spaceInfo/places/Library";
// import SpaceInfo from "../spaceInfo/SpaceInfo";
import MessageDetail from "../message/MessageDetail";
import ActivateDetail from "../activation/ActivateDetail";
import PasswordReset from "../activation/PasswordReset";
import LoginRequiredPage from "../loginRequiredPage/LoginRequiredPage";
import { MessageNotification } from "../message/MessageNotification";

import history from "../../services/history";

import "./nav.css";

import { Nav } from "./NavAntd";

import { DataServiceStatic, getUserImage } from "../../services/DataService";

class myNav extends React.Component {
  constructor(props) {
    super(props);
    this.root = document.getElementById("root");
    this.body = document.getElementsByTagName("body")[0];
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      profile_image: "",
      container: "container",
      fixedHeader: true
    };

    DataServiceStatic.loadUserIfTokenFound();
  }
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  checkURL = () => {
    const path = window.location.pathname.toUpperCase();
    if (path === "/IndexPage".toUpperCase() || path === "/") {
      this.setState({ container: "container-fluid", fixedHeader: true });
    } else {
      this.setState({ container: "container", fixedHeader: false });
    }
  };
  componentDidMount = () => {
    this.checkURL();
    this.historyListener = history.listen(() => {
      this.checkURL();
    });

    this.menu = document.querySelector('[data-menu="2"]');
    window.addEventListener("scroll", this.handleOnScroll);
    const userData = DataServiceStatic.userGetMySelfCache();
    const profile_image = getUserImage(userData);
    this.setState({ profile_image });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleOnScroll);
    this.historyListener();
  };

  // handle onScroll event
  handleOnScroll = () => {
    const wScroll = window.scrollY;
    if (wScroll > 110) {
      this.menu.classList.add("menubg");
    } else {
      this.menu.classList.remove("menubg");
    }
  };

  menuToggle = open => {
    this.setState({ isOpen: open });
  };

  render() {
    return (
      <div>
        <Drawer
          placement="left"
          onClose={this.toggle}
          visible={this.state.isOpen}
          closable
        >
          <nav className="main-menu-mobile">
            <ul className="menu-d-list" data-font="1">
              <li style={{ marginBottom: "20px" }}>
                <Link to="/IndexPage" onClick={() => this.menuToggle(false)}>
                  <img src={Logo} alt="logo" style={{ width: "100px" }} />
                </Link>
              </li>
              <li>
                <Link to="/IndexPage" onClick={() => this.menuToggle(false)}>
                  <span className="">Home</span>
                </Link>
              </li>
              <li className="mobile-menu-top-bar">
                <Link to="/explore" onClick={() => this.menuToggle(false)}>
                  <span className="special-menu">Classes</span>
                </Link>
              </li>
              <li className=" ">
                <Link to="/explore" onClick={() => this.menuToggle(false)}>
                  <span className="special-menu">Tutoring</span>
                </Link>
              </li>
              <li className=" ">
                <Link to="/explore" onClick={() => this.menuToggle(false)}>
                  <span className="special-menu">Mentoring</span>
                </Link>
              </li>
              <li className="mobile-menu-top-bar">
                <Link to="/BeTutorInfo" onClick={() => this.menuToggle(false)}>
                  <span className="">Open Class</span>
                </Link>
              </li>
              <li className="mobile-menu-top-bar">
                <Link to="/AboutUs" onClick={() => this.menuToggle(false)}>
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/BeTutorInfo" onClick={() => this.menuToggle(false)}>
                  <span className="">For Tutor</span>
                </Link>
              </li>
              <li
                className="moblie-menu-login mobile-menu-top-bar"
                onClick={() => this.menuToggle(false)}
              >
                <LoginLink />
              </li>
              <li onClick={() => this.menuToggle(false)}>
                <LoggedContainer>
                  <MessageNotification />
                </LoggedContainer>
              </li>
              <li onClick={() => this.menuToggle(false)}>
                <NotLoggedContainer>
                  <RegisterLink />
                </NotLoggedContainer>
              </li>
            </ul>
          </nav>
        </Drawer>
        <Nav
          toggle={this.toggle}
          menuToggle={this.menuToggle}
          fixedHeader={this.state.fixedHeader}
        />
        <div className="menu-bg"></div>
        <Switch>
          <Route path="/IndexPage" component={IndexPage} />
          <Route path="/Search" component={Search} />
          <Route path="/AboutUs" component={AboutUs} />
          <Route path="/OpenCourse" component={OpenCourse} />
          <Route path="/BeTutorInfo" component={BeTutorInfo} />
          <Route path="/explore" component={CourseList} />
          <Route path="/ApproveMark" component={ApproveMark} />
          <Route path="/GetApproveMark" component={GetApproveMark} />
          <Route path="/Help" component={Help} />
          <Route path="/UserProfile" component={UserProfile} />
          <Route path="/ContactUs" component={ContactUs} />
          <Route path="/PostPage" component={PostPage} />
          {/* <Route path="/SpaceInfo" component={SpaceInfo} /> */}
          <Route path="/CourseType" component={CourseType} />
          {/* <Route path="/Library" component={Library} /> */}
          {/* 
          <Route path="/Other" component={Other} /> */}
          <Route path="/Policy" component={Policy} />
          <Route path="/Checkout" component={Checkout} />
          <Route path="/Confirmation" component={Confirmation} />
          <Route path="/EventPage" component={EventPage} />
          <Route path="/ProfileEdit" component={ProfileEdit} />
          <Route path="/Curriculum" component={Curriculum} />
          <Route path="/OpenMentoring" component={OpenMentoring} />
          <Route path="/MessageIndex" component={MessageIndex} />
          <Route path="/Message/:id" component={MessageThread} />
          <Route path="/MessageDetail" component={MessageDetail} />
          <Route path="/activate" component={ActivateDetail} />
          <Route path="/password_reset" component={PasswordReset} />
          <Route path="/LoginRequired" component={LoginRequiredPage} />
          <Route path="/" exact component={IndexPage} />
          <Route path="/ErrorPage" component={ErrorPage} />
          <Route path="*" component={ErrorPage} />
        </Switch>
      </div>
    );
  }
}

export default myNav;

const Logged = props => {
  return <>{props.user.id && <>{props.children}</>}</>;
};

const NotLogged = props => {
  return <>{!props.user.id && <>{props.children}</>}</>;
};

const mapState = state => ({
  user: state.login.user
});

const LoggedContainer = connect(mapState)(Logged);
const NotLoggedContainer = connect(mapState)(NotLogged);
