import React from "react";
import Icon from "antd/lib/icon";
//import { CheckCircleOutlined } from "@ant-design/icons";

import VideoBanner from "../../images/main-video-image.jpg";
import "./videoSection.css";

class VideoSection extends React.Component {
  render() {
    return (
      <div className=" margin-con videobannerSection">
        <div className="flex">
          <div className="text">
            <h2 className="md-title ">Learn New Skills with Confidence</h2>
            <div>
              <div className="flex">
                <Icon
                  type="check-circle"
                  style={{
                    color: "#08c",
                    marginRight: "5px",
                    marginTop: "5px"
                  }}
                />
                <h4 className="sm-title">Flexible</h4>
              </div>
              <p>
                Learning a new skill doesn’t have to interrupt your busy
                schedule. The time, place, and level of your class are
                completely flexible. You can either meet your tutor in person or
                learn online with Donanum Video Chat
              </p>
            </div>
            <div>
              <div className="flex">
                <Icon
                  type="check-circle"
                  style={{
                    color: "#08c",
                    marginRight: "5px",
                    marginTop: "5px"
                  }}
                />
                <h4 className="sm-title">Supportive</h4>
              </div>
              <p>
                You’ll discover amazing tutors in almost any area. They will
                always be available to lend advice and give you the support you
                need.
              </p>
            </div>
            <div>
              <div className="flex">
                <Icon
                  type="check-circle"
                  style={{
                    color: "#08c",
                    marginRight: "5px",
                    marginTop: "5px"
                  }}
                />
                <h4 className="sm-title">Tailored to You</h4>
              </div>
              <p>
                Donanum boasts a wide range of plans and to help you fit your
                goals and budget.
              </p>
            </div>
          </div>
          <div className="imageBox">
            <img src={VideoBanner} className="" alt="VideoBanner" />
          </div>
        </div>
      </div>
    );
  }
}
export default VideoSection;
