import {
  FacebookShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  EmailShareButton,
  FacebookIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon
} from "react-share";
import Icon from "antd/lib/icon";
import Popover from "antd/lib/popover";

import React from "react";

const body = url => (
  <>
    <FacebookShareButton url={url}>
      <FacebookIcon size={32} round />
    </FacebookShareButton>
    <LinkedinShareButton url={url}>
      <LinkedinIcon size={32} round />
    </LinkedinShareButton>
    <WhatsappShareButton url={url}>
      <WhatsappIcon size={32} round />
    </WhatsappShareButton>
    <EmailShareButton url={url}>
      <EmailIcon size={32} round />
    </EmailShareButton>
  </>
);

const Share = ({ url }) => {
  return (
    <div className=" shareIcon">
      <Popover content={body(url)}>
        <Icon type="share-alt" /> Share
      </Popover>
    </div>
  );
};

export default Share;
