import React from "react";
import PropTypes from "prop-types";

class TextWidgetPreview extends React.Component {
  render() {
    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: this.props.value }} />
      </div>
    );
  }
}
export default TextWidgetPreview;

TextWidgetPreview.defaultProps = {
  value: "<span></span>"
};

TextWidgetPreview.propTypes = {
  value: PropTypes.string
};
