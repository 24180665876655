import React from "react";
import Modal from "antd/lib/modal";
import PropTypes from "prop-types";

import {
  CategoryFilter,
  LanguageFilter,
  ClassTypeFilter,
  PriceFilter,
  DateFilter,
  LocationFilter,
  PriceFilterButtons
} from "./Filters";

import "./FilterModal.css";

class FilterModal extends React.Component {
  constructor() {
    super();
    this.filterData = [];
    this.type = "";
    this.multi = {};

    this.closeModal = this.closeModal.bind(this);
  }
  onClear() {
    const payload = {
      type: this.props.filterType,
      criteria: []
    };
    this.filterData = [];
    this.props.onClose(payload);
  }
  onApply() {
    let payload = {};
    if (this.props.filterType === "multi") {
      payload.filters = this.multi;
      payload.multi = true;
    } else {
      payload = {
        type: this.props.filterType,
        criteria: this.filterData
      };
    }
    this.props.onClose(payload);
  }
  closeModal(hasPayload = true) {
    let payload = {};
    if (this.props.filterType === "multi") {
      payload = this.multi;
    } else {
      payload = {
        type: this.props.filterType,
        criteria: this.filterData
      };
    }
    if (hasPayload) {
      this.props.onClose(payload);
    } else {
      this.props.onClose(null);
    }
  }
  onFilterSelect(data, type, multi = false) {
    if (multi) {
      this.multi[type] = {
        type,
        criteria: data
      };
    } else {
      this.type = type;
      this.filterData = data;
    }
  }
  getFilter(type) {
    const res = this.props.filter.find(f => f.type === type) || {};
    return res;
  }
  getComponent(type) {
    let thisFilter = {};
    if (this.props.filter) {
      thisFilter = this.props.filter.find(f => f.type === type) || {};
    }
    if (type) {
      switch (type) {
        case "category":
          return (
            <CategoryFilter
              onSelect={data => this.onFilterSelect(data, "category")}
              filter={thisFilter}
            />
          );
        case "language":
          return (
            <LanguageFilter
              onSelect={data => this.onFilterSelect(data, "language")}
              filter={thisFilter}
            />
          );
        case "classtype":
          return (
            <ClassTypeFilter
              onSelect={data => this.onFilterSelect(data, "classtype")}
              filter={thisFilter}
            />
          );
        case "price":
          return (
            <PriceFilter
              onSelect={data => this.onFilterSelect(data, "price")}
              filter={thisFilter}
            />
          );
        case "date":
          return (
            <DateFilter
              onSelect={data => this.onFilterSelect(data, "date")}
              filter={thisFilter}
            />
          );
        case "location":
          return (
            <LocationFilter
              onSelect={data => this.onFilterSelect(data, "location")}
              filter={thisFilter}
            />
          );
        case "multi":
          return (
            <>
              <h3>Location:</h3>
              <LocationFilter
                onSelect={data => this.onFilterSelect(data, "location", true)}
                filter={this.getFilter("location")}
              />
              <div
                style={{
                  borderTop: "1px solid var(--greyBorder)",
                  paddingBottom: "20px",
                  marginTop: "30px"
                }}
              ></div>
              <h3>Price:</h3>
              <PriceFilterButtons
                onSelect={data =>
                  this.onFilterSelect(data, "pricebuttons", true)
                }
                filter={this.getFilter("pricebuttons")}
              />
              <div
                style={{
                  borderTop: "1px solid var(--greyBorder)",
                  paddingBottom: "20px",
                  marginTop: "30px"
                }}
              ></div>
              <h3>Dates:</h3>
              <DateFilter
                onSelect={data => this.onFilterSelect(data, "date", true)}
                filter={this.getFilter("date")}
              />
            </>
          );
        default:
          break;
      }
    }
  }
  render() {
    const isOp = this.props.open;
    const comp = this.getComponent(this.props.filterType);

    return (
      <div>
        <Modal
          destroyOnClose
          visible={isOp}
          title="Filters"
          okText={"Filter"}
          onCancel={() => this.closeModal(false)}
          onOk={() => this.onApply()}
        >
          {comp}
        </Modal>
      </div>
    );
  }
}

export default FilterModal;

FilterModal.defaultProps = {
  filter: null
};

FilterModal.propType = {
  elementIdToBind: PropTypes.element.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  filterType: PropTypes.string.isRequired,
  filter: PropTypes.any
};
