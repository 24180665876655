import React from "react";
import List from "antd/lib/list";
import Avatar from "antd/lib/avatar";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Form from "antd/lib/form";

import { connect } from "react-redux";
import moment from "moment";
import { getUserImage, DataServiceStatic } from "../../services/DataService";
import "./MessageIndex.css";

const { TextArea } = Input;

const MessageThreadIn = ({ thread }) => {
  if (thread) {
    if (!thread.seen_receiver || !thread.seen_sender) {
      setTimeout(() => {
        DataServiceStatic.markReadThread(thread.id);
      }, 500);
    }
  }

  if (!thread) return null;
  const children = thread.messages;
  return (
    <div className="container messageIndexCon">
      {/* <List
        className="demo-loadmore-list"
        itemLayout="horizontal"
        dataSource={[thread.messages]}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={getUserImage(item.from_user_full)} />}
              title={
                <span>
                  {item.from_user_full.full_name} @{" "}
                  {moment(item.created_on).format("YYYY-MM-DD hh:mm A")}{" "}
                </span>
              }
              description={<span>{item.body}</span>}
            />
          </List.Item>
        )}
      /> */}
      {children && (
        <List
          className="demo-loadmore-list"
          itemLayout="horizontal"
          dataSource={children}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={<Avatar src={getUserImage(item.user)} />}
                title={
                  <span>
                    {item.user.full_name} @{" "}
                    {moment(item.created_on).format("YYYY-MM-DD hh:mm A")}{" "}
                  </span>
                }
                description={<span>{item.body}</span>}
              />
            </List.Item>
          )}
        />
      )}
      <Editor threadRoot={thread} />
    </div>
  );
};

const mapState = (state, ownProps) => ({
  thread: state.data.messageThreads.threads.find(th => {
    return Number(th.id) === Number(ownProps.match.params.id);
  }),
  // next line has to come, otherwise componet is not updated
  threads: state.data.messageThreads.threads
});

const mapDispatch = dispatch => ({
  markRead: courseId => dispatch.data.markRead(courseId)
});

const MessageThread = connect(mapState, mapDispatch)(MessageThreadIn);

export default MessageThread;

class Editor extends React.Component {
  constructor(props) {
    super(props);
    console.log("[MessageThread.js] props", props);
    this.user = DataServiceStatic.userGetMySelfCache();
    this.state = {
      submitting: false,
      value: ""
    };
    this.thread = this.props.threadRoot;
  }
  handleChange = e => {
    this.setState({
      value: e.target.value
    });
  };

  handleSubmit = () => {
    if (!this.state.value) {
      return;
    }

    this.setState({
      submitting: true
    });
    const merged = {
      // "course_id": "string",
      // "date_id": "string",
      // "receiver_id": "string",
      thread_id: this.thread.id,
      body: this.state.value
    };

    DataServiceStatic.postMessageThread(merged, (err, data) => {
      this.setState({
        submitting: false,
        value: ""
      });
    });
  };
  render() {
    const { submitting, value } = this.state;
    return (
      <span>
        <EditorInput
          onChange={this.handleChange}
          onSubmit={this.handleSubmit}
          submitting={submitting}
          value={value}
        />
      </span>
    );
  }
}

const EditorInput = ({ onChange, onSubmit, submitting, value }) => (
  <div>
    <div xs={12} sm={6}>
      <Form.Item>
        <TextArea rows={4} onChange={onChange} value={value} />
      </Form.Item>
      <Form.Item>
        <Button
          htmlType="submit"
          loading={submitting}
          onClick={onSubmit}
          type="primary"
        >
          Send Message
        </Button>
      </Form.Item>
    </div>
  </div>
);
