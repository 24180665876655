import Tabs from "antd/lib/tabs";
import React from "react";
import "./polices/policy.css";
import Privacy from "./polices/PrivacyPolicy";
import Refund from "./polices/RefundPolicy";
import Responsibility from "./polices/Responsibility";
import Service from "./polices/ServicePolicy";

const TabPane = Tabs.TabPane;

function callback(key) {
  console.log(key);
}

class Policy extends React.Component {
  render() {
    return (
      <div className="container policy footer-bottom-margin">
        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Terms of Service" key="1">
            <Service />
          </TabPane>
          <TabPane tab="Privacy Policy" key="2">
            <Privacy />
          </TabPane>
          <TabPane tab="Refund" key="3">
            <Refund />
          </TabPane>
          <TabPane tab="Tutors and Student Responsibility" key="4">
            <Responsibility />
          </TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Policy;
