import React from "react";
import "./Verification.css";

import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Radio from "antd/lib/radio";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";

import moment from "moment";

import UploadProfileImg from "../../../components/UploadProfileImg";
import { DataServiceStatic } from "../../../services/DataService";
const { MonthPicker } = DatePicker;

class VerificationInner extends React.Component {
  constructor() {
    super();
    this.state = {
      isSaving: false,
      phoneVerLoading: false,
      phoneVerVisible: false,
      phoneVerSuccess: 0
    };
  }
  componentWillMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    this.setState({ user });
  }

  onPhoneVerCancel = () => {
    this.setState({ phoneVerVisible: false });
  };

  onPhoneVerOk = code => {
    this.setState({ phoneVerLoading: true });
    DataServiceStatic.userSendCode(code, (err, data) => {
      let success = 1;
      if (data.phone_status === "approved") {
        success = 1;
        const user = { ...this.state.user };
        user.phone_status = "approved";
        this.setState({ user });
      }
      if (data.phone_status === "fail") {
        success = 3;
      }
      if (data.phone_status === "pending") {
        success = 3;
      }
      if (err) {
        success = 3;
      }
      this.setState({ phoneVerLoading: false, phoneVerSuccess: success });
      if (success === 1) {
        setTimeout(() => {
          this.setState({ phoneVerVisible: false });
        }, 7000);
      }
    });
  };

  sendTextToVerify = () => {
    this.props.form.validateFields((err, fieldsValue) => {
      this.setState({ phoneVerVisible: true });

      DataServiceStatic.userSendText(fieldsValue.phone, true, (err, data) => {
        console.info("resp", data);
      });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      const endDateTmp = fieldsValue["end_date"];
      let end_date = null;
      // TODO: fix dates, set DD to be first of the month, now it's current day.
      if (endDateTmp) {
        end_date = endDateTmp.format("YYYY-MM-DD");
      }

      const birthdayRaw = fieldsValue["birthday"];
      const birthday = moment(birthdayRaw).format("YYYY-MM-DD");

      const values = {
        ...fieldsValue,
        start_date: fieldsValue["start_date"].format("YYYY-MM-DD"),
        end_date: end_date,
        birthday
      };
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ isSaving: true });
        DataServiceStatic.userUpdateProfile(values, (err, data) => {
          console.log("[Verification.js] data", data);
          this.setState({ isSaving: false });
          if (!err) {
            message.success("Saved!");
          }
        });
      }
    });
  };

  normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  initValHelper = name => {
    if (this.state.user[name]) {
      return this.state.user[name];
    }
    return "";
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const configStart = {
      initialValue: moment(this.state.user.start_date || new Date()),
      rules: [
        { type: "object", required: true, message: "Please select date!" }
      ]
    };
    const endDate = this.state.user.end_date
      ? moment(this.state.user.end_date)
      : null;
    const configEnd = {
      initialValue: endDate,
      rules: [{ type: "object" }]
    };

    const birthday = this.state.user.birthday
      ? moment(this.state.user.birthday)
      : null;
    const configBirthday = {
      initialValue: birthday,
      rules: [{ type: "object", required: true }]
    };

    const still_holding_position2 = this.state.user.still_holding_position
      ? "yes"
      : "no";

    const formItemLayout = {
      labelCol: {
        xs: { span: 6 }
        // sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 16 }
        // sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 4
        }
      }
    };

    return (
      <>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="verificationCon"
        >
          <Form.Item label="Profile Image" className="flex profileImg">
            <UploadProfileImg user={this.state.user} />
            <div>*please use image with your face clearly visible</div>
          </Form.Item>
          <Form.Item label="Birthday" className="flex">
            {getFieldDecorator("birthday", configBirthday)(<DatePicker />)}
          </Form.Item>
          <Form.Item className="flex occupation" label="Occupation">
            {getFieldDecorator("occupation", {
              initialValue: this.initValHelper("occupation") || "WORKING",
              rules: [
                {
                  required: true,
                  message: "Please select occupation"
                }
              ]
            })(
              <Radio.Group>
                <Radio value="WORKING">Working</Radio>
                <Radio value="STUDENT">Student</Radio>
                <Radio value="GRADUATE">Graduate</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item className="w60 flex" label="Company">
            {getFieldDecorator("occupation_company_name", {
              initialValue: this.initValHelper("occupation_company_name"),
              rules: [
                {
                  required: true,
                  message: "Please enter your company name"
                }
              ]
            })(<Input placeholder="ex) UBC or Microsoft" />)}
          </Form.Item>

          <Form.Item className="w60 flex" label="Position or Major">
            {getFieldDecorator("position_name", {
              initialValue: this.initValHelper("position_name"),
              rules: [
                {
                  required: true,
                  message: "Please enter your major"
                }
              ]
            })(<Input placeholder="ex) Software designer" />)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Form.Item
              className="flex"
              label="Start Date"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              {getFieldDecorator("start_date", configStart)(<MonthPicker />)}
            </Form.Item>
            <Form.Item
              className="flex"
              label="End Date"
              style={{ display: "inline-block", width: "calc(50% - 12px)" }}
            >
              {getFieldDecorator("end_date", configEnd)(<MonthPicker />)}
            </Form.Item>
          </Form.Item>

          <Form.Item className="flex" label="Still holding position">
            {getFieldDecorator("still_holding_position", {
              initialValue: still_holding_position2
            })(
              <Row>
                <Col>
                  <Radio.Group defaultValue={still_holding_position2}>
                    <Radio.Button value="yes">Yes</Radio.Button>
                    <Radio.Button value="no">No</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </Form.Item>

          <Form.Item className="w60 flex" label="Linkedin">
            {getFieldDecorator("linkedin_url", {
              initialValue: this.initValHelper("linkedin_url")
            })(<Input addonAfter={<Icon type="setting" />} />)}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              className="next-btn"
              type=""
              htmlType="submit"
              loading={this.state.isSaving}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const Verification = Form.create({ name: "validate_other" })(VerificationInner);

export default Verification;
