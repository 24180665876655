import Icon from "antd/lib/icon";
import Collapse from "antd/lib/collapse";

import React from "react";
import { Col, Row } from "../../ui";
import { Link } from "react-router-dom";
import "./footer.css";
import { DataServiceStatic } from "../../services/DataService";
import history from "../../services/history";

import Logo from "../../images/logo/donanum-logo-color.png";

const { Panel } = Collapse;

const text2 = (
  <ul style={{ paddingLeft: "20px" }}>
    <li>
      <Link to="/Policy">Terms of Service</Link>
    </li>
    <li>
      <Link to="/Policy">Privacy Policy</Link>
    </li>
  </ul>
);
const text3 = (
  <ul style={{ paddingLeft: "20px" }}>
    <li>
      <Link to="/Help">Help</Link>
    </li>
    <li>
      <Link to="/ContactUs">Contact us</Link>
    </li>
    <li>
      <Link to="/AboutUs">About us</Link>
    </li>
  </ul>
);
const text4 = (
  <ul style={{ paddingLeft: "20px" }}>
    <li>
      <a href="www.google.com" target="_blank" rel="noopener noreferrer">
        Blog
      </a>
    </li>
    <li>
      <a href="www.google.com" target="_blank" rel="noopener noreferrer">
        Rent a space
      </a>
    </li>
    <li>
      <a href="www.google.com" target="_blank" rel="noopener noreferrer">
        Open source
      </a>
    </li>
  </ul>
);
class Footer extends React.Component {
  constructor() {
    super();
    this.state = {
      cats: []
    };
  }
  componentDidMount() {
    DataServiceStatic.getLoadCatagories((err, data) => {
      if (!err) {
        const cats = data
          .filter(d => d.parent !== null)
          .map(d => {
            let name = d.title;
            if (d.full_slug.endsWith("other")) {
              name = "Other " + d.full_slug.split("_")[0];
            }
            return { name: name, id: d.id, full_slug: d.full_slug };
          });
        this.setState({ cats });
      }
    });
  }
  onLinkClick = (e, id) => {
    e.preventDefault();
    history.push("/explore/?category=" + id);
  };
  renderCats = () => {
    return (
      <ul className="cats">
        {this.state.cats.map(c => {
          return (
            <li key={c.id}>
              <a
                href={"/explore/?category=" + c.full_slug}
                onClick={e => this.onLinkClick(e, c.full_slug)}
              >
                {c.name}
              </a>
            </li>
          );
        })}
      </ul>
    );
  };
  render() {
    return (
      <footer id="footer">
        <div className="footer-line">
          <div className="footer-width footer-web d-none d-sm-block">
            <Row className="">
              <Col className="footer-cd-con " sm={12}>
                <p className="bold">Categories</p>
                {this.renderCats()}
              </Col>

              <Col className="footer-cd-con" sm={4}>
                <p className="bold">Polices</p>
                <ul>
                  <li>
                    <Link to="Policy">Terms of Service</Link>
                  </li>
                  <li>
                    <Link to="Policy">Privacy Policy</Link>
                  </li>
                </ul>
              </Col>
              <Col className=" " sm={4}>
                <p className="bold">Support</p>
                <ul>
                  <li>
                    <Link to="/Help">Help</Link>
                  </li>
                  <li>
                    <Link to="ContactUs">Contact us</Link>
                  </li>
                  <li>
                    <Link to="AboutUs">About us</Link>
                  </li>
                </ul>
              </Col>
              <Col className="" sm={4}>
                <p className="bold">Community</p>
                <ul>
                  <li>
                    <a
                      href="https://blog.donanum.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Blog
                    </a>
                  </li>
                  {/* <li>
                    <Link to="">Forum</Link>
                  </li> */}
                  <li>
                    <a
                      href="https://blog.donanum.com/space/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Rent a space
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://blog.donanum.com/source/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open source
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </div>
        <div className="d-block d-sm-none">
          <Collapse bordered={false} defaultActiveKey={["0"]}>
            <Panel header="Categories" key="1">
              {this.renderCats()}
            </Panel>
            <Panel header="Polices" key="2">
              {text2}
            </Panel>
            <Panel header="Support" key="3">
              {text3}
            </Panel>
            <Panel header="Community" key="4">
              {text4}
            </Panel>
          </Collapse>
        </div>
        <div className="footer-width copyright-con">
          <div className=" bar">
            <Row>
              <Col xs={12} sm={9}>
                <div className="imgCon footer-logo">
                  <img src={Logo} alt="logo" />
                </div>
                {/* <div className="flex">
                  <p>Privacy Policy </p>
                  <p> | </p>
                  <p> Term of Service</p>
                </div> */}
                <p className="copyright">
                  All Content © 2019 Donanum, Inc. All Rights Reserved.
                </p>
              </Col>
              <Col xs={12} sm={3}>
                <div className="sns-container flex">
                  <a href="https://www.facebook.com/Donanum-111463040548142/">
                    <Icon type="facebook" theme="filled" />
                  </a>
                  <a href="https://www.linkedin.com/in/do-nanum">
                    <Icon type="linkedin" theme="filled" />
                  </a>
                  <a href="https://www.instagram.com/donanum/">
                    <Icon type="instagram" />
                  </a>
                  <a href="https://twitter.com/donanum">
                    <Icon type="twitter" />
                  </a>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
