import React from "react";
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Checkbox from "antd/lib/checkbox";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import message from "antd/lib/message";

import { Col, Row } from "../../ui";
import LoginWithGoogle from "./LoginWithGoogle";
import { DataServiceStatic } from "../../services/DataService";
import { registerTrigger } from "./Register";

import ResetPassword from "./ResetPassword";

class NormalLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }
  componentDidMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    if (user.id) {
      this.afterLogin();
    }
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ loading: true });
        DataServiceStatic.userLogin(
          values.username,
          values.password,
          (err, ok) => {
            if (err) {
              message.error(
                "Cannot login, please check your credentials and try again."
              );
              this.setState({ loading: false });
            } else {
              this.props.form.resetFields();
              DataServiceStatic.userGetMySelf((err, data) => {
                this.afterLogin();
              });
            }
          }
        );
      }
    });
  };
  onCancel() {
    this.afterLogin();
  }

  afterLogin = () => {
    this.setState({ loading: false });
    const searchSimple = window.location.search;
    if (searchSimple.indexOf("?back=") === 0) {
      const newHref = searchSimple.split("back=")[1];
      window.location.replace(window.location.origin + newHref);
    } else {
      window.location.href = "/";
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <LoginInputsAll
        getFieldDecorator={getFieldDecorator}
        handleSubmit={this.handleSubmit}
        loading={this.state.loading}
        registerLink={this.registerLink}
        onCancel={this.onCancel}
        onGoogle={() => this.afterLogin()}
      />
    );
  }
}

export const WrappedNormalLoginForm = Form.create({ name: "normal_login" })(
  NormalLoginForm
);

export const LoginInputs = ({
  handleSubmit,
  getFieldDecorator,
  registerLink,
  loading
}) => {
  return (
    <Spin spinning={loading}>
      <Form onSubmit={handleSubmit} className="login-form" layout="vertical">
        <Form.Item>
          {getFieldDecorator("username", {
            rules: [{ required: true, message: "Please enter your username!" }]
          })(
            <Input
              className="userInput"
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Username"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("password", {
            rules: [{ required: true, message: "Please enter your password!" }]
          })(
            <Input
              className="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Password"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator("remember", {
            valuePropName: "checked",
            initialValue: true
          })(<Checkbox>Remember me</Checkbox>)}
          <ResetPassword />
          <Button
            type="primary"
            htmlType="submit"
            className="login-form-button"
            loading={loading}
          >
            Log in
          </Button>
        </Form.Item>
        <div className="signup-line account-text text-center">
          <p>
            Don’t have an account?{" "}
            <Button
              type="link"
              onClick={() => registerTrigger()}
              className="link"
            >
              Sign up
            </Button>
          </p>
        </div>
      </Form>
    </Spin>
  );
};

export const LoginInputsAll = props => {
  return (
    <div className="">
      <div className="">
        <div>
          {/* <div className="login-btn fb-btn-con flex">
      <div className="fbIcon-con">
        <img src={FBIcon} />
      </div>
      <a> Login with Facebook</a>
    </div> */}
          <LoginWithGoogle onOk={props.onGoogle} />
        </div>
        <div className="orCon">
          <Row>
            <Col xs={5}>
              <div className="bar" />
            </Col>
            <Col xs={2}>
              <p className="center">or</p>
            </Col>
            <Col xs={5}>
              <div className="bar" />
            </Col>
          </Row>
        </div>
        <LoginInputs
          {...props}
          // getFieldDecorator={getFieldDecorator}
          // handleSubmit={handleSubmit}
          // loading={state.loading}
          // registerLink={registerLink}
        />
      </div>
    </div>
  );
};
