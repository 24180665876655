import React from "react";
import { Row } from "../../../ui";

import { Link } from "react-router-dom";
import Empty from "antd/lib/empty";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import moment from "moment";
import { ReviewButton } from "./ReviewButton";

// import ClassesRegistered from "./ClassesRegistered";
import { getUserImage } from "../../../services/DataService";
// import ProfileImage from "../../images/profile.png";

const Tbl = ({ data }) => {
  const columns = [
    {
      key: "course.owner.id",
      dataIndex: "course.owner.id",
      title: "Teacher",
      render: (text, record) => {
        const img = getUserImage(record.course.owner);
        const teacherName =
          record.course.owner.first_name + " " + record.course.owner.last_name;
        return (
          <div>
            <img src={img} className="profile-teacherImg" alt="" />{" "}
            {teacherName}{" "}
          </div>
        );
      }
    },
    {
      key: "title",
      dataIndex: "course.title",
      title: "Title",
      render: (text, record) => (
        <span>
          <Link to={`/PostPage/${record.course.id}`}>{text}</Link>
        </span>
      )
    },
    {
      key: "date_start",
      dataIndex: "date_start",
      title: "Date",
      render: (text, record) => {
        return <>{moment(record.date_start).format("YYYY-MM-DD hh:mm A")}</>;
      }
    },
    {
      key: "review",
      dataIndex: "",
      title: "Review",
      render: (text, record) => {
        return (
          <>
            <ReviewButton
              courseId={record.course.id}
              courseTitle={record.course.title}
              // visible={parseInt(writeReviewId, 10) === parseInt(courseId, 10)}
            />
          </>
        );
      }
    }
  ];

  return <Table dataSource={data} columns={columns} rowKey={"id"} />;
};

const ProfileTabRegistered = ({ scheduleArr, writeReviewId }) => (
  <div className="profile-class-con">
    <div className="justifyRight">
      <Button>
        <Link to="/explore">view more classes</Link>
      </Button>
    </div>
    {scheduleArr.length !== 0 && <Tbl data={scheduleArr} />}
    <Row>
      {scheduleArr.length === 0 && (
        <div className="d-block w-100">
          <Empty
            description={
              <span>
                Hello, there! <a href="#API">Register a class now!</a>
              </span>
            }
          />
        </div>
      )}
      {/* {scheduleArr.map(schedule => {
        const teacherName =
          schedule.course.owner.first_name +
          " " +
          schedule.course.owner.last_name;
        // const last_user_image = schedule.course.owner.user_image.pop();
        // let teacherImageUrl = ProfileImage;
        // if (last_user_image) {
        //   teacherImageUrl = last_user_image.profile_image;
        // }
        const teacherImageUrl = getUserImage(schedule.course.owner);
        return (
          <Col xs={12} sm={6} key={schedule.id}>
            <ClassesRegistered
              courseName={schedule.course.title}
              teacherPhotoUrl={teacherImageUrl}
              teacherName={teacherName}
              startDate={schedule.date_start}
              courseId={schedule.course.id}
              writeReviewId={writeReviewId}
            />
          </Col>
        );
      })} */}
    </Row>
  </div>
);

export default ProfileTabRegistered;
