/* eslint-disable no-unused-vars */
import Icon from "antd/lib/icon";
import Popover from "antd/lib/popover";

import React from "react";
import { Link } from "react-router-dom";
import LevelIcon from "../../images/ver-icon.png";
import { LOCATIONS } from "../../../constants";
import { courseHoursCalc } from "../../../services/Utils";
import { WishHeartpp } from "../../../components/WishHeartpp";
import "./postRegisterSection.css";
import Share from "../share";
import DatesOrTooLate from "../DatesOrTooLate";
import { priceOrFree } from "../../../services/Utils";

class PostRegisterSection extends React.Component {
  render() {
    const corse = this.props.course;
    console.log(corse);
    const {
      id,
      images,
      title,
      time,
      category,
      price,
      rating,
      date,
      address,
      size_max,
      language,
      extra_charge,
      requirement,
      type,
      too_late,
      is_free,
      owner
    } = corse;
    const loc = LOCATIONS.find(l => l.key === address.location);
    const hoursTotal = courseHoursCalc(date, type);
    const url = window.location.href;
    console.log("[PostRegisterSection.js] tooLate x c", corse.too_late);
    return (
      <div className="PostRegisterSection d-none d-lg-block d-xl-block">
        <div>
          <div>
            <div className="flex shareIconCon">
              <div className="category-text">
                <span>{category}</span>
              </div>
              <div className="" style={{}}>
                {id && <WishHeartpp course_id={id} />}
              </div>
              <Share url={url} />
            </div>
            <h2 className="lg-title">{title}</h2>
            {owner.verified && (
              <div className="verifiedBox">
                <span className="flex">
                  <div className="verifiedBox-img">
                    <Icon type="safety" className="verified-icon" />
                  </div>
                  <p>Verified</p>
                </span>
              </div>
            )}
          </div>
          <div className="flex margin-8">
            <div className="iconCon">
              <Icon type="environment" />
            </div>
            <p className="PR-location-con"> {loc ? loc.name : "N/A"}</p>
          </div>
          <div className="flex  margin-8">
            <div className="iconCon">
              <Icon type="clock-circle" />
            </div>
            <p>{hoursTotal} total</p>
          </div>
          <div className="flex margin-8">
            <div className="iconCon">
              <Icon type="global" />
            </div>
            <p>Offered in {language.name}</p>
          </div>

          <div className="flex  margin-8">
            <div className="iconCon">
              <Icon type="team" />
            </div>
            <p> {size_max} people </p>
          </div>
          <div className="PR-price-con">
            <div className="seedate-btn">
              <DatesOrTooLate id={id} tooLate={too_late} />
            </div>
            <h3>{priceOrFree(price, is_free)}</h3>
          </div>
        </div>
      </div>
    );
  }
}
export default PostRegisterSection;
