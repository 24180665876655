import React from "react";
import PropTypes from "prop-types";

import Comment from "antd/lib/comment";
import Avatar from "antd/lib/avatar";
import Tooltip from "antd/lib/tooltip";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Rate from "antd/lib/rate";
import Input from "antd/lib/input";

import moment from "moment";
import { DataServiceStatic, getUserImage } from "../../../services/DataService";

import "./PostReviews.css";

const PostReviews = ({ reviews }) => {
  return (
    <div className="PostReviews">
      {reviews.reverse().map(rev => {
        return (
          <div key={rev.id} className="PostReview">
            <Comment
              author={<span>{rev.author.full_name}</span>}
              avatar={
                <Avatar
                  src={getUserImage(rev.author)}
                  alt={rev.author.full_name}
                />
              }
              content={
                <div>
                  {/* Rating: {rev.rating} */}
                  <Rate value={rev.rating} disabled />
                  <br />
                  <p>{rev.body}</p>
                </div>
              }
              datetime={
                <Tooltip
                  title={moment(rev.created_on).format("YYYY-MM-DD hh:mm A")}
                >
                  <span>{moment(rev.created_on).fromNow()}</span>
                </Tooltip>
              }
            />
          </div>
        );
      })}
    </div>
  );
};

export default PostReviews;

export class PostReviewWrite extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: this.props.visible || false,
      confirmLoading: false,
      text: "",
      rate: 0
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
      text: "",
      rate: 0,
      showThankYou: false
    });
  };

  handleOk = () => {
    this.setState({
      confirmLoading: true
    });
    DataServiceStatic.postReview(
      this.props.courseId,
      this.state.text,
      this.state.rate,
      (err, data) => {
        setTimeout(() => {
          this.setState({
            confirmLoading: false,
            showThankYou: true
          });
          setTimeout(() => {
            this.setState({
              visible: false
            });
          }, 5000);
        }, 500);
      }
    );
  };

  handleCancel = () => {
    console.log("Clicked cancel button");
    this.setState({
      visible: false
    });
  };
  onRateChange = val => {
    this.setState({ rate: val });
  };
  onTextChange = e => {
    this.setState({ text: e.target.value });
  };
  render() {
    const { visible, confirmLoading, text, rate, showThankYou } = this.state;
    return (
      <div>
        <Button
          className="pp-reviewbtn"
          type="secondary"
          onClick={this.showModal}
        >
          Write Review
        </Button>
        <Modal
          title={`${this.props.courseTitle} Review`}
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          okText="Publish!"
          width={500}
          height={400}
        >
          <div>
            Rate: <Rate onChange={this.onRateChange} value={rate} />
            <Input.TextArea
              rows={10}
              onChange={this.onTextChange}
              value={text}
              disabled={showThankYou}
            />
            {showThankYou && <h3>Thank you! Review is pending for review.</h3>}
          </div>
        </Modal>
      </div>
    );
  }
}

PostReviewWrite.defaultProps = {
  visible: false
};

PostReviewWrite.propTypes = {
  visible: PropTypes.bool,
  courseId: PropTypes.number.isRequired,
  courseTitle: PropTypes.string.isRequired
};
