import React from "react";
import "./Verification.css";

import Form from "antd/lib/form";
import message from "antd/lib/message";
import Checkbox from "antd/lib/checkbox";

import { DataServiceStatic } from "../../../services/DataService";

class EditSettingsInner extends React.Component {
  constructor() {
    super();
    this.state = {
      isSaving: false,
      settings: []
    };
  }
  componentWillMount() {
    DataServiceStatic.loadUserSettings((err, data) => {
      console.log("data", data);
      this.setState({ settings: data });
    });
  }

  onChange = (name, val) => {
    console.log("name", name, val);
    const obj = this.state.settings.find(s => s.key === name);
    obj.value = val ? "1" : "0";
    this.setState({ isSaving: true });

    DataServiceStatic.updateUserSettings(obj.id, obj, (err, data) => {
      console.log("[EditSettings.js] data", data);
      const settings = this.state.settings.map(s => {
        if (s.name === name) {
          return data;
        }
        return s;
      });

      this.setState({ settings, isSaving: false });
      if (!err) {
        message.success("Saved!");
      }
    });
  };

  handleSubmit = e => {
    e.preventDefault();
  };
  initValHelper = name => {
    const st = this.state.settings.find(s => s.key === name);
    if (st) {
      console.log("val?", st);
      return st.value.toLowerCase() === "1";
    }
    return false;
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 4 }
        // sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 18 }
        // sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 0
        }
      }
    };

    return (
      <>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="EdiSettingsCon"
        >
          <Form.Item {...tailFormItemLayout}>
            <Checkbox
              checked={this.initValHelper("marketing_emails")}
              onChange={val =>
                this.onChange("marketing_emails", val.target.checked)
              }
              style={{ lineHeight: "24px" }}
            >
              I agree to get marketing promotions, special offers, inspiration,
              and policy update via email.
            </Checkbox>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const EditSettings = Form.create({ name: "validate_other" })(EditSettingsInner);

export default EditSettings;
