import Modal from "antd/lib/modal";
import Input from "antd/lib/input";
import Button from "antd/lib/button";

import PropTypes from "prop-types";
import React from "react";
import { DataServiceStatic } from "../../services/DataService";
import { store } from "../../services/EventBus";

export class MessagePopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      text: ""
    };
  }
  showModal = () => {
    this.setState({
      visible: true,
      text: "",
      showThankYou: false
    });
    const user = DataServiceStatic.userGetMySelfCache();
    this.from_user = user.id;
    this.to_user = this.props.course.owner.id;
    this.courseId = this.props.course.id;
    this.parent = null;

    const st = store.getState().data.messages.threads;
    if (st) {
      const needle = st.find(s => s.course === this.courseId);
      if (needle) {
        this.parent = needle.id;
      }
    }
  };

  handleOkOld = () => {
    this.setState({
      confirmLoading: true
    });
    const dataIn = {
      course: this.courseId,
      body: this.state.text,
      to_user: this.to_user,
      from_user: this.from_user,
      parent: this.parent
    };
    DataServiceStatic.postMessage(dataIn, (err, data) => {
      setTimeout(() => {
        this.setState({
          confirmLoading: false,
          showThankYou: true
        });
        setTimeout(() => {
          this.setState({
            visible: false
          });
        }, 5000);
      }, 500);
    });
  };
  handleOk = () => {
    this.setState({
      confirmLoading: true
    });
    const dataIn = {
      body: this.state.text,
      course_id: this.courseId,
      receiver_id: this.to_user,
      thread_id: 0
    };

    DataServiceStatic.postMessageThread(dataIn, (err, data) => {
      setTimeout(() => {
        this.setState({
          confirmLoading: false,
          showThankYou: true
        });
        setTimeout(() => {
          this.setState({
            visible: false
          });
        }, 5000);
      }, 500);
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      text: ""
    });
  };
  onTextChange = e => {
    this.setState({ text: e.target.value });
  };
  render() {
    const ownerName = this.props.course.owner.full_name;
    const { visible, confirmLoading, text, showThankYou } = this.state;
    return (
      <div>
        <p className="pp-tutor-name">{ownerName}</p>
        <Button type="secondary" onClick={this.showModal}>
          Contact Tutor
        </Button>
        <Modal
          title={`Message to ${ownerName}`}
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          okText="Send!"
          width={500}
          height={400}
        >
          <div>
            {!showThankYou && (
              <Input.TextArea
                rows={10}
                onChange={this.onTextChange}
                value={text}
                disabled={showThankYou}
              />
            )}
            {showThankYou && <h3>Message sent!</h3>}
          </div>
        </Modal>
      </div>
    );
  }
}

MessagePopup.propTypes = {
  course: PropTypes.object.isRequired
};

export default MessagePopup;
