import React from "react";
import { Link } from "react-router-dom";
import Tabs from "antd/lib/tabs";

import RecommendedSectionCourses from "./RecommendedSectionCourses";

const { TabPane } = Tabs;

class RecommendedSection extends React.Component {
  render() {
    const { parentCategory, courseList } = this.props;
    return (
      <div className="RecommendedSectionTab-con margin-con">
        <div className="flex flex-space-between">
          <h2 className="md-title">Recommend Classes</h2>
          <div className="OnedayCarouselThumb-seemore-title">
            <Link to="/explore" className="dash seemore-title">
              Show all >
            </Link>
          </div>
        </div>

        <Tabs
          defaultActiveKey={"0"}
          id="uncontrolled-tab-example"
          className="index-recommend-list"
          type="card"
        >
          {parentCategory.map((cat, idx) => (
            <TabPane eventKey={idx} key={cat.id} tab={cat.title}>
              <RecommendedSectionCourses
                categoryId={cat.id}
                courseList={courseList}
              />
            </TabPane>
          ))}
        </Tabs>
      </div>
    );
  }
}
export default RecommendedSection;
