import React from "react";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import Layout from "antd/lib/layout";
import Menu from "antd/lib/menu";
import Icon from "antd/lib/icon";

import TextFilterController from "../../routes/courseList/TextFilterController";

import LoginLink from "../../components/modals/Login";
import RegisterLink from "../../components/modals/Register";
import { MessageNotification } from "../message/MessageNotification";

import Logo from "../../images/logo/donanum-logo-v4.png";

const { Header } = Layout;

export const Nav = ({ toggle, menuToggle, fixedHeader }) => {
  return (
    <Header
      className="NavAntd menubg"
      data-menu="2"
      style={{ position: fixedHeader ? "fixed" : "initial" }}
    >
      <div className="wrap">
        <div className="split">
          <Icon
            type="menu"
            className="menu-toggle"
            onClick={toggle}
            id="main-menu-toggle"
          />
          <div className="logox">
            <Link to="/">
              <img src={Logo} alt="Donanum logo" />
            </Link>
          </div>
          <div className="hide-for-mob">
            <TextFilterController />
          </div>
        </div>
        <div className="split hide-for-mob">
          <Menu className="menu-wrap" theme="light" mode="horizontal">
            <Menu.Item key="1" className="nav-item">
              <Link to="/explore" onClick={() => menuToggle(false)}>
                <span className="special-menu">Classes</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="2" className="nav-item">
              <Link to="/AboutUs" onClick={() => menuToggle(false)}>
                About Us
              </Link>
            </Menu.Item>

            <Menu.Item key="3" className="nav-item">
              <Link to="/BeTutorInfo" onClick={() => menuToggle(false)}>
                <span className="">For Tutor</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="4" className="nav-item">
              <LoginLink />
            </Menu.Item>
            <Menu.Item key="5" className="nav-item">
              <NotLoggedContainer>
                <RegisterLink />
              </NotLoggedContainer>
              <LoggedContainer>
                <MessageNotification />
              </LoggedContainer>
            </Menu.Item>
          </Menu>
        </div>
        <div className="right-menu">
          <LoginLink />
        </div>
      </div>
    </Header>
  );
};

const Logged = props => {
  return <>{props.user.id && <>{props.children}</>}</>;
};

const NotLogged = props => {
  return <>{!props.user.id && <>{props.children}</>}</>;
};

const mapState = state => ({
  user: state.login.user
});

const LoggedContainer = connect(mapState)(Logged);
const NotLoggedContainer = connect(mapState)(NotLogged);
