import React from "react";
import PropTypes from "prop-types";
import Icon from "antd/lib/icon";

import { Col, Row } from "../../ui";

import "./UploadImg.css";
import UploadWall from "./UploadWall";
import { SaveButtonsNextOnly } from "../../routes/openCourse/SaveButtons";

class UploadImg extends React.Component {
  render() {
    console.log("[UploadImg.js] this.props", this.props);
    return (
      <div className="uploadImgCon">
        <div className="title">
          <h3>Add photos to your experience</h3>
          <p style={{ color: "#848484" }}>
            These images will be at the top of your experience page, so try
            uploading high quality photos of your experience to make a great
            first impression. You can always go back and change this later.
          </p>
        </div>
        <Row className="photoCon">
          <Col xs={12} className="">
            <p>Tips:</p>
            <ul>
              <li>
                * Showing people engaged with the activity on the experience
              </li>
              <li>
                * Showing off the location, activity, and candid aspects of your
                experience
              </li>
              <li>
                * Making sure the photo has good lighting. Natural light is best
              </li>
              <li className="warning">
                <Icon type="warning" /> Do not uploading photos of drugs,
                nudity, alcohol, or children
              </li>
            </ul>
          </Col>
          <Col xs={12}>
            <UploadWall
              courseId={this.props.courseId}
              files={this.props.files}
            />

            <p style={{ color: "#1790ff" }}>
              * Please upload at least 3 photos.
            </p>
            {/* <Dragger {...this.uploadSettings}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibit from
                uploading company data or other band files
              </p>
            </Dragger> */}
          </Col>
          <Col xs={12}>
            <SaveButtonsNextOnly
              isSaving={this.props.isSaving}
              onNext={() => this.props.onSave({}, true)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}
export default UploadImg;

UploadImg.defaultProps = {
  files: []
};

UploadImg.propTypes = {
  files: PropTypes.array,
  courseId: PropTypes.number.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired
};
