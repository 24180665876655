import React from "react";
import Button from "antd/lib/button";

export const SaveButtonsSubmit = ({
  isSaving,
  onSave = () => {},
  onNext = () => {}
}) => {
  return (
    <div className="SaveButtons">
      <Button
        className="next-btn"
        type=""
        icon="save"
        htmlType="submit"
        loading={isSaving}
        onClick={() => onSave()}
      >
        Save
      </Button>

      <Button
        data-next={true}
        className="next-btn"
        type=""
        icon="caret-right"
        loading={isSaving}
        onClick={() => onNext()}
      >
        Save & Continue
      </Button>
    </div>
  );
};

export const SaveButtonsOnly = ({
  isSaving,
  onSave = () => {},
  onNext = () => {}
}) => {
  return (
    <div className="SaveButtons">
      <Button
        className="next-btn"
        icon="save"
        loading={isSaving}
        onClick={() => onSave()}
      >
        Save
      </Button>

      <Button
        className="next-btn"
        icon="caret-right"
        loading={isSaving}
        onClick={() => onNext()}
      >
        Save & Continue
      </Button>
    </div>
  );
};

export const SaveButtonsNextOnly = ({ isSaving, onNext }) => {
  return (
    <div className="SaveButtons next-only">
      <Button
        className="next-btn"
        icon="caret-right"
        loading={isSaving}
        onClick={() => onNext()}
      >
        Save & Continue
      </Button>
    </div>
  );
};
