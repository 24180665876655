import React from "react";
import { Col, Grid } from "../../ui";
import { connect } from "react-redux";
import { DataServiceStatic } from "../../services/DataService";
import CourseCategory from "./CourseCategory";
import "./courseList.css";
import CourseThumb from "./CourseThumb";
import { toFilter } from "./FilterUtils";
import Spin from "antd/lib/spin";
import Switch from "antd/lib/switch";
import history from "../../services/history";
class ClassesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true };
    this.historyListener = null;
  }
  componentDidMount() {
    DataServiceStatic.loadAllCourses((err, data) => {
      this.setState({
        isLoading: false
      });
      this.autoFilter();
    }, false);

    this.historyListener = history.listen(() => {
      this.autoFilter();
    });
  }
  componentWillUnmount() {
    this.historyListener();
  }
  autoFilter() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("category")) {
      const slug = urlParams.get("category");
      // console.log("slug", slug);
      const isParent = slug.indexOf("_") === -1;
      toFilter("category", slug, isParent);
    }
  }

  render() {
    return (
      <div className="courseList sort-list-con">
        <CourseCategory />
        <Grid>
          <Spin size="large" spinning={this.state.isLoading}>
            {/* <Row className="courseList-Con"> */}
            <div className="row SubpageAM-con">
              <div className="col">
                <p className="xs-title list-icon-des">
                  <label>
                    <Switch
                      checkedChildren="Verified"
                      unCheckedChildren="All"
                      onChange={val => toFilter("verified", val)}
                    />{" "}
                    Only Show Verified Tutors
                  </label>
                  <label>
                    <Switch
                      checkedChildren="Online Only"
                      unCheckedChildren="All"
                      onChange={val => toFilter("online_only", val)}
                    />{" "}
                    Only Show Online Classes
                  </label>
                </p>
              </div>
            </div>
            <div className="row show-grid index-oneday-con">
              <CoursesList tooLate={false} showEmptyBanner={true} />
            </div>
            <div className="row show-grid too-late-grid index-oneday-con">
              <CoursesList tooLate={true} showEmptyBanner={false} />
            </div>
            {/* </Row> */}
          </Spin>
        </Grid>
      </div>
    );
  }
}

export default ClassesList;

const CoursesListIn = props => {
  return (
    <>
      {props.showEmptyBanner && (
        <>
          {props.filteredCourses.length === 0 && (
            <div className="noclass-text">
              <p>No courses found. Try searching with other criteria.</p>
            </div>
          )}
        </>
      )}
      {props.filteredCourses
        .filter(c => c.too_late === props.tooLate)
        .map(course => (
          <Col className="oneday-thumb" xs={6} sm={4} md={3} key={course.id}>
            <CourseThumb
              images={course.images}
              imgThumbSize="thumb3"
              title={course.title}
              category={course.category}
              price={course.price}
              rating={course.rating}
              address={course.address.osm_address_town}
              link={`/PostPage/${course.id}`}
              tooLate={course.too_late}
              verified={course.owner.verified}
              type={course.type}
              isNew={course._isNew}
            />
          </Col>
        ))}
    </>
  );
};

const mapState = (state, ownProps) => ({
  filteredCourses: state.data.filteredCourses,
  tooLate: ownProps.tooLate,
  showEmptyBanner: ownProps.showEmptyBanner
});

const CoursesList = connect(mapState)(CoursesListIn);
