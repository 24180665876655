/* eslint-disable jsx-a11y/anchor-is-valid */
import Collapse from "antd/lib/collapse";
import React from "react";
import "./helpStudent.css";

const Panel = Collapse.Panel;

// import HelpStudentAccordion from "./HelpStudentAccordion";

class HelpStudent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      activeKey: "1"
    };
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    return (
      <div className="Help-Student">
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Panel header="How do I register for a class?" key="1">
            <ol>
              <li>Choose a class you wish to take</li>
              <li> Click the “Register” button </li>
              <li>Once you register, you will receive a confirmation email</li>
              <li>Within 24 hours, a teacher will confirm your class</li>
            </ol>
          </Panel>
          <Panel header="How do I know what class is right for me?" key="2">
            <p>
              The Tutor will specify the level of a class in the curriculum,
              allowing Students to select the appropriate level of class to
              take.
            </p>
          </Panel>
          <Panel header="How do I pay for a class?" key="3">
            <ol>
              <li>
                Once you register for your class, you will receive a ‘request
                sent’ email
              </li>
              <li>
                Once your Tutor confirms your class, you will get a class
                confirmation email and receipt email.
              </li>
            </ol>
          </Panel>
          <Panel header="How does payment work?" key="4">
            <table id="customers">
              <tr>
                <th>Class Type</th>
                <th>Details</th>
              </tr>
              <tr>
                <td>Classes longer than one day</td>
                <td>
                  <p>
                    The full amount will need to be paid through the website.
                  </p>
                  <p>
                    For example: Donanum will hold onto the money until a class
                    is finished. If the Student wants to withdraw from a class
                    after the first class, we will return 80% of the class fee.
                  </p>
                </td>
              </tr>
              <tr>
                <td>One-Day-Classes</td>
                <td>
                  The amount on the class page will need to be paid through the
                  website.
                </td>
              </tr>
              <tr>
                <td>Tutoring/Mentoring</td>
                <td>
                  The amount on the class page will need to be paid through the
                  website.
                </td>
              </tr>
            </table>
          </Panel>
          <Panel header="What’s the payment system like?" key="5">
            <p>All major credit cards are accepted on Donanum.</p>
          </Panel>
          <Panel header="How do I know a class's time and location?" key="6">
            <p>
              On the post page, the Tutor will include the location and time. If
              it’s a Tutoring/Mentoring class, you can talk with Tutor and
              adjust the schedule and location to whatever works best.
            </p>
          </Panel>
          <Panel
            header="What if I have questions before registering for a class?"
            key="7"
          >
            <p>
              On each post page, there is a contact section. You can easily send
              a message to a Tutor and ask about their class’s details and
              curriculum.
            </p>
          </Panel>
          <Panel header="How can I trust the quality of a class?" key="8">
            <p>
              If you’re worried about a class's quality, you can seek out
              classes which have a “Verified” icon. Tutors who have this icon on
              their class page have provided a certificate, proof of enrollment,
              or proof of employment.
            </p>
            <p>
              You’re also free to check the reviews and feedback on any class.
            </p>
            <p>
              We promise that we will continue to build and maintain a
              verification system that will increase the credibility of our
              tutors.
            </p>
          </Panel>

          <Panel
            header="How can I cancel a class? Can I get a refund?"
            key="10"
          >
            <p>
              You can cancel a class up to 24 hours before a class starts. You
              will get a 100% refund so long as it complies with our refund
              policy.
            </p>
            <a href="https://donanum.com/Policy">Review our refund policy ></a>
          </Panel>
          <Panel header="How long does it take to receive a refund?" key="11">
            <p>
              Generally, it takes between 3 and 5 days for a refund to be
              processed.
            </p>
          </Panel>
          <Panel
            header="What if I have to drop a class in the middle of class? Can I get a refund?"
            key="12"
          >
            <p>
              After the first class, you will need to follow what is outlined in
              the refund policy.
            </p>
            <a href="https://donanum.com/Policy">More Information ></a>
          </Panel>
          <Panel header="How do I delete my account? " key="13">
            <p>
              We don't want to lose you, but if you really want to delete your
              account please contact us.
            </p>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
export default HelpStudent;
