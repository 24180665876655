import React, { Component } from "react";
import AsyncSelect from "react-select/lib/Async";
import axios from "axios";
import debounce from "debounce-promise";
import { setupCache } from "axios-cache-adapter";
const QS =
  "https://open.mapquestapi.com/nominatim/v1/search.php?key=aAbX1e10leekjYAl70bdKA6ZwWfoNQ40&format=json&q=";
const QE = ",Canada&addressdetails=1&limit=3";

const cache = setupCache({
  maxAge: 15 * 60 * 1000
});

const api = axios.create({
  adapter: cache.adapter
});

export default class CityAuto extends Component {
  constructor(props) {
    super(props);

    const wait = 1000; // milliseconds
    const loadOptions = inputValue => this.getAsyncOptions(inputValue);
    this.debouncedLoadOptions = debounce(loadOptions, wait, {
      leading: true
    });

    this.state = {
      selectedTitle: ""
    };
  }

  getAsyncOptions(inputValue) {
    return this.searchTitles(inputValue);
  }

  searchTitles = movieTitle => {
    console.log("searching for", movieTitle);
    let searchTerm = movieTitle;

    if (!movieTitle || movieTitle.trim() === "") {
      searchTerm = "Vancouver";
    }

    console.log("term", searchTerm);
    const urlRequest = `${QS}${searchTerm}${QE}`;
    const newRequest = api.get(urlRequest);

    if (newRequest) {
      // new promise: pending
      return newRequest.then(response => {
        console.log(response.data);
        // const fil = response.data.filter(r => r.type === 'city')
        const fil = response.data;
        return fil.map(r => ({
          label: r.display_name,
          value: r.display_name,
          data: r
        }));
      });
    }
  };
  render() {
    return (
      <div className="CityAuto">
        <AsyncSelect
          cacheOptions
          defaultOptions
          value={this.state.selectedTitle}
          loadOptions={inputValue => this.debouncedLoadOptions(inputValue)}
          onChange={(property, value) => {
            // console.log(property);
            this.setState({ selectedTitle: property });
            this.props.onSelect(property.data);
          }}
        />
      </div>
    );
  }
}
