import axios from "axios";
import store from "store";
import * as Yup from "yup";
import { dispatch, store as connectStore } from "./EventBus";
import message from "antd/lib/message";

import ProfileImage from "../routes/images/profile.png";
import { CourseValidatorSerializer } from "../routes/openCourse/component/CourseValidator";

const URL = process.env.REACT_APP_API_URL;
const CALLS_QUEUE = {
  // afterLogin: ["getReviewQueue", "loadMessageThreads"] //
  afterLogin: ["getReviewQueue", "loadUserSettings", "loadMessageThreads"] //
};

const H24 = 60 * 24;
const H1 = 60;

let msgCount = 0;

class DataService {
  constructor() {
    this.axiosAuth = axios.create({
      baseURL: URL
    });
    this.axios = null;
    this.key = null;
    this.needKeyToAdd = true;
  }
  setupAxios = () => {
    this.addUserKey();
    if (this.needKeyToAdd && this.key) {
      // console.log("[DataService.js] Key Added", true);
      this.keyAdded = false;
      this.axios = null;
      this.axios = axios.create({
        baseURL: URL,
        headers: { Authorization: "Token " + this.key }
      });
    } else {
      console.warn("[DataService.js] no key for axios");
      this.axios = axios.create({
        baseURL: URL
      });
    }
  };
  getStorage = (path, noCacheChecking = false) => {
    if (noCacheChecking) {
      return store.get("cache-" + path);
    } else {
      const exp = store.get(`cache-date-to-exp-` + path);
      const now = Math.floor(Date.now());
      if (now > exp) return null;
      return store.get("cache-" + path);
    }
  };
  cleanStorage = () => {
    store.each((val, key) => {
      if (key.indexOf("cache-") === 0) {
        store.remove(key);
      }
    });
  };

  addStorage = (key, val, ttl) => {
    const MS = 60000 * ttl; // 1 - min;
    store.set(`cache-` + key, val);
    store.set(`cache-date-to-exp-` + key, Math.floor(Date.now()) + MS);
  };

  setUserKey = key => {
    this.key = key;
    store.set("key", key);
  };
  getUserKey = () => {
    return store.get("key");
  };
  addUserKey = () => {
    if (this.key) return;
    this.key = this.getUserKey();
  };
  getAxios = () => {
    this.setupAxios();
    return this.axios;
  };
  loadUserIfTokenFound = () => {
    const key = this.getUserKey();
    if (key) {
      this.userGetMySelf(data => {
        if (data) {
          debugger;
          store.set("user", data);
        }
      });
      this.loadWishlist();
    }
  };
  resetPwd = (email, cb) => {
    this.post(
      "/auth/users/reset_password/",
      // "/auth/password/reset/",
      {
        email: email
      },
      cb,
      false,
      false
    );
  };
  userSocialLogin = (data, cb) => {
    if (data.provider === "google") {
      this.setUserKey(data.token);
      setTimeout(() => {
        cb(null, true);
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 100);
      }, 300);
      this.loadUserIfTokenFound();
    }
  };
  userRegister = (data, cb) => {
    this.axiosAuth
      .post("/auth/users/", {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        username: data.email,
        password: data.password,
        settings: {
          tnc: data.tnc ? "1" : "0",
          marketing_emails: data.marketing_emails ? "1" : "0"
        },
        birthday: data.bday
      })
      .then(response => {
        console.log(response);
        this.setUserKey(response.data.key);
        cb(null, true);
        this.loadUserIfTokenFound();
        // window.location.reload(true);
      })
      .catch(function(error) {
        console.log(error);
        cb(error, null);
      });
  };
  userActivate = ({ uid, token }, cb) => {
    this.axiosAuth
      .post("/auth/users/activation/", {
        uid,
        token
      })
      .then(response => {
        console.log("resp", response);
        cb(null, response.data);
      })
      .catch(error => {
        cb(error, null);
      });
  };
  userResetPwd = ({ uid, token, new_password }, cb) => {
    this.axiosAuth
      .post("/auth/users/reset_password_confirm/", {
        uid,
        token,
        new_password
      })
      .then(response => {
        console.log("resp", response);
        cb(null, response.data);
      })
      .catch(error => {
        cb(error, null);
      });
  };
  userGetOnly = () => {
    const user = store.get("user") || {};
    return user;
  };
  userGetMySelfCache = () => {
    const user = store.get("user") || false;
    if (user) {
      dispatch({ type: "login/setUser", payload: user || {} });
    }
    return user;
  };
  userGetMySelf = cb => {
    this.setupAxios();
    this.axios
      .get("/users/me")
      .then(response => {
        console.log("------ user  ---", response.data === null);
        if (response.data === null) {
          debugger;
        }
        store.set("user", response.data);
        dispatch({ type: "login/setUser", payload: response.data });
        CALLS_QUEUE.afterLogin.forEach(call => {
          DataServiceStatic[call]();
        });
        cb(null, { ...response.data });
      })
      .catch(err => {
        cb(err, null);
      });
  };
  userLogin = (username, password, cb) => {
    this.axiosAuth
      .post("/auth/token/login/", {
        username: username,
        password: password
      })
      .then(response => {
        this.setUserKey(response.data.auth_token);
        cb(null, response.data);
      })
      .catch(function(error) {
        if (error.response.status === 400) {
          cb(
            { msg: "User credentials or user do not exists", status: 400 },
            null
          );
        }
      });
  };
  userLogOut = () => {
    this.setUserKey(null);
    store.set("user", {});
  };

  uploadProfileImage = (file, cb) => {
    const formData = new FormData();
    formData.append("profile_image", file);

    this.getAxios()
      .put("users/upload/", formData)
      .then(response => {
        cb(null, response.data);
      })
      .catch(function(error) {
        globalErrorHandler(error, cb);
      });
  };

  uploadCourseImage = (file, courseId, cb) => {
    const formData = new FormData();
    formData.append("image", file);
    formData.append("course", courseId);

    this.getAxios()
      .put("image/", formData)
      .then(response => {
        cb(null, response.data);
      })
      .catch(function(error) {
        globalErrorHandler(error, cb);
      });
  };

  deleteCourseImage = (imageId, cb) => {
    this.getAxios()
      .delete(`image/${imageId}/`)
      .then(response => {
        cb(null, response.data);
      })
      .catch(function(error) {
        globalErrorHandler(error, cb);
      });
  };

  userUpdateProfile = (data, cb) => {
    const user = this.userGetMySelfCache();
    const url = "users/update/{id}/".replace("{id}", user.id);
    this.getAxios()
      .put(url, data)
      .then(response => {
        debugger;
        store.set("user", response.data);
        cb(null, response.data);
      })
      .catch(function(error) {
        globalErrorHandler(error, cb);
      });
  };

  userSendText = (phone, force, cb) => {
    const user = this.userGetMySelfCache();
    const url = "users/update/{id}/phone".replace("{id}", user.id);
    const dataBody = {
      phone: phone,
      force_resend: force
    };
    this.getAxios()
      .put(url, dataBody)
      .then(response => {
        console.log("res", response);
        cb(null, response.data);
      })
      .catch(function(error) {
        if (error.response) {
          cb(
            {
              status: error.response.status,
              data: error.response.data
            },
            null
          );
        } else {
          cb(
            {
              status: 500,
              data: "Try again."
            },
            null
          );
        }
        // globalErrorHandler(error, cb);
      });
  };

  userSendCode = (code, cb) => {
    const user = this.userGetMySelfCache();
    const url = "users/update/{id}/code".replace("{id}", user.id);
    const dataBody = {
      code
    };
    this.getAxios()
      .put(url, dataBody)
      .then(response => {
        this.userGetMySelf(() => {
          cb(null, response.data);
        });
      })
      .catch(function(error) {
        globalErrorHandler(error, cb);
      });
  };

  update = (path, data, cb) => {
    this.setupAxios();
    this.axios
      .put(path, data)
      .then(response => {
        cb(null, response.data);
      })
      .catch(error => {
        globalErrorHandler(error, cb);
      });
  };

  loadAllForClassInit = cb => {
    const p1 = new Promise((resolve, reject) => {
      this.loadLanguages((err, data) => {
        if (err) return reject(err);
        resolve(data);
      });
    });
    const p2 = new Promise((resolve, reject) => {
      this.loadCategory((err, data) => {
        if (err) return reject(err);
        resolve(data);
      });
    });
    const p3 = new Promise((resolve, reject) => {
      this.loadLocations((err, data) => {
        if (err) return reject(err);
        resolve(data);
      });
    });
    Promise.all([p1, p2, p3]).then(values => {
      const retVal = {
        languages: values[0],
        categories: values[1],
        locations: values[2]
      };
      if (cb) {
        cb(null, retVal);
      }
    });
  };

  loadLanguages = cb => {
    this.load("language/", cb, true, false, H24);
  };

  loadWishlist = cb => {
    this.load("wishlist/my", cb, true, "data/setWishlist");
  };

  loadVideoRoomUsers = cb => {
    this.load("videochat/my", cb, true, "data/setVideoChat");
  };

  //** Returns room user with twilio token */
  putVideoRoom = (roomUserId, cb) => {
    this.put(
      `videochat/gen/${roomUserId}/`,
      {},
      cb,
      true,
      "data/putVideoRoomUserGen"
    );
  };

  addWishlist = (course_id, cb) => {
    return this.post(
      "wishlist/my",
      { course_id },
      cb,
      true,
      "data/addWishlist"
    );
  };

  delWishlist = wishlist_id => {
    return this.delete(`wishlist/${wishlist_id}`, this.loadWishlist);
  };

  getLanguages = () => {
    return this.getStorage("language/") || [];
    // return this.storage["language/"] || [];
  };

  loadCategory = cb => {
    this.load("category/", cb, true, "data/setCatagories", H24);
  };

  getCategories = () => {
    // return this.storage["category/"] || [];
    return this.getStorage("category/") || [];
  };

  getLoadCatagories = cb => {
    const cat = this.getCategories();
    if (cat.length === 0) {
      this.loadCategory(cb);
    } else {
      cb(null, cat);
    }
  };

  loadLocations = cb => {
    this.load("location/", cb, true, false, H24);
  };

  getLocation = () => {
    return this.getStorage("location/") || [];
  };

  loadUserSettings = cb => {
    this.load("users/settings/", cb, true, "login/setSettings", H24);
  };

  createUserSettings = (id, data, cb) => {
    this.post("users/settings/", data, cb, true, false, H24);
  };

  updateUserSettings = (id, data, cb) => {
    this.put(
      "users/settings/" + id + "/",
      data,
      cb,
      true,
      "login/updateSettings",
      null,
      true
    );
  };

  updateUserSingleSetting = (name, value, cb) => {
    this.loadUserSettings(settings => {
      const single = settings.find(s => (s.name = name));
      const id = single.id;
      const data = {
        name,
        value
      };
      this.put(
        "users/settings/" + id + "/",
        data,
        cb,
        true,
        "login/updateSettings",
        null,
        true
      );
    });
  };

  loadCourse = (id, cb, useCached) => {
    if (useCached) {
      const cached = this.getStorage(`course/${id}/`);
      if (cached) return cb(null, cached);
      else this.load(`course/${id}/`, cb, true, false, H1);
    }
    this.load(`course/${id}/`, cb, true, H1);
  };

  loadMyCourses = cb => {
    this.load(`course/my`, cb);
  };

  loadAllCourses = (cb, useCached) => {
    if (useCached) {
      const cached = this.getStorage(`courses/`);
      if (cached) {
        dispatch({ type: `courses/`, payload: cached });
        return cb(null, cached);
      } else this.load(`courses/`, cb, true, "data/setCourses");
    }
    this.load(`courses/`, cb, true, "data/setCourses");
  };

  loadAllCoursesLean = cb => {
    this.load(`courseslean/`, cb, true, "data/setCoursesLean");
  };

  deleteCourse = (id, cb) => {
    this.delete(`course/${id}/delete`, err => {
      cb(err, null);
    });
  };

  updateCourse = (id, data, cb) => {
    if (data.validation_status_obj) {
      data.validation_status = data.validation_status_obj.stringify();
    } else {
      data.validation_status = "{}";
    }

    data.status = data.status.toUpperCase();

    if (!data.address.online_contact) data.address.online_contact = "none";
    this.update(`course/${id}/`, data, (err, respData) => {
      if (err) {
        return cb(err, respData);
      } else {
        const validation_status_obj = new CourseValidatorSerializer(
          data.validation_status
        );
        respData.validation_status_obj = validation_status_obj;
        cb(err, respData);
      }
    });
  };

  loadMyWaitingToConfirm = cb => {
    this.load(`book/waiting`, cb, true);
  };
  confirmBooking = (id, data, cb) => {
    this.update(`book/${id}/confirm`, data, cb);
  };
  loadMyBooked = cb => {
    this.load(`schedule/my`, cb);
  };
  loadReviews = (courseId, cb, useCached) => {
    if (useCached) {
      const cached = this.getStorage(`course/${courseId}/review`);
      if (cached) return cb(null, cached);
      else this.load(`course/${courseId}/review`, cb, true);
    } else {
      this.load(`course/${courseId}/review`, cb, true);
    }
  };
  postReview = (courseId, body, rating, cb) => {
    this.post("review/new", { course: courseId, rating, body }, cb);
  };

  getReviewQueue = cb => {
    this.load(`review/queue`, cb, true, "data/setReviewQueue");
  };
  loadMessages = cb => {
    this.load("messaging/my", cb, false, "data/setMessages");
    console.log("[DataService.js] LoadingMessages", msgCount);
    msgCount++;
    setTimeout(() => {
      this.loadMessages();
    }, 60 * 1000); // 60 seconds
  };

  loadMessageThreads = (data, cb) => {
    this.load("messaging/mythreads", cb, true, "data/setMessageThreads");
    console.log("[DataService.js] loadMessageThreads", msgCount);
    msgCount++;
    setTimeout(() => {
      this.loadMessageThreads();
    }, 60 * 1000);
  };

  markReadThread = (threadId, cb) => {
    this.put(
      `messaging/threadmark/${threadId}`,
      { seen: true },
      cb,
      false,
      "data/updateMessageThreadRead",
      { threadId }
    );
  };

  /**
   * action - star, archive
   */
  markThread = (threadId, action, value, cb) => {
    this.put(
      `messaging/threadmark/${threadId}`,
      { [action]: value },
      cb,
      false,
      "data/updateMessageThreadAction",
      { threadId, action, value }
    );
  };

  postMessageThread = (data, cb) => {
    this.post(
      `messaging/threadpost`,
      data,
      cb,
      false,
      "data/addMessageThreads"
    );
  };

  postMessage = (data, cb) => {
    const merged = {
      to_user: data.to_user,
      from_user: data.from_user,
      body: data.body,
      course: data.course || null,
      archived: false,
      parent: data.parent || null
    };

    this.post(`messaging/`, merged, cb, false, "data/addMessage");
  };

  markRead = (courseId, cb) => {
    const th = connectStore.getState().data.messages.thread[courseId];
    const user = this.userGetMySelfCache();
    if (th && th.children) {
      const list =
        th.children.filter(c => c.to_user === user.id && c.is_read === false) ||
        [];
      list.forEach(el => {
        this.put(
          `messaging/read/${el.id}/`,
          {
            is_read: true
          },
          cb,
          false,
          "data/updateMessageRead",
          { parent: courseId, id: el.id }
        );
      });
    }
  };

  createCourse = (data, cb) => {
    const dataTpl = {};
    dataTpl.address = {
      location: "",
      place: "",
      address: "",
      is_online: false,
      full_address: "",
      online_contact: ""
    };
    dataTpl.main_image = "";
    dataTpl.curriculum = "";
    dataTpl.image = [];
    dataTpl.date = [];
    dataTpl.size_min = 1;
    dataTpl.price = 0;
    dataTpl.duration = 10;
    dataTpl.is_free = false;
    dataTpl.is_public = false;
    dataTpl.compare_price = 0;
    dataTpl.is_discounted = false;
    dataTpl.extra_charge = 0;
    dataTpl.kind = "NO";

    const merged = { ...dataTpl, ...data };

    if (!merged.address.online_contact) {
      merged.address.online_contact = "none";
    }

    this.getAxios()
      .post("course/", merged)
      .then(response => {
        cb(null, response.data);
      })
      .catch(function(error) {
        globalErrorHandler(error, cb);
      });
  };

  bookCourse = ({ course_id, date_id, stripe_token }, cb) => {
    return this.post("book/", { course_id, date_id, stripe_token }, cb, true);
  };

  uploadFile = (formData, cb) => {
    return this.post("sysmessagesupload", formData, cb, false, false);
  };

  sendSysMsg = ({ subject, body }, cb) => {
    return this.post("sysmessages", { subject, body }, cb, true);
  };

  sendInqueryMsg = ({ subject, body }, cb) => {
    return this.post("sysmessagesin", { subject, body }, cb, true);
  };

  post = (path, data, cb, doStore = false, dispatchPath = false) => {
    this.setupAxios();
    this.axios &&
      this.axios
        .post(path, data)
        .then(response => {
          if (doStore) {
            this.addStorage(path, response.data);
          }
          if (dispatchPath) {
            dispatch({ type: dispatchPath, payload: response.data });
          }
          if (cb) {
            cb(null, response.data);
          }
        })
        .catch(err => {
          globalErrorHandler(err, cb);
        });
  };

  put = (
    path,
    data,
    cb,
    doStore = false,
    dispatchPath = false,
    extraData,
    usePutData
  ) => {
    this.setupAxios();
    this.axios &&
      this.axios
        .put(path, data)
        .then(response => {
          if (doStore) {
            this.addStorage(path, response.data);
          }
          let dataToDispatch = extraData;
          if (usePutData) {
            dataToDispatch = response.data;
          }
          if (dispatchPath) {
            dispatch({ type: dispatchPath, payload: dataToDispatch });
          }
          if (cb) {
            cb(null, response.data);
          }
        })
        .catch(err => {
          globalErrorHandler(err, cb);
        });
  };

  delete = (path, cb, dispatchPath = false) => {
    this.setupAxios();
    this.axios &&
      this.axios
        .delete(path)
        .then(response => {
          if (dispatchPath) {
            dispatch({ type: dispatchPath, payload: {} });
          }
          if (cb) {
            cb(null, {});
          }
        })
        .catch(err => {
          globalErrorHandler(err, cb);
        });
  };
  // ttl = minutes
  load = (
    path,
    cb = () => {},
    doStore = false,
    dispatchPath = false,
    ttl = 1
  ) => {
    this.setupAxios();
    this.axios &&
      this.axios
        .get(path)
        .then(response => {
          if (doStore) {
            this.addStorage(path, response.data, ttl);
          }
          if (dispatchPath) {
            dispatch({ type: dispatchPath, payload: response.data });
          }
          if (cb) {
            cb(null, response.data);
          }
        })
        .catch(err => {
          globalErrorHandler(err, cb);
        });
  };
  initValHelper = (st, name, def = "") => {
    if (st[name]) {
      return st[name];
    }
    return def;
  };

  validationSchemas = {
    SignUpSchema: Yup.object().shape({
      email: Yup.string()
        .email("Invalid email address")
        .required("Required"),
      first_name: Yup.string()
        .min(1, "Must be longer than 1 characters")
        .max(20, "Nice try, nobody has a first name that long")
        .required("Required"),
      last_name: Yup.string()
        .min(1, "Must be longer than 1 characters")
        .max(250, "Nice try, nobody has a last name that long")
        .required("Required"),
      password: Yup.string()
        .min(10, "Must be longer than 10 characters")
        .required("Required")
    })
  };
}

export const DataServiceStatic = new DataService();
export default DataService;
window.DataServiceStatic = DataServiceStatic;
window.DataService = DataService;
// curl -X POST "http://localhost:8000/api/v1/auth/registration/" -H "accept: application/json" -H "Content-Type: application/json" -H "X-CSRFToken: okNlOUl7VbQOvURTfrMIG0qhUnmdCxtW20YUACwk953sch7Gcpa9WqWIVBgt8zYo" -d "{ \"email\": \"jonas@ponasd.com\", \"first_name\": \"string\", \"last_name\": \"string\", \"password1\": \"tKzm56F4S2jzeDfrwh\", \"password2\": \"tKzm56F4S2jzeDfrwh\"}"

const globalErrorHandler = (error, cb) => {
  console.error("Server error, please try later :(", error);
  message.error("Server error, please try later :(", 10);
  console.log("erroror ===== ", error.response);
  if (error.response) {
    try {
      if (error.response.data.detail.indexOf("Invalid token.") === 0) {
        store.clearAll();
        window.location.reload();
        return;
      }
    } catch {}

    dispatch({
      type: "error",
      payload: {
        data: error.response.data,
        status: error.response.status
      }
    });
  } else {
    dispatch({
      type: "error",
      payload: {
        data: error,
        status: 400
      }
    });
  }
  cb(error, null);
};

export const getUserImage = user => {
  // console.log("[DataService.js] users", user);
  if (user && user.user_image) {
    if (Array.isArray(user.user_image)) {
      const clone = [...user.user_image];
      const first = clone.pop();
      if (first) {
        return first.profile_image;
      } else {
        if (user.social_thumb) {
          return user.social_thumb;
        } else {
          return ProfileImage;
        }
      }
    }
    return user.user_image.profile_image;
  }
  if (user && user.social_thumb) {
    return user.social_thumb;
  }
  return ProfileImage;
};

export const isUserVerified = () => {
  const user = DataServiceStatic.userGetOnly();
  return user.verified;
};

export const isUserLoggedIn = () => {
  const user = DataServiceStatic.userGetOnly();
  return !!user.id;
};
