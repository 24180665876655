import React from "react";
import Tabs from "antd/lib/tabs";
import Spin from "antd/lib/spin";
import { DataServiceStatic } from "../../../services/DataService";
import "../userProfile.css";
import "./ProfileTabs.css";
import ProfileTabsOpenedCourses from "./ProfileTabsOpenedCourses";
import ProfileTabsRegistered from "./ProfileTabsRegistered";
import ProfileTabsWishlist from "./ProfileTabsWishlist";
import WaitingToConfirm from "./WaitingToConfirm";
import ProfileTabsOnlineClassesSimple from "./ProfileTabsOnlineClassesSimple";

const { TabPane } = Tabs;

class ProfileTabs extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      scheduleArr: [],
      onlineSchedule: [],
      wishlistArr: [],
      videoChatList: [],
      writeReviewId: 0,
      videoChatListLoaded: false
    };
  }
  componentDidMount() {
    DataServiceStatic.loadMyBooked((err, data) => {
      if (!err) {
        console.log("scheduleArr", data);
        const onlineSchedule = data.filter(c => c.course.address.is_online);
        this.setState({ loading: false, scheduleArr: data, onlineSchedule });
      }
    });
    DataServiceStatic.loadWishlist((err, data) => {
      console.log("[UserProfile.js] my wishlist", data);
      this.setState({ wishlistArr: data });
    });

    DataServiceStatic.loadVideoRoomUsers((err, data) => {
      this.setState({ videoChatList: data, videoChatListLoaded: true });
    });

    const href = new URL(window.location.href);
    const writeReviewId = href.searchParams.get("writereview");
    console.log("[ProfileTabs.js] writeReviewId", writeReviewId);
    this.setState({ writeReviewId });
  }
  render() {
    return (
      <Tabs type="card" className="m-top ProfileTabCon">
        <TabPane tab="Class Registered" key="1">
          <ProfileTabsRegistered
            writeReviewId={this.state.writeReviewId}
            scheduleArr={this.state.scheduleArr}
          />
        </TabPane>
        <TabPane tab="Online Classes" key="2">
          <Spin spinning={!this.state.scheduleArr}>
            <ProfileTabsOnlineClassesSimple list={this.state.onlineSchedule} />
          </Spin>
        </TabPane>
        <TabPane tab="Wishlist" key="3">
          <ProfileTabsWishlist wishlistArr={this.state.wishlistArr} />
        </TabPane>

        <TabPane tab="Opened Classes" key="4">
          <ProfileTabsOpenedCourses />
        </TabPane>
        <TabPane tab="Waiting to Confirm" key="5">
          <WaitingToConfirm />
        </TabPane>
      </Tabs>
    );
  }
}
export default ProfileTabs;
