import React from "react";
import Result from "antd/lib/result";

import { Col, Row } from "../../ui";

import { Link } from "react-router-dom";

import "./404.css";

class ErrorPage extends React.Component {
  render() {
    return (
      <div className="container Error-Con footer-bottom-margin">
        <Row>
          <Col xs={12} sm={6}>
            <Result status="404" title="404" />,
          </Col>
          <Col xs={12} sm={6}>
            <h2>Oh S#*T</h2>
            <h3>We can't seem to find the page you're looking for.</h3>
            <p>Error code: 404</p>
            <p>Here are some helpful links instead.</p>
            <div>
              <div>
                <Link to="/">Home</Link>
              </div>
              <div>
                <Link to="/explore">Search</Link>
              </div>
              <div>
                <Link to="/help">Help</Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default ErrorPage;
