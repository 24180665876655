import Select from "antd/lib/select";
import React from "react";
import "./Birthday.css";

const { Option } = Select;

class Birthday extends React.Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const yearTop = date.getFullYear() - 10;
    const yearMin = yearTop - 100;
    this.years = [];
    for (let i = yearTop; i >= yearMin; i--) {
      this.years.push(i);
    }
    this.days = [];
    for (let i = 1; i <= 31; i++) {
      this.days.push(i);
    }
    this.month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    this.selected = {
      d: 1,
      m: 0,
      y: 2019
    };
  }

  onSelect = (el, val) => {
    console.log("[Birthday.js] el, val", el, val);
    this.selected[el] = val;
    const bday = new Date(
      parseInt(this.selected["y"], 10),
      parseInt(this.selected["m"], 10),
      parseInt(this.selected["d"], 10)
    );
    console.log("[Birthday.js] bday", bday);
    this.props.value(bday);
  };

  render() {
    return (
      <div id="components-dropdown-demo-dropdown-button">
        <Select
          defaultValue="Year"
          onChange={e => this.onSelect("y", e)}
          style={{ width: 120 }}
        >
          {this.years.map(d => (
            <Option key={d} value={d}>
              {d}
            </Option>
          ))}
        </Select>
        <Select
          defaultValue="Month"
          onChange={e => this.onSelect("m", e)}
          style={{ width: 120 }}
        >
          {this.month.map((d, i) => (
            <Option key={d} value={i}>
              {d}
            </Option>
          ))}
        </Select>
        <Select
          defaultValue="Day"
          onChange={e => this.onSelect("d", e)}
          style={{ width: 120 }}
        >
          {this.days.map(d => (
            <Option key={d} value={d}>
              {d}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}
export default Birthday;
