import Icon from "antd/lib/icon";

import React from "react";
import { Link } from "react-router-dom";
import "./confirmation.css";
import { DataServiceStatic } from "../../services/DataService";
import moment from "moment";
import BlankImage from "../../images/blank.jpg";

class Confirmation extends React.Component {
  state = {
    course: {},
    isLoaded: false,
    mock: false
  };
  componentDidMount() {
    const s = window.location.search;
    if (s === "?test=1") {
      this.setState({ mock: true, isLoaded: true });
    } else {
      const booked = DataServiceStatic.getStorage("book/", true);
      const courseId = booked.course_id;
      const dateId = booked.date_id;

      DataServiceStatic.loadCourse(
        courseId,
        (err, data) => {
          const date = data.date.find(d => d.id === dateId);
          this.setState({
            isLoaded: true,
            course: data,
            date: date
          });
        },
        true
      );
    }
  }

  render() {
    if (!this.state.isLoaded) {
      return <div>loading..</div>;
    }
    let imgScr = "";
    if (this.state.mock) {
    } else {
      const imgCopy = this.state.course.images;
      imgScr = "";
      let imgThumb = "missing";
      if (imgCopy.length > 0) {
        const mainImg = imgCopy.shift();
        imgThumb = "thumbUrl1";
        imgScr = mainImg[imgThumb];
      }
    }
    return (
      <div className="container">
        <div className="confirmation-con">
          <div className="icon">
            <Icon type="check-circle" />
            <h2>Your Request Sent</h2>
          </div>

          <div className="confirmation-des">
            <p>
              This is not a confirmed booking - at least not yet. You’ll get a
              response within 48 hours.
            </p>
            <p>You won't be charged until your booking is confirmed.</p>
          </div>
          <div className="confirmation-class-info">
            <div className="confirmation-class-img">
              {imgScr && <img alt="" src={imgScr} />}
              {!imgScr && <img alt="" src={BlankImage} />}
            </div>
            <div className="confirmation-class-title">
              <h3>
                {this.state.mock ? "Test Class Title" : this.state.course.title}
              </h3>
              <p>
                Starts at{" "}
                {this.state.mock && moment().format("YYYY-MM-DD hh:mm A")}
                {!this.state.mock &&
                  moment(this.state.date.date_start).format(
                    "YYYY-MM-DD hh:mm A"
                  )}
              </p>
            </div>
          </div>
          <div className="confirm-viewmore">
            <Link to="/explore">View More Classes</Link>
          </div>
        </div>
      </div>
    );
  }
}
export default Confirmation;
