class CourseValidatorRules {
  constructor() {
    this.isValid = false;
    this.general = {
      valid: false
      // Rest comes from form validation
    };
    this.uploadImg = {
      valid: false,
      msg: "Please provide images",
      rules: {
        min: 3,
        max: 5
      }
    };
    this.whoCome = {
      valid: false,
      msg: "Please provide longer text",
      rules: {
        min: 30,
        max: 10000
      }
    };
    this.about = {
      valid: false,
      msg: "Please provide longer text",
      rules: {
        min: 30,
        max: 10000
      }
    };
    this.curriculum = {
      valid: false,
      msg: "Please provide longer text",
      rules: {
        min: 30,
        max: 10000
      }
    };
    this.brief = {
      valid: false,
      msg: "Please provide longer text",
      rules: {
        min: 30,
        max: 10000
      }
    };
    this.calendar = {
      valid: false,
      msg: "Please set up calendar",
      rules: {
        min: 1,
        max: 10000
      }
    };
  }
}

class CourseValidatorSerializer {
  constructor(rawJson, doNew = false) {
    if (doNew) {
      this.data = {
        v: 1,
        invalid: new Set()
      };
    } else {
      try {
        const tmp = JSON.parse(rawJson);
        console.log("[CourseValidator.js] tmp", tmp);
        this.data = {};
        this.data.v = tmp.v;
        this.data.invalid = new Set(tmp.invalid);
      } catch (err) {
        this.data = {
          v: 1,
          invalid: new Set()
        };
      }
    }
  }
  add(attr) {
    this.data.invalid.add(attr);
  }
  del(attr) {
    this.data.invalid.delete(attr);
  }
  has(attr) {
    return this.data.invalid.has(attr);
  }
  any() {
    return this.data.invalid.size !== 0;
  }
  list() {
    return Array.from(this.data.invalid);
  }
  stringify() {
    const d = { ...this.data };
    d.invalid = Array.from(this.data.invalid);
    return JSON.stringify(d);
  }
}

export { CourseValidatorRules, CourseValidatorSerializer };

export const between = (x, rules) => {
  return x >= rules.min && x <= rules.max;
};

export const nameToKeys = {
  phoneValidation: "1",
  whoCome: "3",
  uploadImg: "4",
  calendar: "5",
  about: "6",
  curriculum: "7",
  brief: "7"
};

export const nameToNames = {
  phoneValidation: "Phone Verification",
  phoneValidation_err: "Phone Verification",
  general: "General Info",
  general_err: "General Info",
  whoCome: "Audience",
  whoCome_err: "Audience",
  uploadImg: "Photos",
  uploadImg_err: "Photos",
  calendar: "Calendar",
  calendar_err: "Calendar",
  about: "About teacher",
  about_err: "About teacher",
  curriculum: "Curriculum",
  curriculum_err: "Curriculum",
  brief: "Class Brief",
  brief_err: "Class Brief (under Curriculum section)"
};
