import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Alert from "antd/lib/alert";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Icon from "antd/lib/icon";
import Calendar from "antd/lib/calendar";
import TimePicker from "antd/lib/time-picker";

import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { SaveButtonsOnly } from "../../../routes/openCourse/SaveButtons";

import "./calendar.css";

const FRM = "YYYY-MM-DD";
const TIME_FRM = "hh:mm a";

function zerofixed(v) {
  if (v < 10) {
    return `0${v}`;
  }
  return `${v}`;
}

class CalendarWidget extends Component {
  constructor(props) {
    super(props);

    const val = this.props.values;
    this.dates = {};
    val.forEach(v => {
      const dateFrm = moment(v.date_start).format(FRM);
      const date_start = moment(v.date_start);
      const date_end = moment(v.date_start).add(v.date_duration, "minutes");
      date_start._obj_id = v.id;
      if (this.dates[dateFrm]) {
        this.dates[dateFrm].push([date_start, date_end]);
      } else {
        this.dates[dateFrm] = [[date_start, date_end]];
      }
    });

    console.log("[Calendar.js] this.dates", this.dates);

    this.state = {
      dates: this.dates,
      selectedValue: moment(),
      selectorOpen0: -1,
      selectorOpen1: -1,
      defaultClassLength: 60
    };
    this.removedIds = [];
  }

  onDefaultClassLengthChange = val => {
    this.setState({ defaultClassLength: val });
  };
  onSelect = value => {
    this.setState({
      selectedValue: value
    });
  };

  onSave = (next = false) => {
    const values = [];
    const tplMain = {
      id: 0,
      date_start: "2019-01-01T01:00:01-08:00",
      date_end: "2019-01-01T01:30:00-08:00",
      date_duration: 30
    };
    Object.values(this.state.dates).forEach(dateBase => {
      dateBase.forEach(date => {
        const tpl = { ...tplMain };
        tpl.id = date[0]._obj_id || 0;
        tpl.date_start = date[0].format();
        tpl.date_end = date[1].format();
        tpl.date_duration = date[1].diff(date[0], "minutes");
        values.push(tpl);
      });
    });
    this.removedIds.forEach(id => {
      const tpl = { ...tplMain };
      tpl.id = id;
      tpl.is_deleted = true;
      values.push(tpl);
    });
    console.log("[Calendar.js] dates", values);
    this.props.onSave(values, next);
  };
  handleClose = () => {
    this.setState({ selectorOpen0: -1, selectorOpen1: -1 });
  };

  onOpenChange = (order, idx) => {
    if (order === 0) {
      this.setState({ selectorOpen0: idx });
    } else {
      this.setState({ selectorOpen1: idx });
    }
  };
  handleTimeChange = (order, day, idx, time, _obj_id) => {
    const dates = { ...this.state.dates };
    let times = [...dates[day][idx]];
    times[order] = time;
    if (order === 0) {
      if (times[0].isAfter(times[1])) {
        times[1] = times[0].clone().add(this.state.defaultClassLength, "m");
        times[0]._obj_id = _obj_id;
      }
    }
    dates[day][idx] = times;
    this.setState({ dates });
  };
  removeTime = (day, idx) => {
    const dates = { ...this.state.dates };
    const rmDay = dates[day].splice(idx, 1);
    try {
      const id = rmDay[0][0]._obj_id;
      if (id) {
        this.removedIds.push(id);
      }
    } catch (e) {
      console.warn("Cannot find id of the day", e);
    }
    this.setState({ dates });
  };
  addMore = () => {
    if (this.state.selectedValue) {
      const sv = this.state.selectedValue.format(FRM);
      const dates = { ...this.state.dates };
      const startDate = moment(sv + " 01:00:00");
      const endDate = startDate.clone().add(this.state.defaultClassLength, "m");
      const nv = [startDate, endDate];
      if (dates[sv]) {
        dates[sv].push(nv);
      } else {
        dates[sv] = [nv];
      }
      this.setState({ dates });
    }
  };

  dateCellRenderCheckoutView = value => {
    // const dates = { ...this.state.dates };
    // const doHave = dates[value.format(FRM)];
    // let times;
    // if (doHave) {
    //   times = dates[value.format(FRM)].map((day, i) => {
    //     return <div key={day[0] + i}>{day[0].format("h:mm a")}</div>;
    //   });
    // }
    // return (
    //   <div className={`doHave-${!!doHave} event`}>
    //     {doHave && <span>{times}</span>}
    //   </div>
    // );
  };

  dateCellRender = value => {
    const dates = { ...this.state.dates };
    const doHave = dates[value.format(FRM)];

    let times;
    if (doHave) {
      times = dates[value.format(FRM)].map((day, i) => {
        return <div key={day[0] + i}>{day[0].format("h:mm a")}</div>;
      });
    }
    let doHaveClass = "";
    if (this.props.checkoutView) {
      doHaveClass = `doHave-${!!doHave}`;
    }
    return (
      <div className={`${doHaveClass} event`}>
        {doHave && <span>{times}</span>}
      </div>
    );
  };

  dateFullCellRender = value => {
    const dates = { ...this.state.dates };
    const doHave = dates[value.format(FRM)];

    const prefixCls = "ant-fullcalendar";
    let dayRenderFn = "dateCellRender";
    let doHaveClass = "";
    if (this.props.checkoutView) {
      dayRenderFn = "dateCellRenderCheckoutView";
      doHaveClass = `doHave-${!!doHave}`;
    }

    return (
      <div className={`${prefixCls}-date ${doHaveClass}`}>
        <div className={`${prefixCls}-value`}>{zerofixed(value.date())}</div>
        <div className={`${prefixCls}-content`}>{this[dayRenderFn](value)}</div>
      </div>
    );
  };

  onBookDate = timeSlot => {
    console.log("[Calendar.js] timeSlot", timeSlot, timeSlot);
    this.props.onSelect(timeSlot);
  };

  render() {
    const { selectedValue } = this.state;
    let selectedDay = "";
    // let timeSlot;
    if (selectedValue) {
      selectedDay = selectedValue.format(FRM);
      // timeSlot = this.state.dates[selectedDay];
    }
    console.log("[Calendar.js] selectedDay", selectedDay);
    return (
      <div className="calendarCon">
        <Row>
          <Col xs={{ span: 24 }} sm={{ span: 17 }}>
            <Calendar
              // dateCellRender={this.dateCellRender}
              dateFullCellRender={this.dateFullCellRender}
              onSelect={this.onSelect}
              fullscreen={!this.props.checkoutView}
            />
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 7 }}>
            <Alert message={`Selected date: ${selectedDay}`} />

            {!this.props.checkoutView && (
              <>
                {selectedDay &&
                  this.state.dates[selectedDay] &&
                  Object.keys(this.state.dates[selectedDay]).map((day, idx) => {
                    const timeValue0 = this.state.dates[selectedDay][idx][0];
                    const timeValue1 = this.state.dates[selectedDay][idx][1];
                    const _obj_id = timeValue0._obj_id || 0;
                    const err = timeValue0.isAfter(timeValue1);
                    const style = {
                      background: err ? "red" : ""
                    };
                    return (
                      <div key={day} style={style} className="timePicker-flex">
                        <TimePicker
                          open={this.state.selectorOpen0 === idx}
                          onOpenChange={val => this.onOpenChange(0, idx, val)}
                          use12Hours
                          allowClear={false}
                          format="h:mm a"
                          minuteStep={15}
                          value={timeValue0}
                          onChange={time =>
                            this.handleTimeChange(
                              0,
                              selectedDay,
                              idx,
                              time,
                              _obj_id
                            )
                          }
                          addon={() => (
                            <div>
                              <Button
                                size="small"
                                type="primary"
                                onClick={() => this.handleClose()}
                              >
                                Select
                              </Button>
                            </div>
                          )}
                        />
                        :
                        <TimePicker
                          open={this.state.selectorOpen1 === idx}
                          onOpenChange={val => this.onOpenChange(1, idx, val)}
                          use12Hours
                          allowClear={false}
                          format="h:mm a"
                          minuteStep={15}
                          value={timeValue1}
                          onChange={time =>
                            this.handleTimeChange(
                              1,
                              selectedDay,
                              idx,
                              time,
                              _obj_id
                            )
                          }
                          addon={() => (
                            <div>
                              <Button
                                size="small"
                                type="primary"
                                onClick={() => this.handleClose()}
                              >
                                Select
                              </Button>
                            </div>
                          )}
                        />
                        <Icon
                          className="timePicker-delete"
                          type="minus-circle"
                          onClick={() => this.removeTime(selectedDay, idx)}
                        />
                      </div>
                    );
                  })}
                <br />
                <br />
                <Button onClick={this.addMore}>
                  <Icon type="plus" /> Add Time Slot
                </Button>
              </>
            )}

            {this.props.checkoutView && (
              <DatesSidebar
                dates={this.state.dates}
                onBookDate={this.onBookDate}
                selectedDay={selectedDay}
              />
            )}
            {/* {this.props.checkoutView && selectedDay && (
              <>
                {Object.keys(this.state.dates).map((day, idx) => {
                  const timeSlot = this.state.dates[selectedDay][0];
                  return (
                    <SelectionCard
                      key={idx}
                      timeSlot={timeSlot}
                      onBookDate={this.onBookDate}
                    />
                  );
                })}
              </>
            )} */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
        {!this.props.checkoutView && (
          <SaveButtonsOnly
            isSaving={this.props.isSaving}
            onSave={() => this.onSave()}
            onNext={() => this.onSave(true)}
          />
        )}
      </div>
    );
  }
}

export default CalendarWidget;

CalendarWidget.defaultProps = {
  checkoutView: false,
  onSave: () => {},
  onSelect: () => {},
  isSaving: false
};

CalendarWidget.propTypes = {
  values: PropTypes.array.isRequired,
  onSave: PropTypes.func,
  onSelect: PropTypes.func,
  checkoutView: PropTypes.bool,
  isSaving: PropTypes.bool
};

const SelectionCard = ({ timeSlot, onBookDate }) => {
  const isPast = isPastDate(timeSlot[0]);
  return (
    <div>
      <br />
      <Card
        title={moment(timeSlot[0]).format(FRM)}
        extra={
          <Button onClick={() => onBookDate(timeSlot)} disabled={isPast}>
            Select
          </Button>
        }
        // style={{ width: 300 }}
        className={`past-${isPast}`}
      >
        {moment(timeSlot[0]).format(TIME_FRM)} to{" "}
        {moment(timeSlot[1]).format(TIME_FRM)}
      </Card>
    </div>
  );
};

const isPastDate = compareDate => {
  return moment(compareDate).isBefore(moment());
};

const DatesSidebar = ({ dates, selectedDay, onBookDate }) => {
  if (selectedDay && dates[selectedDay]) {
    const retVal = dates[selectedDay].map(timeSlot => {
      if (isPastDate(timeSlot[0])) return <span key={timeSlot[0]} />;
      return (
        <SelectionCard
          key={timeSlot[0]}
          timeSlot={timeSlot}
          onBookDate={onBookDate}
        />
      );
    });

    return <div>{retVal}</div>;
  } else {
    const retVal = Object.keys(dates).map(date => {
      return dates[date].map(timeSlot => {
        if (isPastDate(timeSlot[0])) return <span key={timeSlot[0]} />;
        return (
          <SelectionCard
            key={timeSlot[0]}
            timeSlot={timeSlot}
            onBookDate={onBookDate}
          />
        );
      });
    });
    return <div>{retVal}</div>;
  }
};
