import React from "react";
import { Col, Row } from "../../ui";
import Icon from "antd/lib/icon";

import ApproveMarkCom from "../../components/ApproveMarkCom/ApproveMarkCom";
// import TeacherCareer from "./component/TeacherCareer";
// import ExtraInfo from "./component/ExtraInfo";
import "./approveMark.css";

class ApproveMark extends React.Component {
  render() {
    return (
      <div className="AM-container">
        <div className="AMtitleCon container">
          <Row>
            <Col xs={12} sm={8}>
              <h2>Get verified icon</h2>
              <p className="amDescription">
                Please upload proof of employment or proof of enrollment or
                proof of graduate to get the verified icon. we will check the
                document and we will prove a verified icon.
              </p>
              <p className="amDescription">
                <Icon type="safety" /> The mark will always show on beside the
                title of the class page. This will help you get high trust from
                students when they consider to register className.
              </p>
              <div style={{ marginTop: "50px" }}>
                <ApproveMarkCom />
              </div>
            </Col>
            <Col className="d-none d-sm-block tips" sm={4}>
              <div className="margin-b">
                <h3>
                  <Icon className="icon" type="info-circle" />
                  Tip! Upload Certificate
                </h3>
                <p>
                  when you upload the certificate paper, please show the date
                  location you get approved.
                </p>
              </div>
              <div>
                <h3>
                  <Icon className="icon" type="clock-circle" />
                  Process Time
                </h3>
                <p>
                  It will take 3-7 business days. Once we check the document, we
                  will delete the information you send.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
export default ApproveMark;
