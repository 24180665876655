import React from "react";
import { Col, Row, Grid } from "../../ui";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";
import "./checkout.css";
import Stripe from "./Stripe";
import Calendar from "../../routes/openCourse/component/Calendar";
import history from "../../services/history";
import Button from "antd/lib/button";

// src/routes/openCourse/component/Calendar.js
import { getIdFromPath, currencyFormatter } from "../../services/Utils";
import { DataServiceStatic } from "../../services/DataService";

import BlankImage from "../../images/blank.jpg";
import Logo from "../../images/logo/donanum-logo-v4.png";
// import Logo from "../../images/logo/donanum-logo-color.png";

const FRM = "YYYY-MM-DD";
const TIME_FRM = "hh:mm a";

class Checkout extends React.Component {
  constructor() {
    super();
    this.bodyCss = document.getElementById("root");
    this.state = {
      course: {},
      isLoaded: false,
      date_selected: false,
      date_selected_arr: [],
      is_paid: false
    };
    this.date_selected_id = 0;
  }
  componentDidMount() {
    this.bodyCss.classList.add("CheckoutTop");
    const id = getIdFromPath("checkout", window.location.pathname);
    DataServiceStatic.loadCourse(id, (err, data) => {
      if (data) {
        // const date_selected = data.type !== "ONE_TIME";
        const date_selected_arr = [
          data.date[0].date_start,
          data.date[0].date_end
        ];
        this.setState({
          isLoaded: true,
          course: data,
          // date_selected,
          date_selected_arr
        });
      }
    });
  }

  componentWillUnmount() {
    this.bodyCss.classList.remove("CheckoutTop");
  }
  onDateSelect = dateArr => {
    console.log("what");
    this.date_selected_id = dateArr[0]._obj_id;
    this.date_selected_arr = dateArr;
    this.setState({ date_selected: true, date_selected_arr: dateArr });
  };
  onBook = dateId => {
    const params = new URLSearchParams(window.location.search);
    params.append("did", dateId);
    params.append("cid", this.state.course.id);
    history.push(window.location.pathname + "?" + params.toString());

    DataServiceStatic.bookCourse(
      { course_id: this.state.course.id, date_id: dateId },
      (err, dataBack) => {
        console.log("[Checkout.js] booked", dataBack);
      }
    );
  };
  handleResult = stripe => {
    if (stripe.error) {
      console.log("[Checkout.js] r", stripe);
    } else {
      DataServiceStatic.bookCourse(
        {
          course_id: this.state.course.id,
          date_id: this.date_selected_id,
          stripe_token: stripe.token.id
        },
        (err, dataBack) => {
          if (!err) {
            console.log("[Checkout.js] booked", dataBack);
            this.setState({ is_paid: true });
          } else {
            this.setState({ is_paid: false, hasError: true });
          }
        }
      );
    }
  };
  handleFree = () => {
    DataServiceStatic.bookCourse(
      {
        course_id: this.state.course.id,
        date_id: this.date_selected_id
      },
      (err, dataBack) => {
        if (!err) {
          console.log("[Checkout.js] booked", dataBack);
          this.setState({ is_paid: true });
        } else {
          this.setState({ is_paid: false, hasError: true });
        }
      }
    );
  };
  render() {
    console.log("is date selected", this.state.date_selected);
    if (this.state.is_paid) {
      return <Redirect to={`/Confirmation/${this.state.course.id}`} />;
    }
    return (
      <div>
        <Grid>
          <Link to="/">
            <div className="checkout-logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>
        </Grid>
        <div className="container checkout-con">
          {this.state.is_paid && <div>Paid! All good!</div>}
          {!this.state.is_paid && this.state.isLoaded && (
            <div>
              {!this.state.date_selected && (
                <Calendar
                  onSelect={d_id => this.onDateSelect(d_id)}
                  values={this.state.course.date}
                  checkoutView
                />
              )}
              {this.state.date_selected && (
                <div className="checkout-wrap">
                  <Desktop
                    course={this.state.course}
                    date_selected_arr={this.state.date_selected_arr}
                    handleResult={this.handleResult}
                    handleFree={this.handleFree}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default Checkout;

const Desktop = ({ course, date_selected_arr, handleResult, handleFree }) => {
  let mainImg = "";
  if (course.images && course.images.length > 0) {
    mainImg = course.images[0];
  }

  return (
    <div className="checkout-con xx container">
      <Row>
        <Col xs={12}>
          <Mobile
            course={course}
            date_selected_arr={date_selected_arr}
            handleResult={handleResult}
            handleFree={handleFree}
          />
        </Col>
        <Col className="d-none d-sm-block" sm={6}>
          <h2 className="lg-title">Review and pay</h2>
          <p>
            You can add more friends to this experience and confirm your
            reservation.
          </p>

          {/* <div className="m-top">
            <h3 className="md-title">Number of guest</h3>
            <p>take a class with friends!</p>
            <div className="numppl-dropdown-con">
              <Dropdown className="numppl-dropdown">
                <a className="ant-dropdown-link" href="/#/">
                  1 Guest <Icon type="down" />
                </a>
              </Dropdown>
              <p>This is popular class. Be sure to book soon.</p>
            </div>
          </div> */}
          <div className="m-top">
            <h3 className="md-title">Payment method</h3>
            <div>
              {course.is_free && (
                <Button onClick={handleFree}>It's Free! Confirm!</Button>
              )}
              {!course.is_free && <Stripe handleResult={handleResult} />}
            </div>
          </div>
          <br />
          <p>
            By confirming this booking, you agree to the
            <a href="/Policy" target="_blank">
              <span> Term Policy</span>
            </a>
            ,
            <a href="/Policy" target="_blank">
              <span> the Cancellation Policy</span>
            </a>
            , and the{" "}
            <a href="/Policy" target="_blank">
              <span>Refund Policy</span>
            </a>
            .
          </p>
        </Col>
        <Col className="d-none d-sm-block" sm={6}>
          <div className="outline">
            <div className="">
              <Row>
                <Col xs={7}>
                  <h2 className="md-title">{course.title}</h2>
                  {/* <p
                    className="brief"
                    dangerouslySetInnerHTML={{ __html: course.brief_html }}
                  /> */}
                </Col>
                <Col xs={5}>
                  <div>
                    {mainImg && (
                      <img
                        alt=""
                        className="img"
                        key={mainImg.id}
                        src={mainImg.image}
                      />
                    )}
                    {!mainImg && (
                      <img className="img" alt="" src={BlankImage} />
                    )}
                  </div>
                </Col>
              </Row>
            </div>
            <div className="bar" />
            <div>
              {date_selected_arr && (
                <>
                  <div className="flex">
                    <p style={{ marginRight: "5px" }}>Date:</p>
                    <p> {moment(date_selected_arr[0]).format(FRM)} </p>
                  </div>
                  <div className="flex">
                    <p style={{ marginRight: "5px" }}>Time:</p>
                    <p> {moment(date_selected_arr[0]).format(TIME_FRM)}</p>
                  </div>
                </>
              )}
            </div>
            <div className="bar" />
            <div className="flex">
              <p style={{ marginRight: "5px" }}>Requirements:</p>
              <p dangerouslySetInnerHTML={{ __html: course.requirement }} />
            </div>

            <div className="bar" />
            <div>
              <Row className="flex-space-between">
                <Col xs={8}>Total:</Col>
                <Col xs={4}>
                  {course.is_free && <span>FREE</span>}
                  {!course.is_free && (
                    <>{currencyFormatter(course.user_pays, course.currency)}</>
                  )}
                </Col>
              </Row>
            </div>
            <div className="bar" />
            <div>
              <span style={{ color: "var(--blue)" }}>Cancellation Policy</span>
              <p>get a full refund if you cancel within 24hours of purchase.</p>
            </div>
          </div>
        </Col>
      </Row>
      <div className="Checkout-footer">
        <p className="copyright">
          All Content © 2019 DoNanum, Inc. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

const Mobile = ({ course, date_selected_arr, handleResult, handleFree }) => {
  let mainImg = "";
  if (course.images && course.images.length > 0) {
    mainImg = course.images[0];
  }

  return (
    <div className="d-block d-sm-none">
      <h2>Review and pay</h2>
      <div className="">
        <div className="outline">
          <div className="">
            <Row>
              <Col xs={7}>
                <h2 className="md-title">{course.title}</h2>
                {/* <p
                  className="brief"
                  dangerouslySetInnerHTML={{ __html: course.brief_html }}
                /> */}
              </Col>
              <Col xs={5}>
                <div>
                  {mainImg && (
                    <img
                      alt=""
                      className="img"
                      key={mainImg.id}
                      src={mainImg.image}
                    />
                  )}
                  {!mainImg && <img className="img" alt="" src={BlankImage} />}
                </div>
              </Col>
            </Row>
          </div>
          <div className="bar" />
          <div>
            {date_selected_arr && (
              <>
                <div className="flex">
                  <p style={{ marginRight: "5px" }}>Date:</p>
                  <p> {moment(date_selected_arr[0]).format(FRM)} </p>
                </div>
                <div className="flex">
                  <p style={{ marginRight: "5px" }}>Time:</p>
                  <p> {moment(date_selected_arr[0]).format(TIME_FRM)}</p>
                </div>
              </>
            )}
          </div>
          <div className="bar" />
          <div className="flex">
            <p>Requirements: </p>
            <p dangerouslySetInnerHTML={{ __html: course.requirement }} />
          </div>

          <div className="bar" />
          <div>
            <Row className="flex-space-between">
              <Col xs={8}>Total:</Col>
              <Col xs={4}>
                {course.is_free && <span>FREE</span>}
                {!course.is_free && (
                  <>{currencyFormatter(course.user_pays, course.currency)}</>
                )}
              </Col>
            </Row>
          </div>
          <div className="bar" />
          <div>
            <span style={{ color: "var(--blue)" }}>Cancellation Policy</span>
            <p>get a full refund if you cancel within 24hours of purchase.</p>
          </div>
        </div>
      </div>

      <div className="" style={{ marginTop: "20px" }}>
        <h3 className="md-title">Payment method</h3>
        <div>
          {course.is_free && (
            <Button onClick={handleFree}>It's Free! Confirm!</Button>
          )}
          {!course.is_free && <Stripe handleResult={handleResult} />}
        </div>
      </div>

      <div className="bar" />
      <div>
        <a href="/Policy">
          <span>Cancellation Policy</span>
        </a>

        <p>Get a full refund if you cancel within 24hours of purchase.</p>
        <p>
          By confirming this bookin, you agree to the
          <a href="/Policy" target="_blank">
            <span> Term Policy</span>
          </a>
          ,
          <a href="/Policy" target="_blank">
            <span> the Cancellation Policy</span>
          </a>
          , and the{" "}
          <a href="/Policy" target="_blank">
            <span>Refund Policy</span>
          </a>
          .
        </p>
      </div>
    </div>
  );
};
