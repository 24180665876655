import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Grid } from "../../../ui";

// import ImgRotation from "../../postPage/imgRotation/ImgRotation2";
// import PostRegisterSection from "../../postPage/postRegisterSection/PostRegisterSection2";
import TextWidgetPreview from "../../../components/TextWidgetPreview";

class Preview extends React.Component {
  render() {
    return (
      <div className="previewCon container">
        <Grid className="postPage-con">
          <Row>
            <Col sm={12}>
              {/* <ImgRotation images={this.props.course.images} /> */}
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              {/* <PostRegisterSection course={this.props.course} previewMode /> */}
            </Col>
          </Row>
          <Row>
            <div className="PP-content-padding">
              <h4 className="sm-title">About teacher</h4>
              <TextWidgetPreview value={this.props.course.about_teacher_html} />
            </div>
          </Row>
          <Row>
            <div className="PP-content-padding">
              <h4 className="sm-title">Class brief</h4>
              <TextWidgetPreview value={this.props.course.brief_html} />
            </div>
          </Row>
          <Row>
            <div className="PP-content-padding">
              <h4 className="sm-title">Who can come</h4>
              <TextWidgetPreview value={this.props.course.audience_html} />
            </div>
          </Row>
          <Row>
            <div className="PP-content-padding">
              <h4 className="sm-title">Curriculum</h4>
              <TextWidgetPreview value={this.props.course.curriculum_html} />
            </div>
          </Row>
          <Row>
            <div className="bar PP-content-padding">
              <h4 className="sm-title">Map</h4>
            </div>
          </Row>
        </Grid>
        <br />
        <br />
        <br />
        <br />
        {/* <Button className="next-btn">Make Public</Button> */}
      </div>
    );
  }
}
export default Preview;

Preview.propTypes = {
  course: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired
};
