import React from "react";
import Select from "react-select";

class CategorySelector extends React.Component {
  onChange = e => {
    console.log("[CategorySelector.js] e", e);
    if (this.props.onSelect) this.props.onSelect(e);
  };
  makeGroups = cats => {
    const parents = cats
      .filter(el => el.parent === null)
      .map(el => {
        return {
          label: el.title,
          options: [
            {
              label: `All "${el.title}" Related Topics`,
              id: el.id,
              value: el.id,
              full_slug: el.full_slug,
              is_parent: true
            }
          ],
          id: el.id,
          full_slug: el.full_slug
        };
      });
    parents.forEach(p => {
      const children = cats
        .filter(el => el.parent === p.id)
        .map(el => {
          return {
            label: el.title,
            id: el.id,
            value: el.id,
            full_slug: el.full_slug
          };
        });
      p.options = [...p.options, ...children];
    });
    return parents;
  };
  findInitVal = (id, cats) => {
    if (!id) return null;
    const one = cats.find(el => el.id === id);
    if (!one) return null;
    return { label: one.title, id: one.id, value: one.id };
  };

  findSlugVal = (full_slug, cats) => {
    if (!full_slug) return null;
    const one = cats.find(
      el => el.full_slug.toLowerCase() === full_slug.toLowerCase()
    );
    if (!one) return null;
    return {
      label: one.title,
      id: one.id,
      value: one.id,
      full_slug: one.full_slug
    };
  };

  render() {
    let cats = [];
    if (this.props.categories) cats = this.props.categories;
    const options = this.makeGroups(cats);
    // const def = this.findInitVal(this.props.categoryId, cats);
    const def = this.findSlugVal(this.props.categorySlug, cats);
    return (
      <Select
        value={def}
        options={options}
        onChange={this.onChange}
        menuPortalTarget={document.querySelector("body")}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
      />
    );
  }
}

export default CategorySelector;
