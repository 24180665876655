import Button from "antd/lib/button";
import Table from "antd/lib/table";
import Divider from "antd/lib/divider";

import { Link } from "react-router-dom";
import React from "react";
import { DataServiceStatic } from "../../../services/DataService";
import { DeclineConfirmForm } from "./DeclineForm";

class WaitingToConfirm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: []
    };
    this.columns = [
      {
        title: "id",
        dataIndex: "id",
        key: "id"
      },
      {
        title: "Course Title",
        dataIndex: "course.title",
        key: "course"
        // render: text => <a href="javascript:;">{text}</a>,
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user.id",
        render: (text, record) =>
          `${record.user.first_name} ${record.user.last_name}`
      },
      {
        title: "Confirmed",
        dataIndex: "confirmed",
        key: "confirmed",
        render: (text, record) => (
          <span>{record.confirmed ? "YES" : "NO"} </span>
        )
      },
      {
        title: "Action",
        key: "action",
        render: (text, record) => {
          if (record.confirmed !== null) {
            return <span>No actions.</span>;
          }

          return (
            <span>
              <Button
                onClick={() => {
                  this.action(true, record.id);
                }}
                disabled={record.confirmed}
              >
                Confirm
              </Button>
              <Divider type="vertical" />
              <Button
                type="danger"
                onClick={() => {
                  this.openDecline(record.id);
                }}
              >
                Deny
              </Button>
            </span>
          );
        }
      }
    ];
  }
  componentDidMount() {
    DataServiceStatic.loadMyWaitingToConfirm((err, data) => {
      if (!err) {
        const sortedData = data.sort((a, b) => {
          return Number(b.id) - Number(a.id);
        });
        this.setState({ loading: false, data: sortedData });
      }
    });
  }
  action(confirmed, id, vals) {
    this.setState({ loading: true });
    const reason = vals ? vals.reason : "";
    DataServiceStatic.confirmBooking(id, { confirmed, reason }, (err, data) => {
      // const d = [...this.state.data];
      const alteredData = this.state.data.map(d => {
        if (d.id !== id) return d;
        const dcopy = { ...d };
        dcopy.confirmed = data.confirmed;
        return dcopy;
      });
      const sortedData = alteredData.sort(
        (a, b) => Number(a.id) - Number(b.id)
      );
      this.setState({ loading: false, data: sortedData, declinePopup: false });
    });
  }
  openDecline = id => {
    this.setState({ declinePopup: true, idToDecline: id });
  };

  render() {
    return (
      <div className="WaitingToConfirm">
        <div className="justifyRight">
          <Button variant="primary">
            <Link to="/explore">view more classes</Link>
          </Button>
        </div>
        {this.state.declinePopup && (
          <DeclineConfirmForm
            onConfirm={vals => this.action(false, this.state.idToDecline, vals)}
          />
        )}
        <Table
          dataSource={this.state.data}
          columns={this.columns}
          rowKey={"id"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}

export default WaitingToConfirm;
