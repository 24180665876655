import moment from "moment";
import humanizeDuration from "humanize-duration";
import React from "react";

const CalcTotalMins = dates => {
  let retVal = 0;
  dates.forEach(date => {
    retVal += Number.parseInt(date.date_duration);
  });

  return retVal;
};

export const CalcPrices = (dates, price, price_mode) => {
  const totalMins = CalcTotalMins(dates);
  const totalHours = moment.duration(totalMins, "minutes").hours();
  let totalPrice = 1.1;
  let pricePerHour = 1.1;
  if (price_mode === "TOTAL") {
    totalPrice = price;
    pricePerHour = price / totalHours;
  } else {
    totalPrice = totalHours * price;
    pricePerHour = price;
  }

  return {
    pricePerHour,
    totalPrice,
    totalHours

    // total time
    // 1 h price
    // total price
  };
};

export const capitalizeFirstLetter = string => {
  if (string) {
    return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
  }
  return "";
};

export const getIdFromPath = (type, path) => {
  let retVal = false;
  switch (type) {
    case "checkout":
      const id = path.split("/")[2];
      retVal = Number(id);
      break;

    default:
      break;
  }
  return retVal;
};

export const currencyFormatter = (input, cur = "CAD") => {
  return `${cur} ${Number.parseFloat(input).toFixed(2)}`;
};

export const courseHoursCalc = (date, type) => {
  let ms = 0;
  if (type === "ONE_TIME") {
    const minsTotal = date[0].date_duration;
    ms = minsTotal * 60000;
  } else {
    const minsTotal = date.reduce((sum, n) => sum + n.date_duration, 0);
    ms = minsTotal * 60000;
  }

  return humanizeDuration(ms);
};

export const priceOrFree = (price, is_free) => {
  return (
    <>
      {is_free || price === 0 ? (
        <>Free</>
      ) : (
        <>
          ${price}
          <span>/per hour</span>
        </>
      )}
    </>
  );
};
