/* eslint-disable jsx-a11y/anchor-is-valid */
import Collapse from "antd/lib/collapse";

import React from "react";
import "./helpStudent.css";
import "./helpTeacher.css";

const Panel = Collapse.Panel;

class HelpTeacher extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.handleSelect = this.handleSelect.bind(this);
    this.state = {
      activeKey: "1"
    };
  }

  handleSelect(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    return (
      <div className="Help-tutor">
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Panel header="Who can open a class?" key="1">
            <p>
              Anyone can open class and become a Tutor. If, as a tutor, you wish
              to increase your chances of getting more students, you can apply
              for a “Verified” icon.
            </p>
            <p>
              Getting a “Verified” icon is not mandatory, but studies have shown
              that it will increase the level of trust between you and your
              prospective students. We highly recommend getting one, and once
              you have it, it will be displayed on every class that you open.
            </p>
            <a href="https://donanum.com/ApproveMark">
              Click to apply for a verified icon>
            </a>
          </Panel>
          <Panel header="How do I create a class? " key="2">
            <p>All you need to do is log into the website to create a class.</p>
            <p>
              From the menu, click “Open Class”, then click “Open a Class Now”
              button on the banner. Write the details of the class, and you’re
              all set!
            </p>
            <a href="https://donanum.com/CourseType"> Open class ></a>
          </Panel>
          <Panel header="Where can I hold my class?" key="3">
            <p>
              It’s fully up to the tutor. Classes can be held in a cafe, a study
              room in the library, a personal studio, etc.
            </p>
            <a
              href="https://blog.donanum.com/source/"
              target="_blank"
              rel="noopener noreferrer"
            >
              View information about open spaces in your area >
            </a>
          </Panel>
          <Panel
            header="How long does it take to get an “verified
          icon”?"
            key="4"
          >
            <p>Generally, it will take between 3 and 5 days.</p>
          </Panel>
          <Panel
            header="What happens after a Student registers for a class?"
            key="5"
          >
            <p>
              Once a Student registers for a class, you will get an email. You
              must then connect with the Student and confirm the class; you are
              responsible for confirming within 3 days, otherwise, the
              registration will be cancelled.
            </p>
          </Panel>
          <Panel header="How do I make a profit? " key="6">
            <p>Classes will be paid out biweekly after classes finish.</p>

            <table id="customers">
              <tr>
                <th>Class Type</th>
                <th>Details</th>
              </tr>
              <tr>
                <td>Classes lasting longer than one day</td>
                <td>
                  <p>
                    Tutors will get paid after a class finishes without any
                    issues.
                  </p>
                  <a href="https://donanum.com/Policy">
                    See Tutor & Student Responsibilities >
                  </a>
                  <p>
                    For example, if a class starts on January 1st and ends on
                    January 4th, the Tutor would get paid on January 11th).
                  </p>
                </td>
              </tr>
              <tr>
                <td>One day classes</td>
                <td>
                  <p>
                    Tutors will get paid after classes finishes without any
                    issues.
                  </p>
                  <a href="https://donanum.com/Policy">
                    See Tutor & Student Responsibilities >
                  </a>
                  <p>
                    For example, if a class occurred on January 1st, the Tutor
                    would get paid on January 11th.)
                  </p>
                </td>
              </tr>
              <tr>
                <td>Tutoring/Mentoring </td>
                <td>
                  Donanum will take the fee for one hour as a connection fee.
                  After that, the Tutor will get paid directly by the Student.
                </td>
              </tr>
            </table>
          </Panel>
          {/* <Panel
            header="Are there service fees that I should be aware of? "
            key="7"
          >
            <table id="customers">
              <tr>
                <th>Class Type</th>
                <th>Service Fee</th>
              </tr>
              <tr>
                <td>Classes lasting longer than one day</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>One-Day-Classes</td>
                <td>20%</td>
              </tr>
              <tr>
                <td>Tutoring/Mentoring</td>
                <td>
                  Donanum will take the fee for one hour as a connection fee.
                </td>
              </tr>
            </table>
          </Panel> */}
          <Panel header="How do I delete my account? " key="8">
            <p>
              We don't want to lose you, but if you really want to delete your
              account please contact us.
            </p>
          </Panel>
        </Collapse>
      </div>
    );
  }
}
export default HelpTeacher;
