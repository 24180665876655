/* eslint-disable */

import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";

import React from "react";
import { Col, Row } from "../../ui";
import { DataServiceStatic } from "../../services/DataService";
import moment from "moment";
import Birthday from "./Birthday";
import "./Login.css";
import LoginWithGoogle from "./LoginWithGoogle";

const EVENT_ROOT = document.getElementById("root");

//rest
const UserRegisterForm = Form.create({ name: "form_in_modal" })(
  class extends React.Component {
    state = {
      loading: false,
      ageError: false,
      showForm: true
    };
    handleSubmit = e => {
      e.preventDefault();
      this.setState({ loading: true });
      this.props.form.validateFields((err, values) => {
        console.log("val", values);
        if (!err) {
          DataServiceStatic.userRegister(
            {
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              password: values.password,
              tnc: values.tnc,
              marketing_emails: values.marketing_emails,
              bday: this.bday.format("YYYY-MM-DD")
            },
            (err, ok) => {
              if (err) {
                // if(err)
                message.error(
                  "Cannot login, please check your credentials and try again."
                );
                this.setState({ loading: false });
              } else {
                this.setState({ showForm: false });
              }
            }
          );
        } else {
          this.setState({ loading: false });
        }
      });
    };
    loginLink = () => {
      this.props.onCancel();
      const hideEvent = new CustomEvent("LoginModal::show");
      EVENT_ROOT.dispatchEvent(hideEvent);
    };
    birthdayValue = val => {
      const entered = moment(val);
      const today = moment();
      const diff = today.diff(entered, "years");
      this.setState({ ageError: diff <= 13 });
      this.bday = entered;
    };
    onChange = e => {
      console.log(`checked = ${e.target.checked}`);
    };
    render() {
      const { visible, onCancel, onCreate, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          className="registerCon"
          visible={visible}
          title=""
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          footer={null}
          bodyStyle={{ width: "600px" }}
          // style={{ width: "600px" }}
          width={600}
        >
          <div>
            Sign up with <LoginWithGoogle onOk={onCancel} textOnly />
          </div>
          <Row>
            <Col xs={5}>
              <div className="bar" />
            </Col>
            <Col xs={2}>
              <p className="center">or</p>
            </Col>
            <Col xs={5}>
              <div className="bar" />
            </Col>
          </Row>
          {!this.state.showForm && (
            <div>
              <h4>
                Please check your e-mail, we sent you registration completion
                instructions.
              </h4>
            </div>
          )}
          {this.state.showForm && (
            <Form
              onSubmit={this.handleSubmit}
              className="login-form"
              layout="vertical"
            >
              <Form.Item>
                {getFieldDecorator("first_name", {
                  rules: [
                    { required: true, message: "Please enter your name!" }
                  ]
                })(
                  <Input
                    className="userInput"
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Name"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("last_name", {
                  rules: [
                    { required: true, message: "Please enter your last name!" }
                  ]
                })(
                  <Input
                    className="userInput"
                    prefix={
                      <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Last Name"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("email", {
                  rules: [
                    { required: true, message: "Please enter your Email!" }
                  ]
                })(
                  <Input
                    className="email"
                    prefix={
                      <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="text"
                    placeholder="Email"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please enter your Password!",
                      min: 8
                    }
                  ]
                })(
                  <Input
                    className="password"
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <p>
                  You must be 13 or older to sign up. Other users won’t have
                  access to your birthday.
                </p>
                <Birthday value={this.birthdayValue} />
                {this.state.ageError && (
                  <div className="has-error">
                    <div className="ant-form-explain">
                      Sorry, you are too yong!
                    </div>
                  </div>
                )}
              </Form.Item>
              <p>
                We’ll send you marketing promotions, special offers,
                inspiration, and policy updates via email.
              </p>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  loading={this.state.loading}
                >
                  Sign up
                </Button>
              </Form.Item>
              <Form.Item>
                {getFieldDecorator("tnc", {
                  valuePropName: "checked",
                  initialValue: true,
                  rules: [
                    {
                      required: true,
                      message: "Please agree!"
                    }
                  ]
                })(
                  <Input
                    className="agreement"
                    prefix={
                      <p> I agree with Term policy and User agreements.</p>
                    }
                    type="checkbox"
                  />
                )}
              </Form.Item>

              <Form.Item>
                {getFieldDecorator("marketing_emails", {
                  valuePropName: "checked",
                  initialValue: true,
                  rules: [
                    {
                      required: false,
                      message: "Please agree!"
                    }
                  ]
                })(
                  <Input
                    className="agreement"
                    prefix={
                      <p>
                        <>
                          {" "}
                          I want to receive marketing messages from Donanum. I
                          can also opt out of receiving these at any time in my
                          account settings or via the link in the message.
                        </>
                      </p>
                    }
                    type="checkbox"
                  />
                )}
              </Form.Item>
            </Form>
          )}
          <p>
            Already have an Donanum account?{" "}
            <a onClick={this.loginLink} href="/#/">
              Log in
            </a>
          </p>
        </Modal>
      );
    }
  }
);

class RegisterLink extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };
    window.RegisterModal = {
      show: this.showModal,
      hide: this.handleCancel
    };
  }
  componentDidMount() {
    EVENT_ROOT.addEventListener(
      "RegisterModal::show",
      function() {
        window.RegisterModal.show();
      },
      false
    );
    EVENT_ROOT.addEventListener(
      "RegisterModal::hide",
      function() {
        window.RegisterModal.hide();
      },
      false
    );
  }
  showModal = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.setState({ visible: true });
  };

  handleCancel = () => {
    this.setState({ visible: false });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <>
        <Button type="link" onClick={this.showModal}>
          Sign Up
        </Button>

        <UserRegisterForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={userData => this.handleCancel(userData)}
        />
      </>
    );
  }
}

export default RegisterLink;

export const registerTrigger = () => {
  window.LoginModal.hide();
  // const hideEvent = new CustomEvent("LoginModal::hide");
  // EVENT_ROOT.dispatchEvent(hideEvent);
  window.RegisterModal.show();
};
