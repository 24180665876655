import React from "react";
import "./herobanner01.css";

import TextFilterController from "../../../routes/courseList/TextFilterController";

class Herobanner01 extends React.Component {
  render() {
    return (
      <div className=" ">
        <div
          className="herobanner-img"
          style={{
            background:
              "url(" + require("../../images/donanum-herobanner.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat"
          }}
        />
        <div
          className="herobanner01-text"
          style={{
            position: "absolute",
            left: "10%",
            color: "white",
            width: "44%"
          }}
        >
          <h1 className="hero-title" style={{ color: "white" }}>
            Find the Perfect Mentor to Master Your New Skill
          </h1>
          <p>Whatever your goal, we'll get you there</p>
          <div style={{ background: "white", borderRadius: "3px" }}>
            <TextFilterController />
          </div>
        </div>
      </div>
    );
  }
}
export default Herobanner01;
