import React from "react";
import { DataServiceStatic } from "../../services/DataService";
import Spin from "antd/lib/spin";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import zxcvbn from "zxcvbn";

import "./ActivateDetail.css";

export class PasswordReset extends React.Component {
  state = {
    isLoading: false,
    ok: true,
    new_password: "",
    done: false,
    secure: false
  };
  resetBtn() {
    this.setState({ isLoading: true });
    const path = window.location.pathname;
    const sp = path.split("/");
    if (sp.length === 4) {
      const uid = sp[2];
      const token = sp[3];
      const new_password = this.state.new_password;
      DataServiceStatic.userResetPwd(
        { uid, token, new_password },
        (err, data) => {
          if (err) {
            this.setState({ isLoading: false, ok: false });
          } else {
            this.setState({ isLoading: false, ok: true, done: true });
          }
        }
      );
    } else {
      this.setState({ isLoading: false, ok: false });
    }
  }
  onChange(new_password) {
    const secure = zxcvbn(new_password).score;
    this.setState({ new_password, secure });
  }
  render() {
    return (
      <div className="PasswordReset container">
        <div className="flex">
          <br />
          <br />
          {!this.state.done && (
            <div>
              <Spin size="large" spinning={this.state.isLoading}>
                <h2 style={{ textAlign: "center", marginBottom: "7%" }}>
                  Reset Password
                </h2>
                <Input.Password
                  onChange={e => this.onChange(e.target.value)}
                  onPressEnter={() => {
                    this.resetBtn();
                  }}
                  addonAfter={
                    <Button type="link" onClick={() => this.resetBtn()}>
                      Change
                    </Button>
                  }
                />
                <p style={{ color: "#848484", marginTop: "3%" }}>
                  8 characters or longer. Combine upper and lowercase letters
                  and numbers. score: {this.state.secure}
                </p>
              </Spin>
            </div>
          )}
          <div>
            <br />
            <br />
            <br />
            {!this.state.isLoading && !this.state.ok && (
              <p style={{ color: "red" }}>
                Password Invalid! Please try again or
                <a
                  style={{ color: "red" }}
                  href="https://donanum.com/ContactUs"
                >
                  {" "}
                  contact us
                </a>
              </p>
            )}
            {this.state.done && (
              <div>
                <h3>Password changed.</h3>
                <p>Please login with new password.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordReset;
