import React from "react";
import { Col, Row } from "../../ui";

import Menu from "antd/lib/menu";
import Dropdown from "antd/lib/dropdown";
import Icon from "antd/lib/icon";

import "./MessageDetail.css";

const menu = (
  <Menu>
    <Menu.Item key="0">
      <a href="http://www.alipay.com/">1st menu item</a>
    </Menu.Item>
    <Menu.Item key="1">
      <a href="http://www.taobao.com/">2nd menu item</a>
    </Menu.Item>
    <Menu.Divider />
    <Menu.Item key="3">3rd menu item</Menu.Item>
  </Menu>
);

export class MessageDetail extends React.Component {
  render() {
    return (
      <div className="container messageDetail-con">
        <Row>
          <Col lg={2} className="d-none d-lg-block d-xl-block">
            <div className="messageDetail-dropdown">
              <Dropdown overlay={menu} trigger={["click"]}>
                <a className="ant-dropdown-link" href="/#/">
                  All Conversations
                  <Icon type="caret-down" />
                </a>
              </Dropdown>
            </div>
            <div className="messageDetail-list">
              <ul>
                <li className="flex">
                  <div>img</div>
                  <div>
                    <p>name of tutor</p>
                    <p>text</p>
                  </div>
                  <div className="flex">
                    <p>4 months</p> <p>star</p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>

          <Col xs={12} sm={8} lg={7}>
            <div className="">
              <p>Name of Tutor</p>
              <p>Date</p>
              <div className="">...(star, archive, icon)</div>
            </div>
            <div className="">
              <p>content</p>
              <input />
              <button>send</button>
            </div>
          </Col>
          <Col className="d-none d-sm-block" sm={4} lg={3}>
            <div className="messageDetail-tutorInfo">
              <div>
                <div className="profile-img">profile img</div>
                <p>name of tutor</p>
              </div>
              <div>Review Rate-star 5(4)</div>
              <div>Review Rate-star 5(4)</div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MessageDetail;
