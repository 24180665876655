import React from "react";
import AntSelect from "antd/lib/select";

const { Option, OptGroup } = AntSelect;

class CategorySelector extends React.Component {
  onChange = e => {
    console.log("[CategorySelector.js] e", e);
    if (this.props.onSelect) this.props.onSelect(e);
  };
  makeGroups = cats => {
    const parents = cats
      .filter(el => el.parent === null)
      .map(el => {
        return {
          label: el.title,
          options: [],
          id: el.id,
          full_slug: el.full_slug
        };
      });
    parents.forEach(p => {
      const children = cats
        .filter(el => el.parent === p.id)
        .map(el => {
          return {
            label: el.title,
            id: el.id,
            value: el.id,
            full_slug: el.full_slug
          };
        });
      p.options = children;
    });
    return parents;
  };

  render() {
    let cats = [];
    if (this.props.categories) cats = this.props.categories;
    const options = this.makeGroups(cats);

    const rxOptions = options.map(op => {
      const children = op.options.map(opo => {
        return (
          <Option key={opo.id} value={opo.id}>
            {opo.label}
          </Option>
        );
      });
      return (
        <OptGroup key={op.id} label={op.label}>
          {children}
        </OptGroup>
      );
    });

    return (
      <AntSelect
        showSearch
        value={this.props.categoryId || ""}
        onChange={this.onChange}
        filterOption={(input, option) => {
          const c = option.props.children;
          //Note: for optGroup children is array, for not - string
          if (Array.isArray(c)) return false;
          return c.toLowerCase().indexOf(input.toLowerCase()) >= 0;
        }}
      >
        {rxOptions}
      </AntSelect>
    );
  }
}

export default CategorySelector;
