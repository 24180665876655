import React from "react";

class EventPage extends React.Component {
  render() {
    return (
      <div className="container">
        <h2>Event</h2>
      </div>
    );
  }
}
export default EventPage;
