import React from "react";
import Icon from "antd/lib/icon";
import { Link } from "react-router-dom";

import "./FifthSection.css";

class FifthSection extends React.Component {
  render() {
    return (
      <div
        className="mainPage-readyCon"
        style={{ background: "#10161e", padding: "40px 0 90px 0" }}
      >
        <div className="readyCon-width">
          <h3
            className=""
            style={{
              color: "white",
              fontSize: "28px",
              textAlign: "center",
              marginBottom: "50px"
            }}
          >
            How Donanum Works
          </h3>
          <div
            className="flex"
            style={{ justifyContent: "space-between", padding: "0 53px" }}
          >
            <div className="flex con01">
              <Icon type="search" className="icon" />
              <div>
                <h3 className="title">Explore Classes</h3>
                <p className="subtitle">
                  Whatever your goal, we will get you there
                </p>
                <Link to="/explore" style={{ color: "#0097bd" }}>
                  Take a class >
                </Link>
              </div>
            </div>
            <div className="flex con02">
              <Icon type="plus" className="icon" />
              <div>
                <h3 className="title">Share Your Knowledge</h3>
                <p className="subtitle">Create your dream class with Donanum</p>
                <Link to="/GetApproveMark" style={{ color: "#0097bd" }}>
                  Open a class >
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default FifthSection;
