import React from "react";

import Marketing from "../../images/marketing-icon.png";
import Animation from "../../images/animation-icon.png";
import Art from "../../images/art-icon.png";
import Business from "../../images/business-icon.png";
import Design from "../../images/design-icon.png";
import Music from "../../images/music-icon.png";
import Programming from "../../images/programming-icon.png";
import Lifestyle from "../../images/lifestyle-icon.png";

import "./MainPageCategoryList.css";

class MainPageCategoryList extends React.Component {
  render() {
    return (
      <div className=" margin-con ">
        <h2 className="md-title ">Explore Tutoring, and Mentoring</h2>
        <div className="">
          <div className="flex MPC-list-con">
            <div className="MPC-width">
              <a href="/explore?category=design">
                <img src={Design} className="" alt="Design icon" />
                <p>Design</p>
              </a>
            </div>
            <div className="MPC-width">
              <a href="/explore?category=marketing">
                <img src={Marketing} className="" alt="Marketing icon" />
                <p>Marketing</p>
              </a>
            </div>
            <div className="MPC-width">
              <a href="/explore?category=art_craft">
                <img src={Art} className="" alt="Art icon" />
                <p>Art & craft</p>
              </a>
            </div>
            <div className="MPC-width">
              <a href="/explore?category=video_animation">
                <img src={Animation} className="" alt="Animation icon" />
                <p>Video & animation</p>
              </a>
            </div>
          </div>
          <div className="flex">
            <div className="MPC-width">
              <a href="/explore?category=music">
                <img src={Music} className="" alt="Music icon" />
                <p>Music</p>
              </a>
            </div>
            <div className="MPC-width">
              <a href="/explore?category=programming">
                <img src={Programming} className="" alt="Programming icon" />
                <p>Programming </p>
              </a>
            </div>
            <div className="MPC-width">
              <a href="/explore?category=business">
                <img src={Business} className="" alt="Business icon" />
                <p>Business</p>
              </a>
            </div>
            <div className="MPC-width">
              <a href="/explore?category=lifestyle">
                <img src={Lifestyle} className="" alt="Lifestyle icon" />
                <p>LifeStyle</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default MainPageCategoryList;
