import React from "react";
import { Col, Row } from "../../../ui";

import DatesOrTooLate from "../DatesOrTooLate";
import { priceOrFree } from "../../../services/Utils";

// import DateTime from "../../../components/DateTime";
// import ProfileImg from "../../../components/profileImg/ProfileImg";
import "./postRegisterSectionSM.css";

class PostRegisterSection extends React.Component {
  render() {
    const { title, price, courseId, tooLate, is_free } = this.props;
    return (
      <div className="PostRegisterSectionSM d-lg-none">
        <Row className="">
          <Col xs={8} sm={8}>
            <div className="title">
              <h2 className="post-sm-title">{title}</h2>
              <div className="sm-hour">{priceOrFree(price, is_free)}</div>
            </div>
          </Col>
          <Col xs={4} sm={4}>
            <div className="flex hour-btn">
              <DatesOrTooLate id={courseId} tooLate={tooLate} />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
export default PostRegisterSection;
