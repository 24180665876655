
class CategoryService {
  constructor() {
    // this.DataServiceStatic = DataServiceStatic;
    // this.loadCategories();
    this.data = data;
  }
  loadCategories(cb) {
    this.data = data;
    // this.DataServiceStatic.getAxios()
    //   .get("category/")
    //   .then(response => {
    //     console.log("[CategoryService.js] reso", response.data);
    //     this.data = response.data;
    //     if (cb) cb(null, response.data);
    //   })
    //   .catch(err => {
    //     if (cb) cb(err, null);
    //   });
  }
  getParents() {
    return this.data.filter(cat => cat.parent == null);
  }
  getChildren(parentId) {
    return this.data.filter(cat => cat.parent === parentId);
  }
}

export const CategoryServiceStatic = new CategoryService();
export default CategoryService;
window.CategoryServiceStatic = CategoryServiceStatic;
window.CategoryService = CategoryService;

const data = [
  {
    id: 1,
    title: "Programming",
    parent: null
  },
  {
    id: 2,
    title: "JS",
    parent: 1
  },
  {
    id: 3,
    title: "HTML",
    parent: 1
  },
  {
    id: 4,
    title: "CSS",
    parent: 1
  },
  {
    id: 5,
    title: "Human Languages",
    parent: null
  },
  {
    id: 6,
    title: "English Novel Writing",
    parent: 5
  },
  {
    id: 7,
    title: "Korean Grammar",
    parent: 5
  }
];
