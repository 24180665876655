import React from "react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";
import { store } from "../services/EventBus";
import Footer from "./footer/Footer";
import Nav from "./nav/Nav";
import CookieText from "../routes/cookie/CookieText";
import ScrollToTop from "./ScrollToTop";
import history from "../services/history";
import "./font.css";
import "./style.css";
import "./variable.css";

class App extends React.Component {
  render() {
    return (
      <div className=" ">
        <Provider store={store}>
          <Router history={history}>
            <ScrollToTop>
              <div>
                <Nav />
                <Footer />
                <CookieText />
              </div>
            </ScrollToTop>
          </Router>
        </Provider>
      </div>
    );
  }
}
export default App;
