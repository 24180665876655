import React from "react";
import { Col, Row } from "../../ui";
import { Link } from "react-router-dom";
import BannerImg from "../images/donanum-tutor-p.jpg";
import LevelIcon from "../images/approved-mark.png";
import QIcon from "../images/q_icon.png";
// import HdIcon from s"../images/hd_icon.png";
import FileIcon from "../images/text-upload.png";
import HImgIcon from "../images/image-upload.png";
import "./beTutorInfo.css";

import { isUserVerified, isUserLoggedIn } from "../../services/DataService";

function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

class BeTutorInfo extends React.Component {
  constructor() {
    super();
    this.state = {
      linkToStart: "/GetApproveMark"
    };
  }
  componentDidMount() {
    const verified = isUserVerified();
    if (verified) {
      this.setState({ linkToStart: "/CourseType" });
    }
    if (!isUserLoggedIn()) {
      this.setState({ linkToStart: "/LoginRequired?back=/CourseType" });
    }
  }
  render() {
    return (
      <div className="beTutorInfoContainer">
        <div
          className="br herobanner"
          style={{
            background: "url(" + require("../images/herobanner_v4.png") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center 0px",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className="container">
            <h1 className="side-hero-title">
              Share Your Skills with the World
            </h1>
            <p>
              Start earning money by becoming a tutor and sharing your favorite
              skills today.
            </p>
            <div className="hero-btn">
              <Link onClick={() => topFunction()} to={this.state.linkToStart}>
                Open a class now
              </Link>
            </div>
          </div>
        </div>

        <div className="container margin-con ">
          <div className="row ">
            <Col className="m-sm-bottom" xs={12} md={4}>
              <div className=" " />
              <h5 className="md-s-title">Quick & Easy Connection</h5>
              <p className="md-p">
                Our special algorithm allows you to connect with students in
                seconds so you can jump-start your classes.
              </p>
            </Col>
            <Col className="m-sm-bottom" xs={12} md={4}>
              <div className=" " />
              <h5 className="md-s-title">Your Skills Are Needed</h5>
              <p className="md-p">
                Your talent and skills are more in-demand than you think! Reach
                out to a new audience today!
              </p>
            </Col>
            <Col className="m-sm-bottom" xs={12} md={4}>
              <div className=" " />
              <h5 className="md-s-title">Meet People, Change Lives</h5>
              <p className="md-p">
                Teaching is a rewarding experience, and changing the lives of
                your students is on its own level.
              </p>
            </Col>
          </div>
        </div>

        <div className="container margin-con flex review">
          <Row className="">
            <Col xs={12} sm={6} className="quote-con">
              <div className="quote-icon ">
                <img alt="" src={QIcon} />
              </div>
              <p className="reviewer-text">
                My goal was to teach, but with Donanum, I also learned a thing
                or two
              </p>
              <p className="md-p">
                I opened up my own 2-hour web developing sessions in the hopes
                of spreading programming to those who needed it. I looked up new
                resources to broaden my skills, but through my students, I
                learned even more as we shared what we knew with each other.
                Since opening my class, I even learned a few new tricks while I
                am preparing a class and from students. I would definitely
                recommend making new classes, not just joining them.
              </p>
              <p className="username">John, Web Master - Vancouver BC</p>
              <div className="carousel-btn">
                <Link onClick={() => topFunction()} to={this.state.linkToStart}>
                  Open class now
                </Link>
              </div>
            </Col>
            <Col xs={12} sm={6} className="margin-sm-con ">
              <div className="cooking-img">
                <img alt="" src={BannerImg} />
              </div>
            </Col>
          </Row>
        </div>

        <div className="container margin-con ">
          <h3 className="md-title center m-bottom">
            It's Super-Easy to Create a Class
          </h3>
          <div className="row">
            <Col className="m-sm-bottom" xs={12} md={4} lg={4}>
              <div className="circle">1</div>
              <h5 className="sm-title">Open a class</h5>
              <p className="sm-p">
                Click the “Open Your Class” button and fill in all the info
                about your experience and what your class will be about
              </p>
            </Col>

            <Col className="m-sm-bottom" xs={12} md={4} lg={4}>
              <div className="circle">2</div>
              <h5 className="sm-title">Confirm Your Students</h5>
              <p className="sm-p">
                Once a student registers for your class, you will get a
                notification email. Simply click the button in the email and you
                will be connected with the student in seconds.
              </p>
            </Col>
            <Col className="m-sm-bottom" xs={12} md={4} lg={4}>
              <div className="circle">3</div>
              <h5 className="sm-title">Connect and Share</h5>
              <p className="sm-p">
                Now that you’re connected, start teaching and reward yourself
                knowing that you’re making a difference (and with a little bit
                of extra money!).
              </p>
            </Col>
          </div>
        </div>

        <div className="container margin-con">
          <h3 className="md-title center m-bottom">Becoming a Popular Class</h3>
          <div className="row">
            <Col className="bti-icon-con" xs={12} md={4} lg={4}>
              <div className="bti-icon">
                <img alt="" src={FileIcon} />
              </div>
              <h5 className="sm-title">Provide a Detailed Description</h5>
              <p className="sm-p">
                The more students know about what your class is about, they will
                have a better experience, learn more, and through word of mouth,
                get you more students.
              </p>
            </Col>
            <Col className="bti-icon-con" xs={12} md={4} lg={4}>
              <div className="bti-icon">
                <img alt="" src={HImgIcon} />
              </div>
              <h5 className="sm-title">Think Visual</h5>
              <p className="sm-p">
                It’s best to upload high-quality images to really show what your
                class is about. Make them stand out, make them
                visually-appealing, and make them exciting. Don’t use stock
                photos or bust out that 144p!
              </p>
            </Col>

            <Col className="bti-icon-con" xs={12} md={4} lg={4}>
              <div className="bti-icon">
                <img alt="" src={LevelIcon} />
              </div>
              <h5 className="sm-title">Get Verified</h5>
              <p className="sm-p">
                Getting yourself verified is a proof that you’re credible. Your
                students will be relieved knowing that you know your stuff. All
                you have to do us send us documentation of your experience and
                new students will start signing up in no time.
              </p>
              {/* <Link to="Login" className="learnmore-btn">
                Learn more
              </Link> */}
            </Col>
          </div>
        </div>

        <div className="readyCon">
          <div
            className="banner"
            style={{
              background:
                "url(" +
                require("../images/donanum-learn-bottom-banner02.jpg") +
                ")",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="container fifthMargin">
              <h2 className="">Ready to Teach?</h2>
              <div className="getStart-btn">
                <Link onClick={() => topFunction()} to="/explore">
                  Get Started
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BeTutorInfo;
