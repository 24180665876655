/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import FeeGuide from "./BetaFeeGuide";

import "./policy.css";

class ServicePolicy extends React.Component {
  render() {
    return (
      <div className="termpolicy-con">
        <div className="policy-title">
          <h2 className="">Terms of Service</h2>
        </div>
        <div>
          <p>Updated date: 2019 June </p>
          <p className="link">Welcome to Donanum.com.</p>
          <p>
            The following terms and conditions (these "Terms of Service"),
            govern your access to and use of the Donanum website, including any
            content, functionality and services offered on or through{" "}
            <span className="link">www.Donanum.com</span> (the "Site"), by
            Donanum.
          </p>
          <p>
            Please read the Terms of Service carefully before you start to use
            the Site. By using the Site, opening a class or by clicking to
            accept or agree to the Terms of Service when this option is made
            available to you, you accept and agree to be bound and abide by this
            Policy (Terms of Service) and our Privacy Policy, found here,
            incorporated herein by reference. If you do not want to agree to
            this Policy (Terms of Service) or the Privacy Policy, you must not
            access or use the Site. For more detailed policies surrounding the
            activity and usage on the Site, please access the designated
            articles herein.
          </p>
          <p>
            This Site is offered and available to users who are 13 years of age
            or older. If you are under 13 you may not use this Site or the
            Donanum services. If you are under 21 you may not create a class on
            Donanum site. By using this Site, you represent and warrant that you
            are of legal age to form a binding contract and meet all of the
            foregoing eligibility requirements. If you do not meet all of these
            requirements, you must not access or use the Site. These Terms and
            Conditions are effective from the moment you use the service, such
            as registering for the site and viewing content on the Donanum site.
            If you have any questions regarding the Site or Terms of Service,
            contacting our Customer Support team can be performed by submitting
            a request <span className="link">here</span>.
          </p>
        </div>
        <hr />
        <div>
          <h3>Changes to this Agreement and the Platform</h3>
          <p>
            Company reserves the right, at its sole and absolute discretion, to
            change, modify, add to, supplement, suspend, discontinue, or delete
            any of the terms and conditions of this Agreement (including the
            Terms of Service and Privacy Policy) and review, improve, modify, or
            discontinue, temporarily or permanently, the Donanum Platform or any
            content or information through the Donanum Platform at any time,
            effective with or without prior notice and without any liability to
            Company. Company will endeavor to notify you of material changes by
            email, but will not be liable for any failure to do so. If any
            future changes to this Agreement are unacceptable to you or cause
            you to no longer be in compliance with this Agreement, you must
            terminate, and immediately stop using, the Donanum Platform. Your
            continued use of the Donanum Platform following any revision to this
            Agreement constitutes your complete and irrevocable acceptance of
            any and all such changes. Company may also impose limits on certain
            features or restrict your access to part or all of the Donanum
            Platform without notice or liability.
          </p>
          <p>
            We will announce the changes through the website, App, or email.
            After any platform updates, ny user still using our platform will be
            assumed to be in agreement with the terms and conditions. All
            clients who disagree with the terms and conditions must stop using
            the site immediately.
          </p>
        </div>
        <hr />
        <div>
          <h3>Key Terms:</h3>
          <p>
            <span className="sm-title">Student:</span> One who is looking for a
            tutor, registered to the site after agreeing to the Terms &
            Conditions and Privacy Policy.
          </p>
          <p>
            <span className="sm-title">Tutor: </span>One who is looking for a
            student, registered to the site after agreeing to the Terms &
            Conditions and Privacy Policy.
          </p>
          <p>
            <span className="sm-title">Verification Icons: </span>These icons
            are given to tutors who have registered and submitted proof of
            qualification for their area of speciality{" "}
            <span className="link">learn more > </span>
          </p>
          <p>
            <span className="sm-title">Users:</span> Students and Tutors
          </p>
          <p>
            <span className="sm-title">Class:</span> A session opened by a Tutor
            based on their knowledge and skill that is offered to Students
          </p>
          <p>
            <span className="sm-title">Overtime:</span> For the first class
            only, the Tutor has the responsibility to spend over 20 minute with
            students after class, if necessary.
          </p>
          <div>
            <span className="sm-title">Late policy:</span>
            <ul>
              <li>
                Tutor must not be late for their class. Tutors who are later
                than 10 minutes to a class are not entitled to a one hour
                charge.
              </li>

              <li>
                Student must not be late for their class. Students who are later
                than 10 minutes to a class are not entitled to have their
                missing time covered.
              </li>
              <li>
                If the Student needs extra time, the Student will need to
                discuss it with the Tutor and pay the correct rate to the Tutor
                for the additional time.
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div>
          <h3>Purpose of (intermediary) services</h3>
          <p>
            The Donanum Platform is a web-based communications platform which
            enables connections between Students and Tutors. The Donanum service
            provides a secure trading place online so the student and tutor can
            make safe and convenient payments. The company only provides the
            platform to enhance the safety and reliability of transactions
            between members. The Donanum Platform only enables connections
            between Students and Tutors. Company is not responsible for the
            performance or communications of Users. Company makes no warranties
            or representations about the suitability, reliability, timeliness,
            or accuracy of any class. However, we will guarantee to try to make
            the service better. If you have comments, please contact our
            customer service.
          </p>
        </div>
        <hr />
        <div>
          <h3>Collection and protection of User information </h3>

          <p>
            1) When you register to the Site, we ask you to provide certain
            personal information, including a valid email address, location,
            Facebook or Google account login details and username. We may
            collect additional information you provide us, such as physical
            address, telephone number, or other contact details, financial
            information (such as payment method and credit card number), and
            additional authentication information, to provide you with the
            services you need (such as payment or withdrawal of funds, sending
            physical goods), or to ensure the marketplace integrity (for
            example, prevention of fraud).
          </p>
          <p>
            2) In addition, we collect information while you access, browse,
            view or otherwise use the Site. In other words, when you access the
            Site, we are aware of your usage of the Site, and may gather,
            collect, and record the information relating to such usage,
            including geo-location information, IP address, device and
            connection information, browser information and web-log information,
            and all communications recorded by Users through the Site. We use
            that information to enhance user experience, personalize your
            browsing experience, as well as monitor the Site for preventing
            fraud and inappropriate content or behaviour. We may also collect
            supplemental information obtained from third parties such as
            demographic and navigation data.
          </p>
          <p>
            3) Additionally, in order to improve your online experience at
            Fiverr, we have implemented impression reporting. While you view our
            ads, we may gather user a Global Unique Identifier and HTTP request
            data like user agent, IP, host, URL, country/continent from which a
            request was made, browser info, device/operating system/operating
            system version.
          </p>
          <p>
            4) Once you register, your username and additional information
            regarding your activity is made public and is visible to all Users
            of the Site. This information includes photos you upload, your
            published portfolio, Gig information, ratings, and additional
            information you may choose to add to your profile.
          </p>
          <p>
            5) Users can view information about themselves at any time, and can
            ask the company to update the information.
          </p>
          <p>
            6) If it is necessary to preserve in accordance with relevant laws
            and regulations such as commercial law, consumer protection law in
            e-commerce, etc., the company keeps the member information for a
            certain period determined by related laws and regulations.
          </p>
          <p>
            7) In the event of damage to others due to the dissemination of
            defamatory or false facts, for the purpose of protecting members and
            companies from the sale of infringement of intellectual property
            rights or other Internet fraud, etc., and for the purpose of
            cooperating with investigations in accordance with legal procedures,
            Company keeps necessary information such as the member's account,
            name or trade name, contact information, other received personal
            information, and information about termination and suspension of
            membership in case of goods transaction history for 6 months.
            Payment information is handled by Stripe payment provider please
            refer to <span className="link">Privacy Policy here</span>.
          </p>
          <a href="">Learn more about the Privacy Policy > </a>
        </div>
        <hr />
        <div>
          <h3>Termination and Suspension</h3>
          <p>
            Company may terminate or limit your right to use the Donanum
            Platform in the event that we are investigating or believe that you
            have breached any provision of this Agreement (a “User Breach”), by
            providing you with written or email notice. Such termination or
            limitation will be effective immediately upon delivery of such
            notice.
          </p>
          <p>
            If Company terminates or limits your right to use the Donanum
            Platform pursuant to{" "}
            <a href="#1">
              <span>Section 8</span>
            </a>
            , you are prohibited from registering and creating a new account
            under your name, a fake or borrowed name, or the name of any third
            party, even if you may be acting on behalf of the third party. If
            Company terminates or limits your right to use the Donanum Platform
            as a Client due to a User Breach, you will not be entitled to any
            refund of unused balance in your account.
          </p>
          <p>
            Even after your right to use the Donanum Platform is terminated or
            limited, this Agreement will remain enforceable against you. Company
            reserves the right to take appropriate legal action, including, but
            not limited to pursuing arbitration in accordance with Section 19 of
            these Terms of Service. Company reserves the right to modify or
            discontinue, temporarily or permanently, all or any portion of the
            Donanum Platform at its sole discretion. Company is not liable to
            you for any modification or discontinuance of all or any portion of
            the Donanum Platform. Company has the right to restrict anyone from
            completing registration as a User if Company believes such person
            may threaten the safety and integrity of the Donanum Platform, or
            if, in Company’s discretion, such restriction is necessary to
            address any other reasonable business concern. You may terminate
            this Agreement at any time by ceasing all use of the Donanum
            Platform. All parts of this Agreement which by their nature should
            survive the expiration or termination of this Agreement shall
            continue in full force and effect subsequent to and notwithstanding
            the expiration or termination of this Agreement.
          </p>
        </div>
        <hr />
        <div>
          <div>
            <p id="1">Section 8</p>
            <h4>1) Illegal promotional activities</h4>
            <p>
              The Company may limit the use of illegal promotional activities
              using Donanum services on other sites, etc., or to detect unlawful
              acts on company servers. Following the criteria below, however,
              the company may have higher limits than the standard, depending on
              the degree of violation.
            </p>
            <p>Details of limitations based on the number of incidents:</p>
            <ul>
              <li>
                <span className="policy-bold">First time:</span> Warning
              </li>
              <li>
                <span className="policy-bold">Second time:</span> 30 days
                suspension
              </li>
              <li>
                <span className="policy-bold">Third time:</span> Permanent
                deactivation of account
              </li>
            </ul>
          </div>
          <div>
            <h4>2) Limitations of Payment from Students to Tutors</h4>
            <p>
              The Company imposes the following limitations on direct deed
              inducement activities in accordance with the number of times it is
              detected to enhance mutual trust and guarantee the quality of
              service. Following the criteria below, however, the company may
              have higher limits than the standard, depending on the degree of
              violation. In the case of re-entry after termination of the
              contract, the limit imposed on the member before termination of
              the contract is retained, and the period from termination to
              re-entry may be excluded from the period of counting the number of
              seizures.
            </p>
            <p>Details of limitations based on the number of incidents:</p>
            <ul>
              <li>
                <span className="policy-bold">First time:</span> Warning
              </li>
              <li>
                <span className="policy-bold">Second time:</span> 30 days
                suspension
              </li>
              <li>
                <span className="policy-bold">Third time:</span> Permanent
                deactivation of account
              </li>
            </ul>
          </div>
          <div>
            <h4>
              3) Unfair purchase behavior, illegal card transactions or
              registration of illegal trading course{" "}
            </h4>
            <p>
              Donanum has the right to terminate any account that produces fake
              information, uses illegal card transactions, or registers illegal
              card transactions or illegal communication billing services.
            </p>
          </div>
          <div>
            <h4>4) Reporting Violations </h4>
            <p>
              If you come across any content that may violate our Terms of
              Service, you should report it to us through the appropriate
              channels created to handle those issues as outlined in our Terms
              of Service. All cases are reviewed by our Trust & Safety team. To
              protect individual privacy, the results of the investigation are
              not shared. You can review our Privacy Policy for more
              information.
            </p>
          </div>
          <div>
            <h4>5) Violations</h4>
            <p>
              Users may receive a warning on their account for violations of our
              Terms of Service or any user misconduct reported to our Trust and
              Safety team. A warning will be sent to the user's email address
              and will be displayed for such user on the Site. Warnings do not
              limit account activity, but can lead to your account losing
              Tutor/Student statuses or becoming permanently disabled based on
              the severity of the violation.
            </p>
          </div>
          <div>
            <h4>6) Non-Permitted Usage</h4>
            <ul>
              <li>
                Adult Services & Pornography - Fiverr does not allow any
                exchange of adult-oriented or pornographic materials and
                services.
              </li>
              <li>
                Inappropriate Behavior & Language - Communication on Fiverr
                should be friendly, constructive, and professional. Fiverr
                condemns bullying, harassment, and hate speech towards others.
                We allow users a medium for which messages are exchanged between
                individuals, a system to rate orders, and to engage on larger
                platforms such as our Community Forum and Social Media pages.
              </li>
              <li>
                Phishing and Spam - Fiverr takes the matter of its members’
                security seriously. Any attempts to publish or send malicious
                content with the intent to compromise another member’s account
                or computer environment is strictly prohibited. Please respect
                our members privacy by not contacting them with offers,
                questions, suggestions, or anything which is not directly
                related to their courses.
              </li>
              <li>
                Privacy & Identity - You may not publish or post other people's
                private and confidential information. Any exchange of personal
                information required for the completion of a service must be
                provided in the order page. Tutors further confirm that whatever
                information they receive from the Student, which is not public
                domain, shall not be used for any purpose whatsoever other than
                for the delivery of the course to the Student. Any users who
                engage and communicate off of Fiverr will not be protected by
                our Terms of Service.
              </li>
              <li>
                Authentic Fiverr Profile - You may not create a false identity
                on Fiverr, misrepresent your identity, create a Fiverr profile
                for anyone other than yourself (a real person), or use or
                attempt to use another user’s account or information; your
                profile information, including your description, skills,
                location, etc., while maybe kept anonymous, must be accurate and
                complete and may not be misleading, illegal, offensive, or
                otherwise harmful. Fiverr reserves the right to require users to
                go through a verification process in order to use the Site
                (whether by using ID, phone, camera, etc.).
              </li>
              <li>
                Intellectual Property Claims - Fiverr will respond to clear and
                complete notices of alleged copyright or trademark infringement,
                and/or violation of third party’s terms of service. Our
                Intellectual Property claims procedures can be reviewed here.
              </li>
              <li>
                Fraud / Unlawful Use - You may not use Fiverr for any unlawful
                purposes or to conduct illegal activities.
              </li>
            </ul>
          </div>
          <div>
            <h4>7) Abuse and Spam</h4>
            <ul>
              <li>
                Multiple Accounts - To prevent fraud and abuse, users are
                limited to one active account. Any additional account determined
                to be created to circumvent guidelines, promote competitive
                advantages, or mislead the Fiverr community will be disabled.
                Mass account creation may result in disabling of all related
                accounts. Note: any violations to Fiverr’s Terms of Service is
                cause for permanent suspension of all accounts.
              </li>
              <li>
                Targeted Abuse - We do not tolerate users who engage in targeted
                abuse or harassment towards other users on Fiverr. This includes
                creating new multiple accounts to harass members through our
                message or ordering system.
              </li>
              <li>
                Selling Accounts - You may not buy or sell Fiverr accounts.
              </li>
            </ul>
          </div>
          <div>
            <h4>8) Disconnection (Out of contact) / Loss of Contact </h4>
            <p>
              “Out of contact” refers to a state in which the Tutor does not
              respond at all despite attempts by the Student to contact the
              Tutor through phone, e-mail or other means.{" "}
            </p>
            <p>
              The Tutor should immediately notify the Company in the event that
              normal class activities are not possible or special exceptions
              must be made. If the class cannot be normal, or if this situation
              is expected, the Company will make every effort to expedite
              cancellation, refund, etc. In the event of a claim arising out of
              losing contact with the tutor, the Company may take one of the
              following measures.
            </p>
            <ul>
              <li>
                <span className="policy-bold">1st warning:</span> Request for
                improvement.{" "}
              </li>
              <li>
                <span className="policy-bold">2nd warning:</span> If a Tutor
                remains out of contact after the 1st warning (1 week after their
                1st warning), the Company will issue a 2nd warning. In this
                case, Donanum maintains the right to take one or more of the
                following actions:
                <ul>
                  <li>Suspension of all open classes held by the Tutor</li>
                  <li>Issuance of refunds to student</li>
                  <li>Restrictions on new class registrations</li>
                </ul>
              </li>
              <li>
                <span className="policy-bold">3rd warning:</span> Tutor remains
                out of contact after the 2nd warning (1 week after their 2nd
                warning)
                <ul>
                  <li>he Tutor’s classes will be terminated and </li>
                  <li>The account will be permanently suspended.</li>
                </ul>
              </li>
            </ul>
          </div>
          <div>
            <h4>9) Delayed Response and General Complaints</h4>
            <p>
              To provide satisfactory service to Users, the Tutor is responsible
              to respond to messages from a User in a timely manner. If Donanum
              receives complaints from Students about a class or Tutor, we will
              contact the Tutor directly in an effort to solve any issues at
              hand.
            </p>
            <ul>
              <li>1st Complaint: Request for improvement.</li>
              <li>
                2nd Complaint: Class may be stopped and an effort must be made
                to improve the class. Before resuming the class, you may be
                required to send proof of preparedness to Donanum.
              </li>
              <li>
                3rd Complaint: All classes will be terminated and a restriction
                may be put in place on future registrations of classes.
              </li>
            </ul>
          </div>
        </div>
        <hr />
        <div>
          <h3>Account, Password, Security, and Mobile Phone Use</h3>
          <p>
            You must register with Company and create an account to use the
            Donanum Platform (an “Account”). You are the sole authorized user of
            your account. You are responsible for maintaining the
            confidentiality of any log-in, password, and account number provided
            by you or given to you by Company for accessing the Donanum
            Platform. You are solely and fully responsible for all activities
            that occur under your password or account, even if not authorized by
            you. If you are accessing and using the Donanum Platform on someone
            else’s behalf, you represent that you have the authority to bind
            that person as the principal to all Terms provided herein. Company
            has no control over the use of any User’s account and expressly
            disclaims any liability derived therefrom. Should you suspect that
            any unauthorized party may be using your password or account or you
            suspect any other breach of security, you agree to contact Company
            immediately. By providing your mobile phone number and using the
            Donanum Platform, you hereby affirmatively consent to Donanum’s use
            of your mobile phone number for calls and recurring texts,
            (including with an autodialer and/or prerecorded voice) in order to
            (a) perform and improve upon the Donanum Platform, (b) facilitate
            the carrying out of Classes through the Donanum Platform, (c)
            provide you with information and reminders regarding your
            registration, orientation, upcoming Classes, product alterations,
            changes and updates, service outages or alterations. These calls and
            texts may include, among other things, reminders about uncompleted
            or upcoming Classes and/or in follow up to any push notifications
            delivered through our mobile application. Company will not assess
            any charges for calls or texts, but standard message, data, or other
            charges from your wireless carrier may apply. You may opt-out of
            receiving texts messages from us by modifying your account settings
            on the Site or Apps, texting “STOP” in response to any texts, or by
            emailing opt-out-texts@taskrabbit.com and specifying you want to
            opt-out of texts. You may opt-out of receiving calls from us by
            stating that you no longer wish to receive calls during any call
            with us, or by emailing opt-out-texts@taskrabbit.com and specifying
            you want to opt-out of calls. You understand that we may send you a
            text confirming any opt-out by you.
          </p>
        </div>
        <hr />
        <div>
          <h3>Secure Payment Service </h3>
          <ul>
            <li>
              The Donanum service provides a secure trading place online so
              Students and Tutors can make safe and convenient payments. All
              Users must follow Donanum’s payment service system.
            </li>
            <li>
              Users must agree to use Donanum’s secure payment service to use
              the service.
            </li>
            <li>
              Any Users who try to circumvent the aforementioned payment model
              by getting direct pay from Users may be suspended.
            </li>
            <li>
              Donanum is not responsible or liable for any fraud, theft, or any
              type of monetary losses accrued by Users outside of the secure
              payment platform mentioned above.
            </li>
            <li>
              Credit cards may be used for payment within Donanum’s system. But
              if it is stolen credit card or the information is different, the
              payment will be terminated or canceled.
            </li>
          </ul>
        </div>
        <hr />
        <FeeGuide />
      </div>
    );
  }
}
export default ServicePolicy;
