import React from "react";

class RefundPolicy extends React.Component {
  render() {
    return (
      <div className="refund-con">
        <div className="policy-title">
          <h2>Refund policy </h2>
        </div>
        <div>
          <p>Updated date: 2019 June </p>
          <h3>Disputes and Cancellations</h3>
          <p>
            We encourage our Students and Tutors to try and settle conflicts
            amongst themselves. If for any reason this fails after using the
            Resolution Center or if you encounter non-permitted usage on the
            Site, users can contact Donanum Customer Support department for
            assistance here.
          </p>
          <h3>Basics</h3>
          <p>
            Registration cancellation can be performed on Donanum, when
            eligible, by Customer Support or through the Resolution Center. If
            the class is expired, the Student misses the class, or if Student
            decides not to continue after the second class, Donanum cannot help
            to refund the tuition fee. At this point, the Student must make
            arrangements with the Tutor directly.
          </p>
        </div>
        <hr />
        <div>
          <h3>Classes Longer Than 2 Days </h3>
          <div>
            <p>
              1) If a Student cancels the class 48 hours in advance, they can
              get a 100% refund. Otherwise will get 80% refund.
            </p>
            <p>
              2) If a Student cancels the day of or wishes to cancel the class
              after taking one class, they will get an 80% refund from the total
              class fee.
            </p>
            <p>
              3) After the second class begins, Students are no longer eligible
              for a refund, barring an event covered in sections 4 or 5 below.
            </p>
            <p>
              4) If a Student wishes to stop the class for one of the following
              situations:
              <ul>
                <li>
                  After the second class, Students are no longer eligible for a
                  refund
                </li>
                <li>
                  However, after the second class, if the Tutor and Student
                  agree, they can cancel the class. At that point, the Tutor is
                  responsible for refunding the student directly. Donanum is not
                  involved nor is responsible for transactions after this point.
                </li>
                <li>
                  If a Student requests a refund and cancels the class because
                  of a problem arising with the other party, including, but not
                  limited to, displeasure of class flow, lack of preparation,
                  threats, violence, sexual harassment, illegal activities,
                  etc., Donanum may become involved in investigating the
                  accusations, which may result in further legal action, as well
                  as possible account suspensions/terminations. If the Tutor is
                  found guilty of any of the aforementioned actions, the Tutor
                  must fully refund the Student (if applicable). The Tutor will
                  also still be compelled to pay the service fee.
                </li>
              </ul>
            </p>
            <p>
              5) If a Tutor wishes to stop the class for one of the following
              situations:
              <ul>
                <li>
                  If the Tutor must stop/cancel the class before/after class has
                  started, a full refund will be issued to the Student, no
                  matter how many dates remain.
                </li>
                <li>
                  If the Tutor cancels the class due to a personal problem, they
                  will still be required to pay Service fee to Donanum.
                </li>
                <li>
                  Personal problems, including health, career, study abroad,
                  etc., still require tutors to give full refunds to students if
                  required to as outlined in the policy.
                </li>
              </ul>
            </p>
            <p>
              6) Getting Refunds: If Student pays the Tutor directly, Donanum
              will not be involved in any stage of the transaction. We stress
              that for security and for fraud prevention that all transactions
              be paid for through Donanum. If Student pays through the Donanum
              website/App, we will honour the refund based on the Refund policy
              (link to top).
            </p>
            <p>
              7) If a conflict arises between the Tutor and Student about
              refunds after class has expired, the Student and Tutor must solve
              the problem themselves. Donanum will not become involved after a
              class has expired.
            </p>
          </div>
        </div>
        <hr />
        <div>
          <h3>One Day Class, Tutoring and Mentoring </h3>
          <div>
            <p>
              1) If Student cancels a class within 24 hours of the class, the
              Student can get a 100% refund. If Student cancels with less than
              24 hours remaining before the class, the Student can get an 80%
              refund.
            </p>
            <p>
              2) If Student has already taken a class and misses the class, the
              Student cannot get a refund.
            </p>
            <p>
              3) Once Tutor start the class these will affect:
              <ul>
                <li>
                  Once a One Day class/Tutoring/Mentoring class begins, a
                  Student loses the right to a refund.
                </li>
                <li>
                  If the Student and Tutor come to an agreement that there is a
                  problem in the class, the Tutor is responsible to refund the
                  Student directly. Donanum will not be involved.{" "}
                </li>
                <li>
                  If Student believes there is a problem with the Tutor and
                  requests a refund, Donanum can be involved and will do their
                  best to find a solution. If the Tutor is found to be
                  inadequately prepared for the class, create an uncomfortable
                  environment, is involved in an assault, sexual molestation,
                  threat, or other illegal activity, the police or other
                  appropriate authority (legal or otherwise) may be contacted
                  depending on the action. The Tutor’s account may also be
                  terminated.{" "}
                </li>
                <li>
                  If a conflict arises between the Tutor and Student regarding
                  refunds after class has expired, after a few days or classes
                  has elapsed, or if the payment did not pass through the
                  website/app, the Student and Tutor must solve the problem
                  themselves. Donanum will not become involved after a class has
                  expired.
                </li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
export default RefundPolicy;
