import React from "react";

import Img from "../../routes/images/profile.png";

import "./profileImg.css";

export const ProfileImg = ({ url }) => {
  const userImg = url || Img;
  return (
    <div className="profile-con">
      <img alt="user" src={userImg} />
    </div>
  );
};

export default ProfileImg;
