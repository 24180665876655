import React from "react";
import GoogleAuthorize from "react-google-authorize";
import axios from "axios";
import GoogleIcon from "../../routes/images/google-icon.png";
import { DataServiceStatic } from "../../services/DataService";
import Spin from "antd/lib/spin";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID; // from google console
const POST_URL = process.env.REACT_APP_GOOGLE_POST_URL; // API server url
const REDIRECT_URL = process.env.REACT_APP_GOOGLE_REDIRECT_URL; // react app url

class LoginWithGoogle extends React.Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.state = { clicked: false };
  }
  onGoogleResponse = (err, response, onOk) => {
    if (err) {
      console.error(err);
      this.setState({ clicked: false });
      return;
    }

    axios
      .post(POST_URL, {
        code: response.code,
        provider: "google-oauth2",
        state: response.session_state,
        clientId: CLIENT_ID,
        redirectUri: REDIRECT_URL
      })
      .then(function(response) {
        DataServiceStatic.userSocialLogin(
          {
            provider: "google",
            token: response.data.token
          },
          (err, data) => {
            onOk();
          }
        );
      })
      .catch(function(error) {
        console.log(error);
      });
  };
  onOk() {
    console.log("[LoginWithGoogle.js] this.props", this.props);
    this.props.onOk();
    this.setState({ clicked: false });
  }
  render() {
    return (
      <>
        <GoogleAuthorize
          clientId={CLIENT_ID}
          buttonText="Google Login"
          onSuccess={rs => this.onGoogleResponse(null, rs, this.onOk)}
          onFailure={err => this.onGoogleResponse(err, null)}
          responseType="code"
          style={{ background: "white", border: "0", width: "100%" }}
          tag={this.props.textOnly ? "span" : "button"}
          type={this.props.textOnly ? "sp" : "button"}
          scope="profile email"
          onRequest={e => {
            this.setState({ clicked: true });
          }}
        >
          <>
            {this.props.textOnly && (
              <a href="/#/" onClick={e => e.preventDefault()}>
                Google
              </a>
            )}
            {!this.props.textOnly && (
              <Spin spinning={this.state.clicked}>
                <div className="login-btn g-btn-con flex">
                  <div className="googleIcon-con">
                    <img src={GoogleIcon} alt="" />{" "}
                  </div>

                  <span>{"  "}Login wih Google</span>
                </div>
              </Spin>
            )}
          </>
        </GoogleAuthorize>
      </>
    );
  }
}

export default LoginWithGoogle;
