import React from "react";
// import { Link } from "react-router-dom";

import InfiniteCarousel from "react-leaf-carousel";
import "./PopularCate.css";

class PopularCate extends React.Component {
  render() {
    return (
      <div className="onedayCarouselThumb CarouselThumb margin-con">
        <div className="flex flex-space-between">
          <h2 className="md-title ">Popular Professional Services</h2>
          {/* <div className="OnedayCarouselThumb-seemore-title">
            <Link to="/explore" className="dash seemore-title">
              Show all >
            </Link>
          </div> */}
        </div>
        <InfiniteCarousel
          breakpoints={[
            {
              breakpoint: 500,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 3,
                slidesToScroll: 3
              }
            }
          ]}
          dots={true}
          showSides={true}
          sidesOpacity={0.5}
          sideSize={0.1}
          slidesToScroll={4}
          slidesToShow={4}
          scrollOnDevice={true}
        >
          <a href="/explore?category=programming">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-p.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">Build your own App</p>
              <p className="subtitle">Programming</p>
            </div>
          </a>
          <a href="/explore?category=design">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-design.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">Build your brand</p>
              <p className="subtitle">Design</p>
            </div>
          </a>
          <a href="/explore?category=yoga">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-yoga2.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">Build Strength</p>
              <p className="subtitle">Yoga</p>
            </div>
          </a>
          <a href="/explore?category=cooking">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-baking.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">Find Flavortow</p>
              <p className="subtitle">Cooking</p>
            </div>
          </a>
          <a href="/explore?category=marketing">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-marketing.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">Growth online</p>
              <p className="subtitle">SEO: Marketing</p>
            </div>
          </a>
          <a href="/explore?category=invest">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-invest.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">Your Financial Future Today</p>
              <p className="subtitle">Investment</p>
            </div>
          </a>
          <a href="/explore?category=language">
            <div
              className="mainPage-pps-thumbList"
              style={{
                background:
                  "url(" + require("../../images/dn-main-l.jpg") + ")",
                backgroundSize: "cover",
                borderRadius: "5px"
              }}
            >
              <p className="title">From Foreign to Fluent</p>
              <p className="subtitle">Language</p>
            </div>
          </a>
        </InfiniteCarousel>
      </div>
    );
  }
}
export default PopularCate;
