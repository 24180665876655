import React from "react";
import Checkbox from "antd/lib/checkbox";
import Slider from "antd/lib/slider";
import InputNumber from "antd/lib/input-number";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Calendar from "antd/lib/calendar";

import PropTypes from "prop-types";
import moment from "moment";

import CategorySelector from "../../components/OpenCourseCom/CategorySelector";
import { DataServiceStatic } from "../../services/DataService";
import { PRICE_RANGES } from "../../constants";

export class CategoryFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cats: [], selected: "" };
  }
  componentDidMount() {
    const cats = DataServiceStatic.getCategories();
    if (this.props.filter.criteria) {
      this.setState({ selected: this.props.filter.criteria[0] });
    }
    this.setState({ cats });
  }
  onSelect(data) {
    const categorySlug = data.full_slug;
    this.props.onSelect([categorySlug, data.is_parent ? true : false]);
    this.setState({ selected: categorySlug });
  }
  render() {
    return (
      <div className="CategoryFilter">
        <CategorySelector
          onSelect={e => this.onSelect(e)}
          // categoryId={this.state.selected}
          categorySlug={this.state.selected}
          categories={this.state.cats}
        />
      </div>
    );
  }
}
CategoryFilter.defaultProps = {
  filter: []
};

CategoryFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

export class LanguageFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { langs: [], selected: {} };
  }
  componentDidMount() {
    const langs = DataServiceStatic.getLanguages();
    let selected = {};
    if (this.props.filter.criteria) {
      this.props.filter.criteria.forEach(fl => {
        selected[fl] = true;
      });
    }

    this.setState({ langs, selected });
  }
  onChange(e, code) {
    const selected = { ...this.state.selected };

    selected[code] = e.target.checked;
    this.setState({ selected }, () => {
      const retVal = [];
      for (let s in selected) {
        if (selected[s]) retVal.push(s);
      }
      console.log("[Filters.jsx] selected retVal", retVal);
      this.props.onSelect(retVal);
    });
  }
  render() {
    return (
      <div className="LanguageFilter">
        <ul>
          {this.state.langs.map(lang => {
            return (
              <li key={lang.code}>
                <Checkbox
                  checked={this.state.selected[lang.code]}
                  onChange={e => this.onChange(e, lang.code)}
                >
                  {lang.name}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

LanguageFilter.defaultProps = {
  filter: []
};

LanguageFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

export class ClassTypeFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      types: [
        { type: "ONE_TIME", name: "One Day Classes" },
        { type: "MORE_THEN_ONE_TIME", name: "Long Term Classes" },
        { type: "TUTORING", name: "Tutors for Students" },
        { type: "MENTORING", name: "Mentors for Career or Life Coaching" }
      ],
      selected: {}
    };
  }
  componentDidMount() {
    let selected = {};
    if (this.props.filter.criteria) {
      this.props.filter.criteria.forEach(fl => {
        selected[fl] = true;
      });
    }

    this.setState({ selected });
  }
  onChange(e, type) {
    const selected = { ...this.state.selected };

    selected[type] = e.target.checked;
    this.setState({ selected }, () => {
      const retVal = [];
      for (let s in selected) {
        if (selected[s]) retVal.push(s);
      }
      console.log("[Filters.jsx] selected retVal", retVal);
      this.props.onSelect(retVal);
    });
  }
  render() {
    return (
      <div className="ClassTypeFilter">
        <ul>
          {this.state.types.map(type => {
            return (
              <li key={type.type}>
                <Checkbox
                  checked={this.state.selected[type.type]}
                  onChange={e => this.onChange(e, type.type)}
                >
                  {type.name}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

ClassTypeFilter.defaultProps = {
  filter: []
};

ClassTypeFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

export class PriceFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: [10, 30]
    };
    this.min = 10;
    this.max = 1000;
  }
  componentDidMount() {
    if (this.props.filter.criteria && this.props.filter.criteria.length === 2) {
      this.setState({ inputValue: this.props.filter.criteria });
    }
  }

  onChangeSlider = inputValue => {
    this.setState({ inputValue }, () => {
      this.props.onSelect(inputValue);
    });
  };
  onChange = (value0, value1) => {
    const inputValue = [...this.state.inputValue];
    if (value0) {
      inputValue[0] = value0;
      this.setState({
        inputValue
      });
    }
    if (value1) {
      inputValue[1] = value1;
      this.setState({
        inputValue
      });
    }
    this.props.onSelect(inputValue);
  };

  render() {
    const { inputValue } = this.state;
    return (
      <div className="PriceFilter">
        <Row>
          <Col span={24}>
            <Slider
              range
              min={this.min}
              max={this.max}
              onChange={this.onChangeSlider}
              value={inputValue}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <InputNumber
              min={this.min}
              max={this.max}
              value={inputValue[0]}
              onChange={e => this.onChange(e, null)}
            />
          </Col>
          <Col span={6} />
          <Col span={6}>
            <InputNumber
              min={this.min}
              max={this.max}
              value={inputValue[1]}
              onChange={e => this.onChange(null, e)}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

PriceFilter.defaultProps = {
  filter: []
};

PriceFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

export class PriceFilterButtons extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: ["c"]
    };
    this.min = 10;
    this.max = 1000;
    this.ranges = PRICE_RANGES;
    // this.ranges = {
    //   a: "Free",
    //   b: "1 - 25",
    //   c: "26 - 50",
    //   d: "51 - 100",
    //   e: "101 - 200",
    //   f: "201 - 500",
    //   g: "501 - more",
    // };
  }
  componentDidMount() {
    if (this.props.filter.criteria) {
      this.setState({ inputValue: this.props.filter.criteria });
    }
  }

  onChange = key => {
    const inputValue = this.state.inputValue;
    const idx = inputValue.findIndex(p => p === key);
    if (idx > -1) {
      inputValue.splice(idx, 1);
    } else {
      inputValue.push(key);
    }
    this.setState({ inputValue });
    this.props.onSelect(inputValue);
  };

  render() {
    const { inputValue } = this.state;
    return (
      <div className="PriceFilterButtons">
        <Row>
          {/* <h3>Price:</h3> */}
          <Col span={24}>
            {Object.keys(this.ranges).map(key => {
              return (
                <Button
                  className="filter-price-btn"
                  style={{ margin: "5px" }}
                  type={inputValue.includes(key) ? "primary" : ""}
                  key={key}
                  onClick={() => this.onChange(key)}
                >
                  {this.ranges[key].label}
                </Button>
              );
            })}
          </Col>
        </Row>
      </div>
    );
  }
}

PriceFilter.defaultProps = {
  filter: []
};

PriceFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

export class DateFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: moment()
    };
    this.defaultDate = this.state.value;
  }
  componentDidMount() {
    if (this.props.filter.criteria && this.props.filter.criteria.length > 0) {
      this.setState({ value: this.props.filter.criteria[0] });
      // used for single component
      // } else {
      // this.props.onSelect([this.defaultDate]);
    }
  }

  onChange = date => {
    console.log("[Filters.js] data, mode", date);
    this.setState({ value: date }, () => {
      this.props.onSelect([date]);
    });
  };

  render() {
    return (
      <div className="DateFilter">
        <h5 style={{ color: "#848484" }}>Filter by start date</h5>
        <Calendar
          fullscreen={false}
          // defaultValue={this.state.value}
          value={this.state.value}
          onChange={this.onChange}
        />
      </div>
    );
  }
}

DateFilter.defaultProps = {
  filter: []
};

DateFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};

export class LocationFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = { locations: [], selected: {} };
  }
  componentDidMount() {
    const locations = DataServiceStatic.getLocation();
    // const locGroups = {
    //   province: [],
    // }
    // locations.forEach(l => {
    //   if(pro[l.osm_address_state]) {
    //     pro[l.osm_address_state].push(l);
    //   } else {

    //   }
    // })
    let selected = {};
    if (this.props.filter.criteria) {
      this.props.filter.criteria.forEach(fl => {
        selected[fl] = true;
      });
    }

    this.setState({ locations, selected });
  }
  onChange(e, code) {
    const selected = { ...this.state.selected };

    selected[code] = e.target.checked;
    this.setState({ selected }, () => {
      const retVal = [];
      for (let s in selected) {
        if (selected[s]) retVal.push(parseInt(s, 10));
      }
      console.log("[Filters.jsx] selected retVal", retVal);
      this.props.onSelect(retVal);
    });
  }
  render() {
    return (
      <div className="LocationFilter">
        <ul>
          {this.state.locations.map(loc => {
            return (
              <li key={loc.osm_place_id}>
                <Checkbox
                  checked={this.state.selected[loc.osm_place_id]}
                  onChange={e => this.onChange(e, loc.osm_place_id)}
                >
                  {loc.osm_address_town}
                  {/* {loc.osm_address_state} */}
                  {/* {loc.osm_address_country} */}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

LocationFilter.defaultProps = {
  filter: []
};

LocationFilter.propTypes = {
  filter: PropTypes.any,
  onSelect: PropTypes.func.isRequired
};
