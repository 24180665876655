import moment from "moment";
import { DataServiceStatic } from "./services/DataService";
import Fuse from "fuse.js";
import { PRICE_RANGES } from "./constants";
window.moment = moment;

export const login = {
  state: {
    user: {},
    settings: []
  },
  reducers: {
    setUser(state, payload) {
      const clone = { ...state };
      clone.user = payload;
      return clone;
    },
    updateSettings(state, payload) {
      const clone = { ...state };

      const sett = clone.settings.map(s => {
        if (s.id === payload.id) {
          return payload;
        }
        return s;
      });

      clone.settings = sett;
      return clone;
    },
    setSettings(state, payload) {
      const clone = { ...state };
      clone.settings = payload;
      return clone;
    }
  },
  effects: dispatch => ({
    async setUserAsync(payload, rootState) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      dispatch.login.setUser(payload);
    }
  })
};

export const ui = {
  state: {
    popupLoginVisible: false,
    popupSignupVisible: false
  }
};

export const data = {
  state: {
    languages: [],
    catagories: [],
    courses: [],
    filters: [], // {type: 'lang', filter: any}
    filteredCourses: [],
    wishlist: [], // [course_id]
    reviewQueue: [], // only ids,
    messages: {
      thread: [],
      mostRecent: [],
      unreadCount: 0
    },
    messageThreads: {
      threads: [],
      unreadCount: 0,
      archived: [],
      stared: [],
      unread: [],
      nonArchived: []
    }
  },
  effects: {},
  selectors: {},
  reducers: {
    setLanguages(state, payload) {
      const clone = { ...state };
      clone.languages = payload;
      return clone;
    },
    setCatagories(state, payload) {
      const clone = { ...state };
      clone.catagories = payload;
      return clone;
    },
    setCourses(state, payload) {
      const clone = { ...state };
      clone.courses = payload;
      const filtered = filterCourses(clone.courses, clone.filters);
      const sortByLast = filtered.sort((a, b) => {
        if (a.latest_date !== null && b.latest_date !== null) {
          return new moment(b.latest_date.date_start).diff(
            a.latest_date.date_start
          );
        } else {
          return false;
        }
      });
      const addedBit = sortByLast.map(c => {
        // let _tooLate = true;
        // if (c.latest_date) {
        //   _tooLate = moment().isAfter(c.latest_date.date_start);
        // }
        return {
          ...c,
          // _tooLate,
          _isNew: moment().diff(c.modified_on, "days") <= 7
        };
      });
      clone.filteredCourses = addedBit;
      return clone;
    },
    addToFilterMulti(state, payload) {
      console.log("payload multi", payload);
      const clone = { ...state };
      if (payload) {
        Object.keys(payload.filters).forEach(filterKey => {
          const filterType = filterKey;
          const payloadExtract = payload.filters[filterKey];
          const existingFilter = clone.filters.find(
            fl => fl.type === filterType
          );
          if (existingFilter) {
            const without = clone.filters.filter(fl => fl.type !== filterType);
            clone.filters = without;
            clone.filters.push(payloadExtract);
          } else {
            clone.filters.push(payloadExtract);
          }
        });

        const filtered = filterCourses(clone.courses, clone.filters);
        const sortByLast = filtered.sort((a, b) => {
          if (a.latest_date !== null && b.latest_date !== null) {
            return new moment(b.latest_date.date_start).diff(
              a.latest_date.date_start
            );
          } else {
            return false;
          }
        });

        const addedBit = sortByLast.map(c => {
          return {
            ...c,
            _isNew: moment().diff(c.modified_on, "days") <= 7
          };
        });
        clone.filteredCourses = addedBit;
      } else {
        clone.filteredCourses = clone.courses;
        clone.filters = [];
      }
      return clone;
    },
    addToFilter(state, payload) {
      const clone = { ...state };
      if (payload) {
        const filterType = payload.type;
        const existingFilter = clone.filters.find(fl => fl.type === filterType);
        if (existingFilter) {
          const without = clone.filters.filter(fl => fl.type !== filterType);
          clone.filters = without;
          clone.filters.push(payload);
        } else {
          clone.filters.push(payload);
        }
        const filtered = filterCourses(clone.courses, clone.filters);
        const sortByLast = filtered.sort((a, b) => {
          if (a.latest_date !== null && b.latest_date !== null) {
            return new moment(b.latest_date.date_start).diff(
              a.latest_date.date_start
            );
          } else {
            return false;
          }
        });
        const addedBit = sortByLast.map(c => {
          // let _tooLate = true;
          // if (c.latest_date) {
          //   _tooLate = moment().isAfter(c.latest_date.date_start);
          // }
          return {
            ...c,
            // _tooLate,
            _isNew: moment().diff(c.modified_on, "days") <= 7
          };
        });
        clone.filteredCourses = addedBit;
        // clone.filteredCourses = filtered;
      } else {
        clone.filteredCourses = clone.courses;
        clone.filters = [];
      }
      return clone;
    },
    clearFilter(state) {
      const clone = { ...state };
      clone.filters = [];
      clone.filteredCourses = clone.courses;
      return clone;
    },
    setFilteredCourses(state, payload) {
      const clone = { ...state };
      clone.filteredCourses = payload;
      return clone;
    },
    setWishlist(state, payload) {
      const clone = { ...state };
      clone.wishlist = payload;
      return clone;
    },
    addWishlist(state, payload) {
      const clone = { ...state };
      clone.wishlist.push(payload);
      return clone;
    },
    setReviewQueue(state, payload) {
      const clone = { ...state };
      const tmp = payload.map(p => p.course);
      clone.reviewQueue = [...tmp];
      return clone;
    },
    setMessageThreads(state, payload) {
      const clone = { ...state };
      const unread = getUnreadCountThreads(payload);
      const archived = filterMessageThreads("archived", payload);
      const stared = filterMessageThreads("stared", payload);
      const nonArchived = filterMessageThreads("archived", payload, false);
      clone.messageThreads = {
        threads: payload,
        unreadCount: unread.length,
        archived,
        stared,
        unread: unread,
        nonArchived
      };
      return clone;
    },
    addMessageThreads(state, payload) {
      const clone = { ...state };
      // const unreadCount = getUnreadCountThreads(payload);
      const mergedBody = mergeMessageThreadsBody(
        payload,
        clone.messageThreads.threads
      );
      clone.messageThreads = {
        ...clone.messageThreads,
        threads: mergedBody,
        unreadCount: state.messageThreads.unreadCount
      };
      console.log(
        "[models.js] clone.messageThreads.threads",
        clone.messageThreads.threads
      );
      return clone;
    },
    updateMessageThreadRead(state, payload) {
      const clone = { ...state };
      const threads = state.messageThreads.threads.map(th => {
        if (th.id !== payload.threadId) return th;
        return {
          ...th,
          seen_receiver: true,
          seen_sender: true
        };
      });

      const unread = getUnreadCountThreads(threads);
      const archived = filterMessageThreads("archived", threads);
      const stared = filterMessageThreads("stared", threads);
      const nonArchived = filterMessageThreads("archived", threads, false);
      clone.messageThreads = {
        threads,
        unreadCount: unread.length,
        archived,
        stared,
        unread: unread,
        nonArchived
      };
      return clone;
    },
    updateMessageThreadAction(state, payload) {
      const clone = { ...state };
      let key1 = "archived_receiver";
      let key2 = "archived_sender";
      if (payload.action === "star") {
        key1 = "stared_receiver";
        key2 = "stared_sender";
      }
      const value = payload.value;
      const threads = state.messageThreads.threads.map(th => {
        if (th.id !== payload.threadId) return th;
        return {
          ...th,
          [key1]: value,
          [key2]: value
        };
      });
      const unread = getUnreadCountThreads(threads);
      const archived = filterMessageThreads("archived", threads);
      const stared = filterMessageThreads("stared", threads);
      const nonArchived = filterMessageThreads("archived", threads, false);
      clone.messageThreads = {
        threads,
        unreadCount: unread.length,
        archived,
        stared,
        unread: unread,
        nonArchived
      };
      return clone;
    },
    setMessages(state, payload) {
      const clone = { ...state };
      const { thread, mostRecent } = aggregateMessages(payload);
      const unreadCount = getUnreadCount(mostRecent);
      clone.messages = {
        thread,
        mostRecent,
        unreadCount
      };
      console.log("[models.js] msgs set", mostRecent);
      return clone;
    },
    addMessage(state, payload) {
      const clone = { ...state };
      // const { thread, mostRecent } = aggregateMessages(payload);
      // const unreadCount = state.messages.unreadCount;
      const mostRecent = [...state.messages.mostRecent];
      const thread = { ...state.messages.thread };
      let parentId = payload.parent;
      if (parentId) {
        thread[parentId].children.push(payload);
        const needleIndx = state.messages.mostRecent.findIndex(
          m => m.parent === parentId
        );
        mostRecent[needleIndx] = payload;
      } else {
        parentId = payload.id;
        thread[parentId] = payload;
        mostRecent.push(payload);
      }

      const unreadCount = state.messages.unreadCount;

      clone.messages = {
        thread,
        mostRecent,
        unreadCount
      };
      console.log("[models.js] msgs add", mostRecent);
      return clone;
    },
    updateMessageRead(state, payload) {
      const clone = { ...state };
      const mostRecent = [...state.messages.mostRecent];
      const thread = { ...state.messages.thread };
      const needle = thread[payload.parent].children.findIndex(
        c => c.id === payload.id
      );
      const needleEl = thread[payload.parent].children[needle];
      needleEl.is_read = true;
      thread[payload.parent].children[needle] = needleEl;

      const mrIdx = mostRecent.findIndex(m => m.id === payload.id);
      if (mrIdx > -1) {
        const mrEl = mostRecent[mrIdx];
        mrEl.is_read = true;
        mostRecent[mrIdx] = mrEl;
      }

      const unreadCount = getUnreadCount(mostRecent);

      clone.messages = {
        thread,
        mostRecent,
        unreadCount
      };
      console.log("[models.js] msgs add", mostRecent);
      return clone;
    }
  }
};

var fuseOptions = {
  shouldSort: true,
  threshold: 0.6,
  location: 0,
  distance: 100,
  maxPatternLength: 32,
  minMatchCharLength: 1,
  keys: ["title", "owner.first_name", "owner.last_name", "category"]
};

//TODO add more sophisticated filter, now it's only by one
const filterCourses = (courses, filter) => {
  let retVal = [...courses];
  if (filter !== null) {
    filter.forEach(fil => {
      const criteria = fil.criteria;
      let found = [...retVal];
      switch (fil.type) {
        case "text":
          if (criteria[0].trim().length > 0) {
            const fuse = new Fuse(found, fuseOptions);
            const result = fuse.search(criteria[0].trim());
            found = [...result];
          }
          break;
        // case "category":
        //   if (criteria.length > 0) {
        //     found = retVal.filter(
        //       c => c.category_id === parseInt(criteria[0], 10)
        //     );
        //   }
        //   break;
        case "category":
          if (criteria.length > 0) {
            found = retVal.filter(c => {
              // if second arg is true, then this is parent category, we shall get all related classes.
              // slug is parent-category_child_category, so splitting by _.
              if (criteria[1]) {
                return (
                  c.category_full_slug.split("_")[0].toLowerCase() ===
                  criteria[0].toLowerCase()
                );
              }
              return (
                c.category_full_slug.toLowerCase() === criteria[0].toLowerCase()
              );
            });
          }
          break;
        case "language":
          if (criteria.length > 0) {
            found = retVal.filter(c => criteria.includes(c.language.code));
          }
          break;
        case "classtype":
          if (criteria.length > 0) {
            found = retVal.filter(c => criteria.includes(c.type));
          }
          break;
        case "price":
          if (criteria.length > 0) {
            found = retVal.filter(
              c => criteria[0] <= c.price && c.price <= criteria[1]
            );
          }
          break;
        case "pricebuttons":
          let foundTmp = [];
          criteria.forEach(criteriaKey => {
            const cr = PRICE_RANGES[criteriaKey].criteria;
            const foundCycle = retVal.filter(c => {
              return cr[0] <= c.price && c.price <= cr[1];
            });
            foundCycle.forEach(single => {
              foundTmp.push(single);
            });
          });
          found = [...foundTmp];
          break;
        case "date":
          if (criteria.length > 0) {
            found = retVal.filter(c => {
              const firstDate = c.date[0] || false;
              if (!firstDate) return false;
              return moment(firstDate.date_start).isSameOrAfter(criteria[0]);
            });
          }
          break;
        case "location":
          if (criteria.length > 0) {
            found = retVal.filter(c =>
              criteria.includes(c.address.osm_place_id)
            );
          }
          break;
        case "verified":
          if (criteria.length > 0) {
            found = retVal.filter(c => {
              if (criteria[0] === false) {
                // if show all, then criteria is false
                return true;
              }
              return c.owner.verified === criteria[0];
            });
          }
          break;

        case "online_only":
          if (criteria.length > 0) {
            found = retVal.filter(c => {
              if (criteria[0] === false) {
                // if show all, then criteria is false
                return true;
              }
              return c.address.is_online === criteria[0];
            });
          }
          break;
        default:
          break;
      }
      retVal = [...found];
    });
  }
  // console.log("[models.js] courses", retVal);
  return retVal;
};

// const filterMethods = {
//   category: (criteria) => {}
// }

/**
 * @returns {thread, mostRecent} - thread is top level parent is null, children all links to same parent, not each other.
 * Most recent is children latests
 * @param {array} data
 */
const aggregateMessages = data => {
  const filteredParents = data.filter(d => d.parent === null);

  const thread = {};
  const mostRecent = [];

  filteredParents.forEach(parent => {
    const children = data.filter(p => p.parent === parent.id);
    const childrenSorted = children.sort((a, b) => a.id - b.id);
    const pp = parent;
    pp.children = childrenSorted;
    thread[parent.id] = pp;
    let last = childrenSorted[childrenSorted.length - 1];
    if (!last) {
      last = pp;
    }
    mostRecent.push(last);
  });

  return { thread, mostRecent };
};

const getUnreadCount = mostRecent => {
  const user = DataServiceStatic.userGetOnly();
  const f = mostRecent.filter(
    m => m.is_read === false && m.to_user === user.id
  );
  return f.length;
};

const getUnreadCountThreads = threads => {
  const user = DataServiceStatic.userGetOnly();
  const f = threads.filter(th => {
    if (th.receiver.id === user.id) {
      return th.seen_receiver === false;
    } else {
      return th.seen_sender === false;
    }
  });
  return f;
};

const mergeMessageThreadsBody = (msg, threads) => {
  const threadsRet = threads.map(th => {
    if (Number(th.id) !== Number(msg.thread_id)) return th;
    const messages = [...th.messages, msg];
    th.messages = messages;
    console.log("[models.js] messages", messages);
    return th;
  });
  return threadsRet;
};

const filterMessageThreads = (criteria, threads, comp = true) => {
  const user = DataServiceStatic.userGetOnly();
  const f = threads.filter(th => {
    if (th.receiver.id === user.id) {
      return th[criteria + "_receiver"] === comp;
    } else {
      return th[criteria + "_sender"] === comp;
    }
  });
  return f;
};
