import React from "react";
import Tabs from "antd/lib/tabs";

import HelpStudent from "./HelpStudent";
import HelpTeacher from "./HelpTeacher";
import ContactUsTag from "./ContactUsTag";
// import ListBar from "../indexPage/ListBar";
import "./help.css";

const TabPane = Tabs.TabPane;

function callback(key) {
  console.log(key);
}
class Help extends React.Component {
  render() {
    return (
      <div className="helpPage-con">
        <div className="faqTitle">
          <h3 className="lg-title container">How Can We Help?</h3>
        </div>
        <div className="container">
          <div className="help-con">
            <Tabs defaultActiveKey="1" onChange={callback} className="">
              <TabPane tab="I'm a student" key="1">
                <HelpStudent />
              </TabPane>
              <TabPane tab="I'm a tutor" key="2">
                <HelpTeacher />
              </TabPane>
            </Tabs>
          </div>
          <ContactUsTag />
        </div>
      </div>
    );
  }
}
export default Help;
