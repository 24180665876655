/* eslint-disable */
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Dropdown from "antd/lib/dropdown";
import Menu from "antd/lib/menu";
import message from "antd/lib/message";
import Modal from "antd/lib/modal";

import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { DataServiceStatic, getUserImage } from "../../services/DataService";
import { LoginInputsAll } from "./LoginComp";
import { dispatch } from "../../services/EventBus";

import Media from "react-media";
import "./Login.css";

const EVENT_ROOT = document.getElementById("root");

const CollectionCreateForm = Form.create({ name: "form_in_modal" })(
  class extends React.Component {
    state = {
      loading: false
    };
    handleSubmit = e => {
      e.preventDefault();
      this.setState({ loading: true });
      this.props.form.validateFields((err, values) => {
        if (!err) {
          DataServiceStatic.userLogin(
            values.username,
            values.password,
            (err, ok) => {
              if (err) {
                message.error(
                  "Cannot login, please check your credentials and try again."
                );
                this.setState({ loading: false });
              } else {
                this.props.form.resetFields();
                DataServiceStatic.userGetMySelf((err, data) => {
                  this.setState({ loading: false });
                  this.props.onCancel(data);
                });
              }
            }
          );
        }
      });
    };
    registerLink = () => {
      this.props.onCancel();
      const hideEvent = new CustomEvent("RegisterModal::show");
      EVENT_ROOT.dispatchEvent(hideEvent);
    };
    forgot() {}
    render() {
      const { visible, onCancel, onCreate, form, onGoogle } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          className="loginCon"
          visible={visible}
          title=""
          okText="Create"
          onCancel={onCancel}
          onOk={onCreate}
          footer={null}
          zIndex={2000}
        >
          <LoginInputsAll
            getFieldDecorator={getFieldDecorator}
            handleSubmit={this.handleSubmit}
            loading={this.state.loading}
            registerLink={this.registerLink}
            onCancel={onCancel}
            onGoogle={onGoogle}
          />
        </Modal>
      );
    }
  }
);

const logOut = () => {
  DataServiceStatic.userLogOut();
  window.location.href = "/";
};
const menu = (
  <Menu>
    <Menu.Item>
      <Link to="/UserProfile/" type="primary">
        Profile
      </Link>
    </Menu.Item>
    <Menu.Item>
      <a onClick={logOut}>Log Out</a>
    </Menu.Item>
  </Menu>
);

class LoginLink extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false,
      uid: false
    };
    window.LoginModal = {
      show: this.showModal,
      hide: this.handleCancel
    };
  }
  componentDidMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    this.handleCancel(user);

    EVENT_ROOT.addEventListener(
      "LoginModal::show",
      function() {
        console.log("LoginModal::show", 1);
        window.LoginModal.show();
      },
      false
    );
    EVENT_ROOT.addEventListener(
      "LoginModal::hide",
      function() {
        console.log("LoginModal::hide", 1);

        window.LoginModal.hide();
      },
      false
    );
  }
  showModal = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    this.setState({ visible: true });
    dispatch({ type: "ui/popupLoginVisible", payload: true });
  };
  handleGoogle = userData => {
    /// need to closes
    console.log("pre");
    DataServiceStatic.userGetMySelf((err, data) => {
      console.log("[Login.jsx] userrData", data);
      this.handleCancel(data);
      console.log("post");
    });
  };
  handleCancel = userData => {
    if (userData) {
      // let profile_image = "";
      // if (userData && userData.user_image && userData.user_image.length > 0) {
      //   let image = userData.user_image.pop();
      //   profile_image = image.profile_image;
      // } else {
      //   profile_image = ProfileImage;
      // }
      const profile_image = getUserImage(userData);
      this.setState({
        visible: false,
        user: userData,
        profile_image,
        uid: userData.id
      });
    } else {
      this.setState({
        visible: false
      });
    }
  };

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
    });
  };

  saveFormRef = formRef => {
    this.formRef = formRef;
  };

  render() {
    return (
      <>
        {!this.state.uid && (
          <a
            href="/#/"
            type="primary"
            onClick={this.showModal}
            style={{ paddingRight: "8px" }}
          >
            Log in
          </a>
        )}
        {this.state.uid && (
          <>
            <Media query="(max-width: 767.98px)">
              {matches =>
                matches ? (
                  <>
                    <span className="profile-img">
                      <Link className="ant-dropdown-link" to="/UserProfile">
                        <img
                          // style={{ width: "40px" }}
                          alt="profile"
                          src={this.state.profile_image}
                        />
                      </Link>
                    </span>
                  </>
                ) : (
                  <Dropdown overlay={menu} overlayStyle={{ zIndex: "1250" }}>
                    <Link
                      className="ant-dropdown-link profile-img"
                      to="/UserProfile"
                    >
                      <img
                        style={{ width: "20px" }}
                        alt="profile"
                        src={this.state.profile_image}
                      />
                      <Icon type="down" />
                    </Link>
                  </Dropdown>
                )
              }
            </Media>
          </>
        )}
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={this.state.visible}
          onCancel={userData => this.handleCancel(userData)}
          onGoogle={userData => this.handleGoogle(userData)}
        />
      </>
    );
  }
}

export default LoginLink;
