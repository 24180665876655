import React from "react";
import { TextFilter } from "./TextFilter";
import history from "../../services/history";
import { toFilter } from "./FilterUtils";
import { store } from "../../services/EventBus";

class TextFilterController extends React.Component {
  constructor() {
    super();
    this.state = { redir: false, text: "" };
    this.goDo = false;
  }
  componentDidMount() {
    this.historyListener = history.listen(() => {
      const filters = store.getState().data.filters;
      const needle = filters.find(f => f.type === "text");
      if (needle) {
        this.setState({ text: needle.criteria[0] });
      }
    });
  }
  componentWillUnmount() {
    this.historyListener();
  }
  onSelect(str) {
    toFilter("text", str);
    this.setState({ text: str });
    this.goDo = str.trim().length > 1;
  }
  onFocus() {
    const doNav = window.location.pathname.indexOf("/explore") !== 0;
    setTimeout(() => {
      if (doNav) {
        history.push("/explore?text=" + this.state.text);
      }
    }, 300);
  }
  onChange(text) {
    this.setState({ text });
  }
  render() {
    return (
      <>
        <TextFilter
          onSelect={s => this.onSelect(s)}
          onFocus={() => this.onFocus()}
          onChangeValue={value => this.onChange(value)}
          value={this.state.text}
        />
      </>
    );
  }
}

export default TextFilterController;
