import Button from "antd/lib/button";
import Checkbox from "antd/lib/checkbox";

import "antd/dist/antd.css";
import React from "react";
import { Link } from "react-router-dom";
import "./CourseType.css";

class CourseType extends React.Component {
  constructor() {
    super();
    this.state = {
      agreePolicy: false
    };
  }

  onChangePolicy = e => {
    this.setState({
      agreePolicy: e.target.checked
    });
  };
  render() {
    return (
      <div className="container courseTypeCon footer-bottom-margin">
        <div className="courseTypeCon-border">
          <h3 className="lg-title">
            Are you agree with a Term policy and Tutor Responsibility?
          </h3>
          <div className="tnc-before-class">
            <p>
              Please read the Terms of Service carefully before you start to use
              the Site. By using the Site, opening a class or by clicking to
              accept or agree to the Terms of Service when this option is made
              available to you, you accept and agree to be bound and abide by
              this Policy (Terms of Service) and our Privacy Policy, found here,
              incorporated herein by reference. If you do not want to agree to
              this Policy (Terms of Service) or the Privacy Policy, you must not
              access or use the Site. For more detailed policies surrounding the
              activity and usage on the Site, please access the designated
              articles herein.
            </p>
            <p>
              This Site is offered and available to users who are 13 years of
              age or older. If you are under 13 you may not use this Site or the
              Donanum services. If you are under 21 you may not create a class
              on Donanum site. By using this Site, you represent and warrant
              that you are of legal age to form a binding contract and meet all
              of the foregoing eligibility requirements. If you do not meet all
              of these requirements, you must not access or use the Site. These
              Terms and Conditions are effective from the moment you use the
              service, such as registering for the site and viewing content on
              the Donanum site. If you have any questions regarding the Site or
              Terms of Service, contact our Customer Support team can be
              performed by submitting a request here.{" "}
            </p>
          </div>
          <Checkbox onChange={e => this.onChangePolicy(e)} className="checkbox">
            Yes, I understand and I agree
          </Checkbox>

          <div>
            {/* <h3>What type of class do you want to open?</h3> */}
            <Button
              disabled={!this.state.agreePolicy}
              type="primary"
              htmlType="button"
            >
              <Link to="OpenCourse">Sumbit</Link>
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default CourseType;
