import { connect } from "react-redux";
import React from "react";
import { Link } from "react-router-dom";
import Badge from "antd/lib/badge";

import Media from "react-media";

const MessageNotificationIn = props => (
  <Media query="(max-width: 767.98px)">
    {matches =>
      matches ? (
        <>
          <Badge count={props.mostRecentCount} title={"Unread messages"}>
            <Link to="/MessageIndex">Messages</Link>
          </Badge>
        </>
      ) : (
        <Badge count={props.mostRecentCount} title={"Unread messages"}>
          <Link to="/MessageIndex">Messages</Link>
        </Badge>
      )
    }
  </Media>
);

const mapState = state => {
  return {
    mostRecentCount: state.data.messageThreads.unreadCount
  };
};

export const MessageNotification = connect(mapState)(MessageNotificationIn);
