import React from "react";
import Button from "antd/lib/button";
import { Link } from "react-router-dom";
import LevelIcon from "../images/approved-mark.png";
import "./getApproveMark.css";

class GetApproveMark extends React.Component {
  render() {
    return (
      <div className="container getApprovedCon">
        <div className="API-con">
          <div className="icon">
            <img src={LevelIcon} alt="icon" />
          </div>

          <h2 className="title">Do you want to get Verified Icon?</h2>
          <p className="md-p">
            The verified icon will always show on beside the title of the class
            page. This mark will help you get high trust from a student when
            they consider registering the class.
          </p>
          <div className=" ">
            <Button className="am-btn">
              <Link to="ApproveMark" className="">
                Get verified icon
              </Link>
            </Button>
            <br />
            <Link to="CourseType">No, Thank you</Link>
          </div>
        </div>
      </div>
    );
  }
}
export default GetApproveMark;
