import Modal from "antd/lib/modal";
import Input from "antd/lib/input";

import React from "react";

import { DataServiceStatic } from "../../services/DataService";

class ResetPassword extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    email: "",
    done: false
  };

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  handleOk = () => {
    this.setState({
      confirmLoading: true
    });
    DataServiceStatic.resetPwd(this.state.email, (err, ok) => {
      if (err) {
        this.setState({ done: true });
      } else {
        this.setState({
          visible: true,
          confirmLoading: false,
          email: "",
          done: true
        });
      }
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      email: "",
      confirmLoading: false
    });
  };

  onChange = e => {
    const val = e.target.value;
    this.setState({ email: val });
  };
  render() {
    const { visible, confirmLoading, email } = this.state;
    return (
      <div>
        <a onClick={this.showModal} className="login-form-forgot" href="/#/">
          Forgot password
        </a>
        <Modal
          title="Forgot password? Enter your email and will help you"
          visible={visible}
          onOk={this.handleOk}
          confirmLoading={confirmLoading}
          onCancel={this.handleCancel}
          okText={"Reset!"}
        >
          <div>
            {this.state.done && (
              <>Thank you, please check you email with further instructions.</>
            )}
            {!this.state.done && (
              <Input type="text" value={email} onChange={this.onChange} />
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default ResetPassword;
