import React from "react";
import { Row } from "../../../ui";

import { Link } from "react-router-dom";
import Empty from "antd/lib/empty";
import Table from "antd/lib/table";
import Button from "antd/lib/button";

import { getUserImage } from "../../../services/DataService";

const Tbl = ({ data }) => {
  const columns = [
    {
      key: "author.id",
      dataIndex: "author.id",
      title: "Teacher",
      width: "30%",
      render: (text, record) => {
        const img = getUserImage(record.author);
        const teacherName =
          record.author.first_name + " " + record.author.last_name;
        // const teacherName = "xx";
        return (
          <div>
            <img src={img} className="profile-teacherImg" alt="" />{" "}
            {teacherName}{" "}
          </div>
        );
      }
    },
    {
      key: "course",
      dataIndex: "course",
      title: "Title",
      render: (text, record) => (
        <span>
          <Link to={`/PostPage/${record.course_id}`}>{text}</Link>
        </span>
      )
    }
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey={"id"}
      className="d-block w-100"
    />
  );
};

const ProfileTabsWishlist = ({ wishlistArr }) => (
  <div className="profile-class-con">
    <div className="justifyRight">
      <Button variant="primary">
        <Link to="/explore">view more classes</Link>
      </Button>
    </div>
    <Row>
      {wishlistArr.length === 0 && (
        <div className="d-block w-100">
          <Empty
            description={
              <span>
                Hello, there! <Link to="/explore">view more classes</Link>
              </span>
            }
          />
        </div>
      )}
      {wishlistArr.length !== 0 && <Tbl data={wishlistArr} />}
      {/* {wishlistArr.map(wishlist => {
        return (
          <Col xs={12} sm={6} key={wishlist.id}>
            <div className="flex profile-class-con-list m-sm-bottom whishlist-thumb">
              <Link to={`/PostPage/${wishlist.course_id}`} className="flex">
                <div>
                  {wishlist.owner_image && (
                    <img
                      src={wishlist.owner_image.profile_image}
                      alt=""
                      className="profile-teacherImg"
                    />
                  )}
                  {!wishlist.owner_image && (
                    <img
                      src={ProfileImage}
                      alt=""
                      className="profile-teacherImg"
                    />
                  )}
                  <p>{wishlist.owner_name}</p>
                </div>
                <div>
                  <h4 className="md-title">{wishlist.course}</h4>
                </div>
              </Link>
            </div>
          </Col>
        );
      })} */}
    </Row>
  </div>
);

export default ProfileTabsWishlist;
