import React from "react";
// import 'antd/dist/antd.css';
import Icon from "antd/lib/icon";
import message from "antd/lib/message";
import Upload from "antd/lib/upload";

import { DataServiceStatic } from "../services/DataService";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {
  const allowedTypes = ["image/jpeg", "image/png"];
  let goodType = true;
  if (allowedTypes.indexOf(file.type) < 0) {
    goodType = false;
    message.error("You can only upload JPG or PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return goodType && isLt2M;
}

class UploadProfileImg extends React.Component {
  constructor() {
    super();
    let img = "";
    this.state = {
      loading: false,
      imageUrl: img
    };
  }

  handleChange = info => {
    if (info.file.status === "uploading") {
      this.setState({ loading: true });
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          loading: false
        })
      );
    }
  };

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? "loading" : "plus"} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );
    let imageUrl = "";
    if (this.state.imageUrl) {
      imageUrl = this.state.imageUrl;
    } else {
      if (this.props.user.user_image) {
        const last = this.props.user.user_image[
          this.props.user.user_image.length - 1
        ];

        if (last && last.profile_image) {
          imageUrl = last.profile_image;
        }
      }
    }

    return (
      <Upload
        name="avatar"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        onChange={this.handleChange}
        customRequest={crData => {
          DataServiceStatic.uploadProfileImage(crData.file, (err, data) => {
            if (err) {
              console.log(err);
            } else {
              this.setState({ imageUrl: data.profile_image });
            }
          });
        }}
      >
        {imageUrl ? (
          <img src={imageUrl} style={{ width: "150px" }} alt="avatar" />
        ) : (
          uploadButton
        )}
      </Upload>
    );
  }
}

export default UploadProfileImg;
