import React from "react";
import { WrappedNormalLoginForm } from "../../components/modals/LoginComp";
import "./LoginRequiredPage.css";

const LoginRequiredPage = () => (
  <div className="LoginRequiredPage container">
    <h3>Please login to create class</h3>
    <div className="login">
      <WrappedNormalLoginForm />
    </div>
  </div>
);

export default LoginRequiredPage;
