import React from "react";
import Layout from "antd/lib/layout";
import Icon from "antd/lib/icon";
import Menu from "antd/lib/menu";
import notification from "antd/lib/notification";

import history from "../../services/history";

import Curriculum from "./component/Curriculum";
import About from "./component/About";
import WhoCome from "./component/WhoCome";
import Verification from "./component/Verification";
import PhoneVerificationPage from "./component/PhoneVerificationPage";
import UploadImg from "../../components/UploadImg/UploadImg";
import OpenCourseCom from "../../components/OpenCourseCom/OpenCourseCom";
import Calendar from "./component/Calendar";
import Preview from "./component/Preview";
import ValidateAndPublish from "./component/ValidateAndPublish";
import {
  CourseValidatorSerializer,
  CourseValidatorRules,
  between,
  nameToNames
} from "./component/CourseValidator";

import { getWordCount } from "../../components/TextWidget";

import "./openCourse.css";

import { DataServiceStatic } from "../../services/DataService";

const { Content, Sider } = Layout;

class OpenCourse extends React.Component {
  constructor() {
    super();
    this.state = {
      isSaving: false,
      isLoaded: false,
      isError: false,
      collapsed: false,
      selectedSectionKey: "0",
      course: {
        address: {
          is_online: false,
          full_address: "",
          location: "",
          place: "",
          online_contact: ""
        },
        main_image: "na",
        size_max: 2,
        size_min: 0,
        duration: 0,
        type: "MORE_THEN_ONE_TIME",
        price: 0,
        currency: "CAD",
        is_free: false,
        compare_price: 0,
        price_mode: "TOTAL",
        is_discounted: true,
        extra_charge: 0,
        requirement: "",
        language_id: 1,
        language_other: "",
        category_id: 0,
        level: "INTERMEDIATE",
        audience: "",
        audience_html: "",
        about_teacher: "",
        about_teacher_html: "",
        brief: "",
        brief_html: "",
        curriculum: "",
        curriculum_html: "",
        status: "DRAFT"
      },
      languages: [],
      categories: []
    };
    this.courseId = null;
    this.courseValidatorRules = new CourseValidatorRules();
  }
  goToSection(key) {
    this.setState({ selectedSectionKey: key });
    history.push(window.location.pathname + "?s=" + key.toString());
  }
  handleMenuClick = e => {
    if (e.key === "9") {
      this.checkValidationForAll(this.state.course);
    }
    if (this.courseId) {
      console.log("xxxxxx", this.state.course);
      this.goToSection(e.key);
    } else {
      // If it's new course we cannot go to other tabs,
      // we need to get course id to add other sections.
      if (e.key === "1") {
        this.goToSection("1");
        return;
      }

      if (e.key === "2") {
        this.goToSection("2");
        return;
      }

      if (e.key === "0") {
        this.goToSection("0");
        return;
      }
    }
  };
  componentDidMount() {
    this.openCourse = window.location.pathname.indexOf("/OpenCourse") === 0;
    document.getElementById("footer").style.display = "none";
    const section = window.location.search.substr(3) || 1; // returns only section number
    const path = window.location.pathname.split("/");
    if (path.length === 4) {
      // the first one is null, but len is 4
      this.courseId = path[2];
      DataServiceStatic.loadAllForClassInit((err, data) => {
        if (err) return;
        this.setState({
          languages: data.languages,
          categories: data.categories
        });
        DataServiceStatic.loadCourse(this.courseId, (err, data) => {
          if (err) return;
          const validation_status_obj = new CourseValidatorSerializer(
            data.validation_status
          );

          data.validation_status_obj = validation_status_obj;

          console.log("[OpenCourseCom.js] data courses", data);
          this.setState({
            course: data,
            isLoaded: true
            // selectedSectionKey: section.toString()
          });
          if (data.owner.phone_status === "approved") {
            this.goToSection("2");
          } else {
            this.goToSection("1");
          }
        });
      });
    } else {
      DataServiceStatic.loadAllForClassInit((err, data) => {
        if (err) return;

        this.setState({
          languages: data.languages,
          categories: data.categories,
          isLoaded: true,
          selectedSectionKey: section.toString()
        });
      });
    }
  }
  componentWillUnmount() {
    document.getElementById("footer").style.display = "block";
  }
  onChange = data => {
    console.log("[OpenCourse.js] onChange", data, JSON.stringify(data));
    this.setState({ course: data });
  };
  onSaveSuccess = (next = false) => {
    notification.success({
      message: "Saved!",
      duration: 1,
      description: next ? "Loading next page..." : ""
    });
  };

  onSaveCB(err, data, next, nextKey) {
    console.log("[OpenCourse.js] err data", err, data);
    if (err) return; //TODO add  error
    this.onSaveSuccess(next);
    setTimeout(() => {
      this.setState({
        course: data,
        isSaving: false
      });
      if (next) {
        this.goToSection(nextKey);
      }
    }, 1000);
  }

  onSave = (place, dataObj, next = false) => {
    // if dataObj is empty there was no changes

    if (place === "openCourse") {
      this.setState({ isSaving: true });
      if (!dataObj) {
        return;
      }

      const up = { ...this.state.course, ...dataObj };
      up.address = { ...up.address, ...dataObj.address };

      if (up.validation_status_obj) {
        up.validation_status = JSON.stringify(up.validation_status_obj);
      } else {
        up.validation_status = JSON.stringify({});
      }

      if (this.courseId) {
        DataServiceStatic.updateCourse(up.id, up, (err, data) => {
          this.onSaveCB(err, data, next, "3");
        });
      } else {
        const up = { ...this.state.course, ...dataObj };

        const newValidator = new CourseValidatorSerializer(null, true);
        up.validation_status = JSON.stringify(newValidator);
        DataServiceStatic.createCourse(up, (err, data) => {
          console.log("[OpenCourseCom.js] ok", err, data);
          this.courseId = data.id;
          history.push(
            window.location.pathname + "/" + this.courseId + "/edit"
          );
          this.onSaveCB(err, data, next, "3");
        });
      }
    }
    if (place === "uploadImg") {
      this.setState({ isSaving: true });
      // we have to "save" so we can get added images to state.
      const up = { ...this.state.course };

      if (!up.validation_status_obj) {
        up.validation_status_obj = new CourseValidatorSerializer(null, true);
      }

      DataServiceStatic.updateCourse(up.id, up, (err, data) => {
        if (
          !between(up.images.length, this.courseValidatorRules.uploadImg.rules)
        ) {
          up.validation_status_obj.add("uploadImg");
        } else {
          up.validation_status_obj.del("uploadImg");
        }

        DataServiceStatic.updateCourse(up.id, up, (err, data) => {
          this.onSaveCB(err, data, next, "5");
        });
      });
    }
    if (place === "whoCome") {
      this.setState({ isSaving: true });
      if (!dataObj) {
        this.onSaveCB(null, { ...this.state.course }, next, "4");
        return;
      }

      const up = { ...this.state.course };

      if (!up.validation_status_obj) {
        up.validation_status_obj = new CourseValidatorSerializer(null, true);
      }

      if (
        !between(dataObj.wordCount, this.courseValidatorRules.whoCome.rules)
      ) {
        up.validation_status_obj.add("whoCome");
      } else {
        up.validation_status_obj.del("whoCome");
      }

      up.audience = dataObj.raw;
      up.audience_html = dataObj.html;
      console.log("[OpenCourse.js] up", up);
      DataServiceStatic.updateCourse(up.id, up, (err, data) => {
        this.onSaveCB(err, data, next, "4");
      });
    }
    if (place === "about") {
      if (!dataObj) {
        this.onSaveCB(null, { ...this.state.course }, next, "7");
        return;
      }

      this.setState({ isSaving: true });
      const up = { ...this.state.course };

      if (!up.validation_status_obj) {
        up.validation_status_obj = new CourseValidatorSerializer(null, true);
      }

      if (!between(dataObj.wordCount, this.courseValidatorRules.about.rules)) {
        up.validation_status_obj.add("about");
      } else {
        up.validation_status_obj.del("about");
      }

      up.about_teacher = dataObj.raw;
      up.about_teacher_html = dataObj.html;
      DataServiceStatic.updateCourse(up.id, up, (err, data) => {
        this.onSaveCB(err, data, next, "7");
      });
    }
    if (place === "curriculum") {
      this.setState({ isSaving: true });
      if (!dataObj) {
        this.onSaveCB(null, { ...this.state.course }, next, "9");
        return;
      }

      const up = { ...this.state.course };
      up.curriculum = dataObj.curriculum.raw;
      up.curriculum_html = dataObj.curriculum.html;
      up.brief = dataObj.brief.raw;
      up.brief_html = dataObj.brief.html;

      if (!up.validation_status_obj) {
        up.validation_status_obj = new CourseValidatorSerializer(null, true);
      }

      if (
        !between(
          dataObj.curriculum.wordCount,
          this.courseValidatorRules.curriculum.rules
        )
      ) {
        up.validation_status_obj.add("curriculum");
      } else {
        up.validation_status_obj.del("curriculum");
      }

      if (
        !between(dataObj.brief.wordCount, this.courseValidatorRules.brief.rules)
      ) {
        up.validation_status_obj.add("brief");
      } else {
        up.validation_status_obj.del("brief");
      }

      DataServiceStatic.updateCourse(up.id, up, (err, data) => {
        this.onSaveCB(err, data, next, "9");
      });
    }
    if (place === "calendar") {
      this.setState({ isSaving: true });
      if (!dataObj) {
        this.onSaveCB(null, { ...this.state.course }, next, "6");
        return;
      }

      const up = { ...this.state.course };
      up.date = dataObj;

      if (!up.validation_status_obj) {
        up.validation_status_obj = new CourseValidatorSerializer(null, true);
      }

      if (!between(up.date.length, this.courseValidatorRules.calendar.rules)) {
        up.validation_status_obj.add("calendar");
      } else {
        up.validation_status_obj.del("calendar");
      }

      DataServiceStatic.updateCourse(up.id, up, (err, data) => {
        this.onSaveCB(err, data, next, "6");
      });
    }
    if (place === "publish") {
      this.setState({ isSaving: true });

      const up = { ...this.state.course };
      if (up.status === "DRAFT") {
        up.status = "PUBLISHED";
      } else {
        up.status = "DRAFT";
      }
      DataServiceStatic.updateCourse(up.id, up, (err, data) => {
        if (err) return;
        this.onSaveSuccess();
        console.log("[OpenCourse.js] err data", err, data);
        this.setState({ course: data, isSaving: false });
      });
    }
    // if (place === "preview") {
    //   const up = { ...this.state.course };
    //   up.date = dataObj;
    //   DataServiceStatic.updateCourse(up.id, up, (err, data) => {
    //     if (err) return;
    //     this.onSaveSuccess();
    //     console.log("[OpenCourse.js] err data", err, data);
    //     this.setState({ course: data });
    //   });
    // }
  };

  checkValidationForAll(course) {
    if (!course.id) {
      // in case phone ver is done before class is created
      return;
    }
    const validation_status_obj = course.validation_status_obj;
    if (
      !between(
        getWordCount(course.audience),
        this.courseValidatorRules.whoCome.rules
      )
    ) {
      validation_status_obj.add("whoCome");
    } else {
      validation_status_obj.del("whoCome");
    }

    if (
      !between(
        getWordCount(course.about_teacher),
        this.courseValidatorRules.about.rules
      )
    ) {
      validation_status_obj.add("about");
    } else {
      validation_status_obj.del("about");
    }

    if (
      !between(
        getWordCount(course.curriculum),
        this.courseValidatorRules.curriculum.rules
      )
    ) {
      validation_status_obj.add("curriculum");
    } else {
      validation_status_obj.del("curriculum");
    }

    if (
      !between(
        getWordCount(course.brief),
        this.courseValidatorRules.brief.rules
      )
    ) {
      validation_status_obj.add("brief");
    } else {
      validation_status_obj.del("brief");
    }

    if (
      !between(course.date.length, this.courseValidatorRules.calendar.rules)
    ) {
      course.validation_status_obj.add("calendar");
    } else {
      course.validation_status_obj.del("calendar");
    }

    if (
      !between(course.images.length, this.courseValidatorRules.uploadImg.rules)
    ) {
      course.validation_status_obj.add("uploadImg");
    } else {
      course.validation_status_obj.del("uploadImg");
    }
    const user = DataServiceStatic.userGetMySelfCache();
    console.log("useruseruser", user);
    if (user.phone_status !== "approved") {
      course.validation_status_obj.add("phoneValidation");
    } else {
      course.validation_status_obj.del("phoneValidation");
    }

    DataServiceStatic.updateCourse(course.id, course, (err, data) => {
      this.onSaveCB(err, data);
    });
  }

  showErrorMenu(attr, attr2) {
    let retVal = false;
    let retVal2 = false;
    if (this.state.course.validation_status_obj) {
      if (attr === "any") {
        retVal = this.state.course.validation_status_obj.any();
      } else {
        retVal = this.state.course.validation_status_obj.has(attr);
        retVal2 = this.state.course.validation_status_obj.has(attr2);
      }
    }
    return retVal || retVal2;
  }
  showErrorIcon(attr, attr2 = "") {
    return (
      <>
        {this.showErrorMenu(attr, attr2) && (
          <span className="err-icon">
            <Icon type="exclamation-circle" style={{ color: "red" }} />
          </span>
        )}
      </>
    );
  }
  render() {
    console.log("[OpenCourse.js] out", this.state.course);
    return (
      <div className="openCourseCon container">
        <Layout>
          <Layout>
            <Sider
              breakpoint="md"
              collapsedWidth="0"
              // zeroWidthTriggerStyle={{ top: 0 }}
            >
              <p className="basic">Course Info</p>
              <Menu
                mode="inline"
                selectedKeys={[this.state.selectedSectionKey.toString()]}
                style={{ height: "", borderRight: 0 }}
              >
                <Menu.Item onClick={this.handleMenuClick} key="0">
                  <span>Tutor Info</span>
                </Menu.Item>
                <Menu.Item onClick={this.handleMenuClick} key="1">
                  <span>
                    {nameToNames["phoneValidation"]}
                    {this.showErrorIcon("phoneValidation")}
                  </span>
                </Menu.Item>
                <Menu.Item onClick={this.handleMenuClick} key="2">
                  <span>
                    {nameToNames["general"]}
                    {this.showErrorIcon("general")}
                  </span>
                </Menu.Item>
                <Menu.Item
                  onClick={this.handleMenuClick}
                  key="3"
                  disabled={!this.state.course.id}
                >
                  <span>
                    {nameToNames["whoCome"]}
                    {this.showErrorIcon("whoCome")}
                  </span>
                </Menu.Item>
                <Menu.Item
                  onClick={this.handleMenuClick}
                  key="4"
                  disabled={!this.state.course.id}
                >
                  <span>
                    {nameToNames["uploadImg"]}
                    {this.showErrorIcon("uploadImg")}
                  </span>
                </Menu.Item>
                <Menu.Item
                  onClick={this.handleMenuClick}
                  key="5"
                  disabled={!this.state.course.id}
                >
                  <span>
                    {nameToNames["calendar"]}
                    {this.showErrorIcon("calendar")}
                  </span>
                </Menu.Item>
                <Menu.Item
                  onClick={this.handleMenuClick}
                  key="6"
                  disabled={!this.state.course.id}
                >
                  <span>
                    {nameToNames["about"]}
                    {this.showErrorIcon("about")}
                  </span>
                </Menu.Item>
                <Menu.Item
                  onClick={this.handleMenuClick}
                  key="7"
                  disabled={!this.state.course.id}
                >
                  <span>
                    {nameToNames["curriculum"]}
                    {this.showErrorIcon("curriculum", "brief")}
                  </span>
                </Menu.Item>
                <Menu.Item
                  onClick={this.handleMenuClick}
                  key="9"
                  disabled={!this.state.course.id}
                >
                  <span>
                    {/* <Icon type="warning" style={{ color: "red" }} /> */}
                    Validate and Publish
                    {this.showErrorIcon("any")}
                  </span>
                </Menu.Item>
                {/* <Menu.Item
                  onClick={this.handleMenuClick}
                  key="8"
                  disabled={!this.state.course.id}
                >
                  <span>Preview</span>
                </Menu.Item> */}
              </Menu>
            </Sider>

            <Layout
              key="1"
              style={{ padding: "0px 0 25px 30px", background: "white" }}
            >
              {/* {this.state.isError && (<span>System Error, please try again later</span>)} */}
              {!this.state.isError && this.state.isLoaded && (
                <Content>
                  {this.state.selectedSectionKey === "0" && <Verification />}
                  {this.state.selectedSectionKey === "1" && (
                    <PhoneVerificationPage
                      onSuccessCallback={() =>
                        this.checkValidationForAll(this.state.course)
                      }
                    />
                  )}
                  {this.state.selectedSectionKey === "2" && (
                    <OpenCourseCom
                      languages={this.state.languages}
                      categories={this.state.categories}
                      course={this.state.course}
                      onSave={(data, next) =>
                        this.onSave("openCourse", data, next)
                      }
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "3" && (
                    <WhoCome
                      value={this.state.course.audience}
                      onSave={(data, next) =>
                        this.onSave("whoCome", data, next)
                      }
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "4" && (
                    <UploadImg
                      onSave={(data, next) =>
                        this.onSave("uploadImg", data, next)
                      }
                      files={this.state.course.images}
                      courseId={this.state.course.id}
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "5" && (
                    <Calendar
                      onSave={(data, next) =>
                        this.onSave("calendar", data, next)
                      }
                      values={this.state.course.date}
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "6" && (
                    <About
                      value={this.state.course.about_teacher}
                      onSave={(data, next) => this.onSave("about", data, next)}
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "7" && (
                    <Curriculum
                      valueBrief={this.state.course.brief}
                      valueCurriculum={this.state.course.curriculum}
                      onSave={(data, next) =>
                        this.onSave("curriculum", data, next)
                      }
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "8" && (
                    <Preview
                      course={this.state.course}
                      onSave={(data, next) =>
                        this.onSave("preview", data, next)
                      }
                      isSaving={this.state.isSaving}
                    />
                  )}
                  {this.state.selectedSectionKey === "9" && (
                    <ValidateAndPublish
                      course={this.state.course}
                      onSave={(data, next) =>
                        this.onSave("publish", data, next)
                      }
                      isSaving={this.state.isSaving}
                    />
                  )}
                </Content>
              )}
            </Layout>
          </Layout>
        </Layout>
      </div>
    );
  }
}
export default OpenCourse;
