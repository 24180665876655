import React from "react";

class FeeGuide extends React.Component {
  render() {
    return (
      <div>
        <p>
          * These fees effective only when a student registers the class. Free
          class is free
        </p>
        <div>
          <h3>Service Fees</h3>
          <table id="customers">
            <tr>
              <th>Type</th>
              <th>Service Fee</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>Payment provider - Stripe</td>
              <td>2.9% + 0.30</td>
              <td>ex) if tuition is $20, the fee will be $0.88(CAD) </td>
            </tr>
          </table>
        </div>
        {/* <div style={{ marginTop: "45px" }}>
          <h3>Online Class Service Fees</h3>
          <table id="customers">
            <tr>
              <th>Type</th>
              <th>Service Fee</th>
              <th>Description</th>
            </tr>
            <tr>
              <td>Create classes less than 17hours per month</td>
              <td>per minute and per person $0.004(USD)</td>
              <td>ex) 2 people 60 mins $0.48(USD) </td>
            </tr>
            <tr>
              <td>Create classes more than 17hours per month</td>
              <td>$7.8(CAD)</td>
              <td>
                Unlimited use with $7.8(CAD) and we provide these:
                <ul>
                  <li>Email with tutor@donanum.com</li>
                  <li>Google Drive</li>
                  <li>Calendar</li>
                </ul>
              </td>
            </tr>
          </table>
        </div> */}
      </div>
    );
  }
}
export default FeeGuide;
