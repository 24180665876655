import * as models from "../models";
import selectPlugin from "@rematch/select";

import { init } from "@rematch/core";

export const store = init({
  models,
  plugins: [
    {
      middleware: store => next => action => {
        // console.table(store.getState());
        // console.log("[App.js] next", action);
        return next(action);
      }
    },
    selectPlugin()
  ]
});

export const { dispatch } = store;

window.dispatch = dispatch;
window.store = store;
