export const LOCATIONS = [
  { key: "ANMORE", name: "Anmore" },
  { key: "BELCARRA", name: "Belcarra" },
  { key: "BOWEN_ISLAND", name: "Bowen Island" },
  { key: "BURNABY", name: "Burnaby" },
  { key: "COQUITLAM", name: "Coquitlam" },
  { key: "DELTA", name: "Delta" },
  { key: "ELECTORAL AREA A", name: "Electoral Area A" },
  { key: "LANGLEY", name: "Langley" },
  { key: "LIONS_BAY", name: "Lions Bay" },
  { key: "MAPLE_RIDGE", name: "Maple Ridge" },
  { key: "NEW_WESTMINSTER", name: "New Westminster" },
  { key: "NORTH_VANCOUVER", name: "North Vancouver" },
  { key: "PITT_MEADOWS", name: "Pitt Meadows" },
  { key: "PORT_COQUITLAM", name: "Port Coquitlam" },
  { key: "PORT_MOODY", name: "Port Moody" },
  { key: "RICHMOND", name: "Richmond" },
  { key: "SURREY", name: "Surrey" },
  { key: "TSAWWASSEN", name: "Tsawwassen" },
  { key: "VANCOUVER", name: "Vancouver" },
  { key: "WEST_VANCOUVER", name: "West Vancouver" },
  { key: "WHITE_ROCK", name: "White Rock" },
];

export const PRICE_RANGES = {
  a: { criteria: [0, 0], label: "Free" },
  b: { criteria: [1, 25], label: "1$ - 25$" },
  c: { criteria: [26, 50], label: "26$ - 50$" },
  d: { criteria: [51, 100], label: "51$ - 100$" },
  e: { criteria: [101, 200], label: "101$ - 200$" },
  f: { criteria: [201, 500], label: "201$ - 500$" },
  g: { criteria: [501, 1000000], label: "501$ - rest" },
};
