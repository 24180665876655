import React from "react";
import { connect } from "react-redux";
import { PostReviewWrite } from "../../../routes/postPage/postReviews/PostReviews";

const ReviewButtonIn = props => {
  if (!props.canReview) return null;
  return (
    <span>
      <PostReviewWrite
        courseId={props.courseId}
        courseTitle={props.courseTitle}
        visible={props.visible}
      />
    </span>
  );
};

const mapState = (state, ownProps) => {
  return {
    canReview: !!state.data.reviewQueue.find(id => id === ownProps.courseId),
    courseId: ownProps.courseId,
    courseTitle: ownProps.courseTitle,
    visible: ownProps.visible
  };
};

export const ReviewButton = connect(mapState)(ReviewButtonIn);
