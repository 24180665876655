/* eslint-disable no-unused-vars */
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Input from "antd/lib/input";

import React from "react";
import "./contactus.css";

const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class ContactFormForm extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
    });
  };

  render() {
    const {
      visible,
      onCancel,
      onCreate,
      form,
      getFieldsError,
      getFieldError,
      isFieldTouched,
      getFieldDecorator
    } = this.props.form;
    const userNameError =
      isFieldTouched("userName") && getFieldError("userName");
    return (
      <div className="container">
        <div className="contact-con footer-bottom-margin">
          <h2 className="xl-title">Message us</h2>
          {/* <h3 className="sm-title">Here’s what you’ve told us so far</h3>
          <p className="xs-title">
            Listing and hosting tools; Hosting resources; Hosting standards
          </p> */}
          <p className="sm-title contactus-margin">
            What can we help you with?
          </p>
          <Form layout="inline" onSubmit={this.handleSubmit}>
            <Form.Item
              validateStatus={userNameError ? "error" : ""}
              help={userNameError || ""}
            >
              {getFieldDecorator("message", {
                rules: [
                  { required: true, message: "Please input your username!" }
                ]
              })(<TextArea placeholder="" />)}
            </Form.Item>
            <Form.Item>
              <p className="xs-p contactus-bottom-p">
                Please don’t provide sensitive personal info (e.g. credit card
                numbers). Some requests are directed to third-party Community
                Experts to provide quicker responses.
              </p>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
              >
                Send message
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

const ContactUs = Form.create({ name: "horizontal_login" })(ContactFormForm);

export default ContactUs;
