import React from "react";
import ScrollMenu from "react-horizontal-scrolling-menu";
import { Redirect } from "react-router-dom";
import { DataServiceStatic } from "../../services/DataService";
import history from "../../services/history";

import "./ListBar.css";

const MenuItem = ({ text, selected, id }) => {
  return (
    <a
      href={"/explore/?category=" + id}
      onClick={e => e.preventDefault()}
      className={`menu-item ${selected ? "active" : ""}`}
    >
      {text}
    </a>
  );
};

export const Menu = (list, selected) =>
  list.map((el, idx) => {
    const { name, id } = el;
    return <MenuItem text={name} id={id} key={id + idx} selected={selected} />;
  });

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

const selected = "item1";

class ListBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
      menuItems: []
    };
  }
  componentDidMount = () => {
    // handling cover parallax
    this.listBar = document.querySelector('[data-listbar="1"]');
    window.addEventListener("scroll", this.handleOnScroll);
    DataServiceStatic.loadCategory((err, data) => {
      console.log("[ListBar.js] err", err);
      if (err) {
        return;
      }
      const list = data
        .filter(d => d.parent !== null)
        .map(d => {
          return { name: d.title, id: d.full_slug };
        });
      this.setState({
        menuItems: Menu(list, selected)
      });
    });
  };

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleOnScroll);
  };

  handleOnScroll = () => {
    const wScroll = window.scrollY;
    if (wScroll > 150) {
      this.listBar.classList.remove("d-none");
    } else {
      this.listBar.classList.add("d-none");
    }
  };

  onSelect = id => {
    history.push("/explore/?category=" + id);
  };
  render() {
    const { selected } = this.state;
    // Create menu from items
    const menu = this.state.menuItems;

    return (
      <div
        data-listbar="1"
        className="container-x listBar-con d-none zIndex-1100"
      >
        {this.state.redir && <Redirect to="/explore" />}
        <div className="App">
          <ScrollMenu
            data={menu}
            arrowLeft={ArrowLeft}
            arrowRight={ArrowRight}
            selected={selected}
            onSelect={this.onSelect}
          />
        </div>
      </div>
    );
  }
}

export default ListBar;
