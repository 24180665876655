import React from "react";
import PropTypes from "prop-types";
import TextWidget from "../../../components/TextWidget";
import { SaveButtonsOnly } from "../../../routes/openCourse/SaveButtons";

import "./Curriculum.css";
class Curriculum extends React.Component {
  constructor() {
    super();
    this.value = {
      brief: {},
      curriculum: {}
    };
  }
  onChange(data, type) {
    this.value[type] = data;
  }
  render() {
    const { valueCurriculum, valueBrief } = this.props;
    return (
      <div className="curriculumCon">
        <form>
          <div className="titleCon">
            <h3>Class Brief</h3>
          </div>
          <TextWidget
            value={valueBrief}
            onChange={data => this.onChange(data, "brief")}
          />

          <div className="titleCon" style={{ marginTop: "8%" }}>
            <h3>Curriculum</h3>
          </div>
          <TextWidget
            value={valueCurriculum}
            onChange={data => this.onChange(data, "curriculum")}
            extraOptions={["embedded"]}
          />
          <SaveButtonsOnly
            isSaving={this.props.isSaving}
            onSave={() => this.props.onSave(this.value)}
            onNext={() => this.props.onSave(this.value, true)}
          />
        </form>
      </div>
    );
  }
}
export default Curriculum;

Curriculum.propTypes = {
  valueBrief: PropTypes.string.isRequired,
  valueCurriculum: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired
};
