import PropTypes from "prop-types";
import React from "react";
import { Col, Row, Grid } from "../../../ui";

import Button from "antd/lib/button";
import Alert from "antd/lib/alert";

import { nameToNames } from "./CourseValidator";
import ImgRotation from "../../postPage/imgRotation/ImgRotationAntd";
import TextWidgetPreview from "../../../components/TextWidgetPreview";

const ValidateAndPublish = ({ course, onSave, isSaving }) => {
  return (
    <div className="previewCon">
      <Grid className="postPage-con">
        <Row>
          <Col sm={12}>
            <ImgRotation course={course} />
          </Col>
        </Row>
        <Row>
          <div className="PP-content-padding">
            <h4 className="sm-title">About teacher</h4>
            <TextWidgetPreview value={course.about_teacher_html} />
          </div>
        </Row>
        <Row>
          <div className="PP-content-padding">
            <h4 className="sm-title">Class brief</h4>
            <TextWidgetPreview value={course.brief_html} />
          </div>
        </Row>
        <Row>
          <div className="PP-content-padding">
            <h4 className="sm-title">Who can come</h4>
            <TextWidgetPreview value={course.audience_html} />
          </div>
        </Row>
        <Row>
          <div className="PP-content-padding">
            <h4 className="sm-title">Curriculum</h4>
            <TextWidgetPreview value={course.curriculum_html} />
          </div>
        </Row>
      </Grid>
      <br />
      <br />
      <br />
      <br />
      <div>
        <h4>Validation Status</h4>
        {course.validation_status_obj.any() && (
          <>
            <Alert
              message="Warning"
              description={
                <>
                  <span>
                    It seems that following sections are not fully complied,
                    please fix to continue:
                  </span>
                  <ul>
                    {course.validation_status_obj.list().map(er => {
                      return <li key={er}>{nameToNames[`${er}_err`]}</li>;
                    })}
                  </ul>
                </>
              }
              type="warning"
              showIcon
            />
          </>
        )}
        {!course.validation_status_obj.any() && (
          <Alert
            message="Data is filled fully!"
            description="You can make it public!"
            type="success"
            showIcon
          />
        )}
      </div>
      <div>
        {course.status === "DRAFT" && (
          <Button
            className="next-btn"
            onClick={() => onSave(course)}
            loading={isSaving}
            disabled={course.validation_status_obj.any()}
          >
            Make Public
          </Button>
        )}
        {course.status === "PUBLISHED" && (
          <Button
            className="next-btn"
            onClick={() => {
              onSave(course);
            }}
            loading={isSaving}
            disabled={course.validation_status_obj.any()}
          >
            Make Private
          </Button>
        )}
      </div>
    </div>
  );
};

export default ValidateAndPublish;

ValidateAndPublish.propTypes = {
  course: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired
};
