import React from "react";

export const Row = ({ className, children }) => {
  return (
    <div data-ui="row" className={` ${className ? className : ""} row`}>
      {children}
    </div>
  );
};

export const Col = ({ xs, sm, md, lg, className, children }) => {
  const smTmp = sm ? "col-sm-" + sm : "";
  const xsTmp = xs ? "col-" + xs : "";
  const mdTmp = md ? "col-md-" + md : "";
  const lgTmp = lg ? "col-lg-" + lg : "";
  return (
    <div
      data-ui="col"
      className={`${
        className ? className : ""
      } ${lgTmp} ${xsTmp} ${mdTmp} ${smTmp}`}
    >
      {children}
    </div>
  );
};

export const Grid = ({ fluid = false, className, children }) => {
  return (
    <div
      data-ui="grid"
      className={`${className ? className : ""} ${
        fluid ? "container-fluid" : "container"
      }`}
    >
      {children}
    </div>
  );
};
