import React from "react";
import { Link } from "react-router-dom";
import CourseThumb from "../../courseList/CourseThumb";

import InfiniteCarousel from "react-leaf-carousel";

import "./OnedayCarouselThumb.css";

class OnedayCarouselThumb extends React.Component {
  render() {
    const courseList = this.props.courseList;
    return (
      <div className="OnedayCarouselThumb CarouselThumb margin-con">
        <div className="flex flex-space-between">
          <h2 className="md-title">One Day Classes</h2>
          <div className="OnedayCarouselThumb-seemore-title">
            <Link to="/explore" className="dash seemore-title">
              Show all >
            </Link>
          </div>
        </div>
        {courseList.map(course => (
          <InfiniteCarousel
            key={course.id}
            breakpoints={[
              {
                breakpoint: 500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 3
                }
              }
            ]}
            dots={true}
            showSides={true}
            sidesOpacity={0.5}
            sideSize={0.1}
            slidesToScroll={4}
            slidesToShow={4}
            scrollOnDevice={true}
          >
            <div className="oneday-thumb" key={course.id}>
              <CourseThumb
                id={course.id}
                images={course.images}
                imgThumbSize="thumb2"
                title={course.title}
                category={course.category}
                price={course.price}
                rating={course.rating}
                address={course.address.osm_address_town}
                link={`/PostPage/${course.id}`}
              />
            </div>
          </InfiniteCarousel>
        ))}
      </div>
    );
  }
}
export default OnedayCarouselThumb;
