import React from "react";
import "./Verification.css";

import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Input from "antd/lib/input";
import Alert from "antd/lib/alert";
import DatePicker from "antd/lib/date-picker";

import moment from "moment";
import PhoneVerificationModal from "./PhoneVerificationModal";

import UploadProfileImg from "../../../components/UploadProfileImg";
import { DataServiceStatic } from "../../../services/DataService";

class EditProfileInner extends React.Component {
  constructor() {
    super();
    this.state = {
      isSaving: false,
      phoneVerLoading: false,
      phoneVerVisible: false,
      phoneVerSuccess: 0,
      failed: false,
      failMsg: ""
    };
  }
  componentWillMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    this.setState({ user });
  }

  onPhoneVerCancel = () => {
    this.setState({ phoneVerVisible: false });
  };

  onPhoneVerOk = code => {
    this.setState({ phoneVerLoading: true });
    DataServiceStatic.userSendCode(code, (err, data) => {
      console.info("resp", data);
      let success = 1;
      if (data.phone_status === "approved") {
        success = 1;
        const user = { ...this.state.user };
        user.phone_status = "approved";
        this.setState({ user });
      }
      if (data.phone_status === "fail") {
        success = 3;
      }
      if (data.phone_status === "pending") {
        success = 3;
      }
      if (err) {
        success = 3;
      }
      this.setState({ phoneVerLoading: false, phoneVerSuccess: success });
      if (success === 1) {
        setTimeout(() => {
          this.setState({ phoneVerVisible: false });
        }, 7000);
      }
    });
  };

  sendTextToVerify = () => {
    console.log("this.state.user.phone", this.state.user.phone);
    this.props.form.validateFields((err, fieldsValue) => {
      this.setState({ phoneVerVisible: true });

      DataServiceStatic.userSendText(fieldsValue.phone, true, (error, data) => {
        if (error.status === 400) {
          this.setState({
            failed: true,
            failMsg: error.data[0],
            phoneVerVisible: false
          });
        }
      });
    });
  };

  handleSubmit = e => {
    console.log("eee", e);
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      // const endDateTmp = fieldsValue["end_date"];
      // let end_date = null;
      // TODO: fix dates, set DD to be first of the month, now it's current day.
      // if (endDateTmp) {
      //   end_date = endDateTmp.format("YYYY-MM-DD");
      // }

      const birthdayRaw = fieldsValue["birthday"];
      const birthday = moment(birthdayRaw).format("YYYY-MM-DD");

      const phone = fieldsValue["phone"];
      const values = {
        ...fieldsValue,
        // start_date: fieldsValue["start_date"].format("YYYY-MM-DD"),
        // end_date: end_date,
        phone,
        birthday
      };
      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ isSaving: true });
        DataServiceStatic.userUpdateProfile(values, (err, data) => {
          console.log("[EditProfile.js] data", data);
          this.setState({ isSaving: false });
          if (!err) {
            message.success("Saved!");
          }
        });
      }
    });
  };

  normFile = e => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  initValHelper = val => {
    if (this.state.user[val]) {
      return this.state.user[val];
    }
    return "";
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const birthday = this.state.user.birthday
      ? moment(this.state.user.birthday)
      : null;
    const configBirthday = {
      initialValue: birthday,
      rules: [{ type: "object", required: true }]
    };

    const phone_disabled = this.state.user.phone_status === "approved";

    const formItemLayout = {
      labelCol: {
        xs: { span: 4 }
        // sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 18 }
        // sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 4
        }
      }
    };

    return (
      <>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="EditProfileCon"
        >
          <Form.Item label="Profile Image" className="flex profileImg">
            <UploadProfileImg user={this.state.user} />
            <div>*please use image with your face clearly visible</div>
          </Form.Item>
          <Form.Item label="Birthday" className="flex">
            {getFieldDecorator("birthday", configBirthday)(<DatePicker />)}
          </Form.Item>
          <Form.Item label="Phone Number:" className="flex phone">
            {getFieldDecorator("phone", {
              initialValue: this.initValHelper("phone"),
              rules: [
                { required: true, message: "Please enter your phone number." },
                { len: 11, message: "Phone number has to be 11 char long." },
                {
                  pattern: new RegExp("^[0-9]+$"),
                  message: "Please enter numbers only."
                }
              ]
            })(
              <Input style={{ width: "100%" }} disabled={phone_disabled} />
            )}{" "}
            {!phone_disabled && (
              <div>
                <Button onClick={this.sendTextToVerify}>
                  Send Text to Verify
                </Button>
              </div>
            )}
            {this.state.failed && (
              <>
                <Alert
                  message={this.state.failMsg}
                  type="error"
                  showIcon
                ></Alert>
              </>
            )}
            <p>
              *Please add 1 in-front of your phone number ex)17780000000(Canada)
            </p>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <div className="phone-status">
              <div>
                {" "}
                {phone_disabled && (
                  <>
                    <Icon type="check-circle" style={{ color: "green" }} />{" "}
                    Phone Verified.
                    <div>
                      If you need to update the phone number please contact us.
                    </div>
                  </>
                )}
                {!phone_disabled && (
                  <>
                    <Icon type="exclamation-circle" style={{ color: "red" }} />{" "}
                    Please verify phone number.
                  </>
                )}
              </div>
            </div>
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              className="next-btn"
              type=""
              htmlType="submit"
              loading={this.state.isSaving}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
        <PhoneVerificationModal
          visible={this.state.phoneVerVisible}
          loading={this.state.phoneVerLoading}
          onOk={val => this.onPhoneVerOk(val)}
          onCancel={this.onPhoneVerCancel}
          success={this.state.phoneVerSuccess}
        />
      </>
    );
  }
}

const EditProfile = Form.create({ name: "validate_other" })(EditProfileInner);

export default EditProfile;
