import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Dropdown from "antd/lib/dropdown";
import Icon from "antd/lib/icon";
import Button from "antd/lib/button";
import Menu from "antd/lib/menu";
import Avatar from "antd/lib/avatar";
import List from "antd/lib/list";

import { getUserImage } from "../../services/DataService";
import { Col, Row } from "../../ui";

import "./MessageIndex.css";
import moment from "moment";
import { DataServiceStatic } from "../../services/DataService";

const MessageListIn = ({ messageThreads }) => {
  console.log("[MessageIndex.js] messageThreads", messageThreads);

  return (
    <div className="container messageIndexCon">
      <MessageInboxBody messageThreads={messageThreads} />
    </div>
  );
};

const mapState = state => ({
  messageThreads: state.data.messageThreads
});

const MessageList = connect(mapState)(MessageListIn);

export default MessageList;

class MessageInboxBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toShow: "nonArchived"
    };
    this.currentUser = DataServiceStatic.userGetOnly();
    this.names = {
      nonArchived: "All Messages ",
      stared: "Stared ",
      unread: "Unread ",
      archived: "Archived "
    };
  }
  onClickMenu = el => {
    this.setState({ toShow: el.key });
  };
  render() {
    const messageThreads = this.props.messageThreads;
    const data = messageThreads[this.state.toShow];
    const messagesCount = data.length;

    const archivedCount = messageThreads["archived"].length;
    const unreadCount = messageThreads["unread"].length;
    const staredCount = messageThreads["stared"].length;
    const nonArchivedCount = messageThreads["nonArchived"].length;

    return (
      <>
        <h2 className="lg-title">Message Inbox</h2>
        <div className="message-dropdown-conx">
          <Dropdown
            overlay={
              <Menu
                onClick={k => {
                  console.log(k);
                  this.onClickMenu(k);
                }}
              >
                <Menu.Item key="nonArchived">
                  All Messages ({nonArchivedCount})
                </Menu.Item>
                <Menu.Item key="stared">Starred ({staredCount})</Menu.Item>
                <Menu.Item key="unread">Unread ({unreadCount})</Menu.Item>
                <Menu.Item key="archived">Archived ({archivedCount})</Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            className="message-dropdown"
          >
            <Button className="ant-dropdown-link">
              {this.names[this.state.toShow]} &nbsp; ({messagesCount}){" "}
              <Icon type="down" />
            </Button>
          </Dropdown>
        </div>
        <List
          className="demo-loadmore-list"
          // loading={loading}
          itemLayout="horizontal"
          dataSource={data}
          renderItem={item => {
            const userId = this.currentUser.id;
            let verb = "receiver";
            let seen = item.seen_sender;
            if (userId === item.receiver.id) {
              verb = "sender";
              seen = item.seen_receiver;
              console.log("[MessageIndex.1.js] sender", 1);
            }
            console.log("[MessageIndex.1.js] seen", seen);
            const last = item.messages[item.messages.length - 1];
            return (
              <List.Item actions={[<></>]} className="message-thumb">
                <Row className="w-100">
                  <Col xs={5} sm={4}>
                    <Link to={`/Message/${item.id}`}>
                      <List.Item.Meta
                        avatar={<Avatar src={getUserImage(item[verb])} />}
                        title={<span>{item[verb].full_name}</span>}
                        description={
                          <div>
                            {moment(item.created_on).format(
                              "YYYY-MM-DD hh:mm A"
                            )}
                          </div>
                        }
                      />{" "}
                    </Link>
                  </Col>
                  <Col xs={5} sm={5}>
                    <Link to={`/Message/${item.id}`}>
                      <span className={`is-read-${seen}`}>{last.body}</span>
                    </Link>
                  </Col>
                  <Col sm={3} className="d-none d-sm-block">
                    <StarArchive thread={item} verb={verb} />
                  </Col>
                </Row>
              </List.Item>
            );
          }}
        />
      </>
    );
  }
}

class StarArchive extends React.Component {
  constructor(props) {
    super(props);
    this.user = this.props.verb;

    this.state = {
      starLoading: false,
      archiveLoading: false
    };
  }
  onStar(val) {
    this.setState({ starLoading: true });
    DataServiceStatic.markThread(
      this.props.thread.id,
      "star",
      val,
      (err, data) => {
        if (!err) {
          this.setState({ starLoading: false, type: "primary" });
        }
      }
    );
  }
  onArchive(val) {
    this.setState({ archiveLoading: true });
    DataServiceStatic.markThread(
      this.props.thread.id,
      "archive",
      val,
      (err, data) => {}
    );
  }
  render() {
    let type = "default";
    let valstar = true;
    let valarchive = true;

    if (this.user === "sender") {
      type = this.props.thread.stared_receiver ? "primary" : "default";
      valstar = this.props.thread.stared_receiver ? false : true;
      valarchive = this.props.thread.archived_receiver ? false : true;
    } else {
      type = this.props.thread.stared_sender ? "primary" : "default";
      valstar = this.props.thread.stared_sender ? false : true;
      valarchive = this.props.thread.archived_sender ? false : true;
    }

    return (
      <>
        <div>
          <Button
            loading={this.state.starLoading}
            onClick={() => this.onStar(valstar)}
            icon="star"
            type={type}
          ></Button>
          <Button
            loading={this.state.archiveLoading}
            onClick={() => this.onArchive(valarchive)}
          >
            Archive
          </Button>
        </div>
      </>
    );
  }
}
