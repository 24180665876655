import React from "react";

import { CategoryServiceStatic } from "../../services/CategoryService";
import FifthSection from "./fifthSection/FifthSection";
import RecommendedSection from "./recommendedSection/RecommendedSection";
import Herobanner from "./herobanner/Herobanner01";
import OnedayCarouselThumb from "./thirdSection/OnedayCarouselThumb";
import AppBanner from "./AppBanner/AppBanner";
import VideoSection from "./videoSection/VideoSection";
import PopularCate from "./category/PopularCate";
import MainPageCategoryList from "./category/MainPageCategoryList";
// import Guides from "./Guides/Guides";
import RegisterStep from "../aboutUs/RegisterStep";

import "./indexPage.css";
import ListBar from "./ListBar";
// import IndexEvent from "./secondSection/IndexEvent";
// import AppComingSoon from "../images/appComingSoon.jpg";
import { DataServiceStatic } from "../../services/DataService";
import Spin from "antd/lib/spin";

class IndexPage extends React.Component {
  constructor() {
    super();
    this.state = {
      parentCategory: [],
      courseList: [],
      courseListOneDay: [],
      courseListRecommended: [],
      recParentCategory: [],
      isLoading: true
    };
    this.bodyCss = document.getElementById("root");
    this.CategoryServiceStatic = CategoryServiceStatic;
    // this.CoursesServiceStatic.
  }
  componentDidMount() {
    this.bodyCss.classList.add("indexPageTop");
    const parentCategory = this.CategoryServiceStatic.getParents();
    this.setState({ parentCategory });
    // DataServiceStatic.loadAllCourses((err, data) => {
    DataServiceStatic.loadAllCoursesLean((err, data) => {
      const oneDay = data.filter(d => d.type === "ONE_TIME");
      const recommended = data.filter(d => d.is_recommended);
      const recParentCat = [];
      const recParentIds = {};
      recommended.forEach(d => {
        if (!recParentIds[d.category]) {
          recParentCat.push({ id: d.category_id, title: d.category });
          recParentIds[d.category] = true;
        }
      });

      this.setState({
        isLoading: false,
        courseList: data,
        courseListOneDay: oneDay,
        courseListRecommended: recommended,
        recParentCategory: recParentCat
      });
    });
  }
  componentWillMount() {
    this.CategoryServiceStatic.loadCategories((err, data) => {});
  }
  componentWillUnmount() {
    this.bodyCss.classList.remove("indexPageTop");
  }

  // handle onScroll event

  render() {
    return (
      <div className="indexPage">
        <ListBar className="" />
        <Herobanner />
        <div className="mainPage-width">
          <PopularCate />
          <VideoSection />
          <RegisterStep />
          <Spin spinning={this.state.isLoading}>
            <RecommendedSection
              parentCategory={this.state.recParentCategory}
              courseList={this.state.courseListRecommended}
            />
          </Spin>
          <MainPageCategoryList />
          <Spin spinning={this.state.isLoading}>
            <OnedayCarouselThumb courseList={this.state.courseListOneDay} />
          </Spin>

          <AppBanner />
        </div>
        <FifthSection />
      </div>
    );
  }
}

export default IndexPage;
