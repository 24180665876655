import React from "react";
import { Row, Col } from "../../ui";

import { Link } from "react-router-dom";
import Icon from "antd/lib/icon";

import ProfileImg from "../../components/profileImg/ProfileImg";
import { DataServiceStatic, getUserImage } from "../../services/DataService";
import { capitalizeFirstLetter } from "../../services/Utils";
import ProfileTabs from "./component/ProfileTabs";
import "./userProfile.css";

class UserProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      isLoading: true
    };
  }
  componentDidMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    this.setState({ user: user, isLoading: false });
  }
  render() {
    if (this.state.isLoading) return <></>;

    const { user } = this.state;
    let url = "";
    // if (user && user.user_image && user.user_image.length > 1) {
    //   const urlObj = user.user_image[user.user_image.length - 1];
    //   url = urlObj.profile_image;
    // }
    url = getUserImage(user);
    return (
      <div className="container profilePage-con">
        <div className="userProfile-con m-top">
          <Row>
            <Col xs={12} sm={3} className="">
              <ProfileImg url={url} />
              <div className="edit-btn">
                <Link to="/ProfileEdit">Edit Profile</Link>
              </div>
            </Col>
            <Col xs={12} sm={4}>
              <div className="profileInfo">
                <p>
                  Name: {user.first_name} {user.last_name}{" "}
                </p>
                <p>Email: {user.email}</p>
                <p>Company or School: {user.occupation_company_name}</p>
                <p>Major: {capitalizeFirstLetter(user.occupation)}</p>
                <p>Website: {user.personal_url} </p>
              </div>
            </Col>
            <Col xs={12} sm={5}>
              {user.verified && (
                <h4 className="verified-icon">
                  <Icon type="safety" /> You are verified!
                </h4>
              )}
            </Col>
          </Row>
          <ProfileTabs />
        </div>
      </div>
    );
  }
}
export default UserProfile;
