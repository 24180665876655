import React from "react";
import { Grid } from "../../ui";
import FilterModal2 from "./FilterModal2";
import "./courseCategory.css";
import { DataServiceStatic } from "../../services/DataService";
import { dispatch, store } from "../../services/EventBus";
import history from "../../services/history";

class CourseCategory extends React.Component {
  constructor() {
    super();
    this.state = {
      categoryModalOpen: false,
      categoryModalClickedId: "",
      categoryModalName: "",
      categoryModalFilterType: null,
      filters: []
    };
    this.filtersCounter = 0;
  }
  componentDidMount() {
    DataServiceStatic.loadAllForClassInit();
    this.checkForFilters();
  }

  checkForFilters() {
    setTimeout(() => {
      const filters = store.getState().data.filters;
      if (filters.length === 0) {
        this.filtersCounter += 1;
        if (this.filtersCounter <= 25) {
          this.checkForFilters();
        }
      }
      this.setState({ filters });
    }, 200);
  }

  onClick(el) {
    this.setState({
      categoryModalOpen: true,
      categoryModalClickedId: el.target.id,
      categoryModalName: el.target.dataset.name,
      categoryModalFilterType: el.target.dataset.type
    });
  }
  onClose(data, reset = false) {
    if (reset) {
      this.setState({ categoryModalOpen: false, filter: null });
      dispatch.data.addToFilter(null);
      setTimeout(() => {
        const filters = store.getState().data.filters;
        this.setState({ filters });
      }, 300);
      history.push("/explore");
    }
    // if data is null modal was closed on ESC or X.
    // don't filter just close
    if (data) {
      this.setState({ categoryModalOpen: false, filter: data });
      if (data.multi) {
        dispatch.data.addToFilterMulti(data);
      } else {
        dispatch.data.addToFilter(data);
      }
      setTimeout(() => {
        const filters = store.getState().data.filters;
        this.setState({ filters });
      }, 1000);
    } else {
      this.setState({ categoryModalOpen: false });
    }
  }
  render() {
    const isSelected = name => {
      if (name === "multi") {
        const multiNames = ["pricebuttons", "location", "date"];
        const found = this.state.filters.find(f => multiNames.includes(f.type));
        return found && found.criteria.length > 0;
      } else {
        const found = this.state.filters.find(f => f.type === name);
        return found && found.criteria.length > 0;
      }
    };
    return (
      <div className="sort-border">
        <Grid className="courseCategory-con">
          <ul className="flex" id="filters-modal-anchor">
            <li
              className="catlist"
              id="categoryEl"
              data-name="Category"
              data-type="category"
              data-selected={isSelected("category")}
              onClick={e => this.onClick(e)}
            >
              Category
            </li>
            <li
              className="catlist"
              id="dateEl"
              data-name="Date"
              data-type="date"
              data-selected={isSelected("date")}
              onClick={e => this.onClick(e)}
            >
              Date
            </li>
            <li
              className="catlist"
              id="locationEl"
              data-name="Location"
              data-type="location"
              data-selected={isSelected("location")}
              onClick={e => this.onClick(e)}
            >
              Location
            </li>
            {/* <li
              className="catlist"
              id="classTypeEl"
              data-name="Type"
              data-type="classtype"
              data-selected={isSelected("classtype")}
              onClick={e => this.onClick(e)}
            >
              Type
            </li> */}
            {/* <li
              className="catlist"
              id="priceEl"
              data-name="Price"
              data-type="price"
              data-selected={isSelected("price")}
              onClick={e => this.onClick(e)}
            >
              Price
            </li>
            <li
              className="catlist"
              id="languageEl"
              data-name="Language"
              data-type="language"
              data-selected={isSelected("language")}
              onClick={e => this.onClick(e)}
            >
              Language
            </li> */}
            <li
              className="catlist"
              id="languageEl"
              data-name="More"
              data-type="multi"
              data-selected={isSelected("multi")}
              onClick={e => this.onClick(e)}
            >
              More Filters
            </li>
            {/* add this inside of more filter */}
            <li className="catlist" onClick={e => this.onClose(null, true)}>
              Clear all filters
            </li>
          </ul>
        </Grid>
        {/* <FilterModal
          elementIdToBind={this.state.categoryModalClickedId}
          onClose={data => this.onClose(data)}
          open={this.state.categoryModalOpen}
          name={this.state.categoryModalName}
          filterType={this.state.categoryModalFilterType}
          filter={this.state.filters}
        /> */}
        <FilterModal2
          onClose={data => this.onClose(data)}
          open={this.state.categoryModalOpen}
          filterType={this.state.categoryModalFilterType}
          filter={this.state.filters}
        ></FilterModal2>
      </div>
    );
  }
}

export default CourseCategory;
