import React from "react";
import { DataServiceStatic } from "../../services/DataService";
import Spin from "antd/lib/spin";
import ErrorImg from "../images/donanum-error-illust.png";
import SuccessImg from "../images/donanum-success-illust.png";
import "./ActivateDetail.css";

export class ActivateDetail extends React.Component {
  state = {
    isLoading: true,
    ok: true
  };
  componentDidMount() {
    const path = window.location.pathname;
    const sp = path.split("/");
    if (sp.length === 4) {
      const uid = sp[2];
      const token = sp[3];
      DataServiceStatic.userActivate({ uid, token }, (err, data) => {
        if (err) {
          this.setState({ isLoading: false, ok: false });
        } else {
          this.setState({ isLoading: false, ok: true });
        }
      });
    }
  }
  render() {
    return (
      <div className="ActivateDetail container">
        <div>
          {this.state.isLoading && <Spin size="large" />}
          {!this.state.isLoading && !this.state.ok && (
            <div>
              <div className="image1">
                <img src={ErrorImg} alt="" style={{ width: "100%" }} />
              </div>
              <h3>Sorry, the link is invalid. Please try again.</h3>
            </div>
          )}
          {!this.state.isLoading && this.state.ok && (
            <div className="active">
              <div className="image2">
                <img src={SuccessImg} alt="" style={{ width: "100%" }} />
              </div>
              <h3>Thank you! Your account is activated.</h3>
              <p>Please login to continue.</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ActivateDetail;
