import React from "react";
import PropTypes from "prop-types";

import Select from "antd/lib/select";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Input from "antd/lib/input";
import Radio from "antd/lib/radio";
import Row from "antd/lib/row";
import Checkbox from "antd/lib/checkbox";
import InputNumber from "antd/lib/input-number";

import "./OpenCourseCom.css";

import CategorySelector from "./CategorySelector2";
import { DataServiceStatic } from "../../services/DataService";
import CityAuto from "./CityAuto";
import { SaveButtonsSubmit } from "../../routes/openCourse/SaveButtons";
import { Collapse } from "antd";

const { Panel } = Collapse;
const { Option } = Select;

const { TextArea } = Input;

class OpenCourseCom extends React.Component {
  constructor(props) {
    super(props);
    let showOtherLang = false;
    if (
      this.props.course.language &&
      this.props.course.language.code === "zxx"
    ) {
      showOtherLang = true;
    }

    this.state = {
      class: {},
      onlineCheck: this.props.course.address.is_online,
      languages: [],
      categories: [],
      categoryId: this.props.course.category_id,
      course: this.props.course,
      showOtherLang: showOtherLang,
      selectedCity: this.props.course.address.osm_display_name,
      autoCityInEdit: false,
      autoCity: null
    };
  }
  onCategorySelect = id => {
    this.setState({ categoryId: id });
  };
  onBlur = e => {
    console.log("[OpenCourseCom.js] e", e);
    const { name, value } = e.target;
    console.log("[OpenCourseCom.js] e n", name, value);
    if (name === "language_other") {
      const course = { ...this.state.course };
      course.language_other = value;
      console.log("[OpenCourseCom.js] course", course);
      this.setState({ course });
    }
  };
  handleSubmit = (e, next) => {
    if (e && e.preventDefault) e.preventDefault();
    this.props.form.validateFields((err, values) => {
      console.log("values", values);
      const autoCity = this.state.autoCity;
      if (!err) {
        values.category_id = this.state.categoryId;
        values.language_other = this.state.course.language_other;
        values.autoCity = this.state.autoCity;
        values.address = {
          // location: values.location,
          place: values.place,
          address: values.address,
          is_online: this.state.course.address.is_online,
          full_address: values.full_address,
          online_contact: values.online_contact || "none"
        };
        if (this.state.autoCity) {
          values.address.osm_address_country = autoCity.address.country;
          values.address.osm_address_country_code =
            autoCity.address.country_code;
          values.address.osm_address_county = autoCity.address.county;
          values.address.osm_address_state = autoCity.address.state;
          values.address.osm_address_town = autoCity.address[autoCity.type]; // sometime is town, sometimes city, type describes it well
          values.address.osm_display_name = autoCity.display_name;
          values.address.osm_class = autoCity.class;
          values.address.osm_id = autoCity.id;
          values.address.osm_lat = autoCity.lat;
          values.address.osm_lon = autoCity.lon;
          values.address.osm_place_id = autoCity.place_id;
          values.address.osm_state_district = autoCity.state_district;
          values.address.osm_type = autoCity.type;
        }
        if (this.state.course.address.id) {
          values.address.id = this.state.course.address.id;
        }
        console.log("Received values of form: ", values);
        console.log("[OpenCourseCom.js] isnet", next);
        this.props.onSave(values, next);
        this.setState({
          autoCityInEdit: false
        });
        if (autoCity) {
          this.setState({
            autoCity: autoCity.display_name
          });
        }
        // DataServiceStatic.createCourse(values, (err, data) => {
        //   console.log('[OpenCourseCom.js] ok', err, data);
        // });
      }
    });
  };
  onChangeOnlineClass(e) {
    const course = { ...this.state.course };
    course.address.is_online = e.target.checked;
    this.setState({ course });
  }

  normFile = e => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  onLangChange = code => {
    if (code === "zxx") {
      this.setState({ showOtherLang: true });
    } else {
      this.setState({ showOtherLang: false });
    }
  };
  onSelectCity = data => {
    console.log("[OpenCourseCom.js] city data", data);
    this.setState({ autoCity: data, selectedCity: data.display_name });
  };
  editAutoCity = () => {
    this.setState({ autoCityInEdit: true });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const categoryId = this.state.categoryId;

    const currencySelector = getFieldDecorator("currency", {
      initialValue: this.state.course.currency
    })(
      <Select style={{ width: 80 }}>
        <Option value="CAD">CAD</Option>
      </Select>
    );

    const formItemLayout = {
      labelCol: {
        xs: { span: 3 }
        // sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 18 }
        // sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 0
        }
      }
    };

    return (
      <Form
        {...formItemLayout}
        onSubmit={this.handleSubmit}
        className="openCourseComCon"
      >
        <h3>What type of class do you want to open?</h3>
        <Form.Item className="flex classTypeBox" label="">
          {getFieldDecorator("type", {
            initialValue: this.state.course.type,
            rules: [
              {
                required: true,
                message: "Please choose the type",
                whitespace: true
              }
            ]
          })(
            <Radio.Group>
              <Radio value="ONE_TIME">One Day Class</Radio>
              <Radio value="MORE_THEN_ONE_TIME">More Then One Day</Radio>
              <Radio value="TUTORING">Tutoring</Radio>
              <Radio value="MENTORING">Mentoring</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item className="flex" label="Title">
          {getFieldDecorator("title", {
            initialValue: DataServiceStatic.initValHelper(
              this.state.course,
              "title"
            ),
            rules: [
              {
                required: true,
                message: "Please enter your class title",
                whitespace: true
              }
            ]
          })(<Input placeholder="Please enter your class title" />)}
        </Form.Item>
        {/*
          <Col xs={{ span: 24 }} sm={{ span: 11, offset: 2 }}> */}
        <Form.Item className="flex" label="Category" hasFeedback>
          <CategorySelector
            onSelect={id => this.onCategorySelect(id)}
            categoryId={categoryId}
            categories={this.props.categories}
          />
          {!this.state.categoryId && (
            <div className="ant-form-explain">Please select category</div>
          )}
        </Form.Item>
        {/* </Col>
        </Row> */}

        <div className="divider">
          <p style={{ color: "#1790ff" }}>
            *If you planning to run a class through the online media (ex.Skype,
            Google Meet, Zoom, etc), please select online check box, otherwise
            please write the location information.
          </p>
          <Form.Item {...tailFormItemLayout}>
            <Checkbox
              checked={this.state.course.address.is_online}
              onChange={e => this.onChangeOnlineClass(e)}
            >
              Online Live Class
            </Checkbox>
          </Form.Item>
          {this.state.course.address.is_online && (
            <>
              <h3>Online Service URL</h3>
              <Form.Item className="flex" label="">
                {getFieldDecorator("online_contact", {
                  initialValue: this.state.course.address.online_contact,
                  rules: [
                    {
                      required: true,
                      message: "Please enter information"
                    }
                  ]
                })(<Input placeholder="https://meet.gogle.com/abc-defg-hjl" />)}
              </Form.Item>

              <Collapse
                bordered={false}
                defaultActiveKey={["0"]}
                className="chatguide"
                expandIcon={() => <Icon type="caret-down" />}
                style={{ paddingBottom: "40px" }}
              >
                <Panel header="How to get link from Google Meet?" key="1">
                  <ol>
                    <li>Create Google account</li>
                    <li>
                      On{" "}
                      <a
                        href="https://meet.google.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Google Meet site
                      </a>{" "}
                      click{" "}
                      <span
                        style={{
                          color: "white",
                          background: "#02786a",
                          padding: "3px",
                          borderRadius: "3px"
                        }}
                      >
                        start a meeting
                      </span>{" "}
                      button
                    </li>
                    <li> Copy URL and paste inside of input box</li>
                  </ol>
                </Panel>
              </Collapse>
            </>
          )}
          {!this.state.course.address.is_online && (
            <>
              <Form.Item className="flex" label="Place">
                {getFieldDecorator("place", {
                  initialValue: this.state.course.address.place,
                  rules: [
                    {
                      required: true,
                      message: "Please enter place"
                    }
                  ]
                })(<Input placeholder="ex) Library" />)}
              </Form.Item>

              <Form.Item className="flex " label="Address">
                {getFieldDecorator("full_address", {
                  initialValue: this.state.course.address.full_address,
                  rules: [
                    {
                      required: true,
                      message: "Please enter address"
                    }
                  ]
                })(<Input placeholder="Please enter address" />)}
              </Form.Item>
              <Form.Item label="City" className="citybox">
                {this.state.autoCityInEdit ? (
                  <CityAuto onSelect={data => this.onSelectCity(data)} />
                ) : (
                  <span>
                    {this.state.selectedCity}{" "}
                    <Icon onClick={this.editAutoCity} type="edit" />
                  </span>
                )}
              </Form.Item>
            </>
          )}
        </div>
        <Form.Item className="size flex" label="Class size">
          {getFieldDecorator(
            "size_max",
            {
              initialValue: this.state.course.size_max,
              rules: [
                {
                  required: true,
                  message: "Please enter number"
                }
              ]
            },
            { initialValue: 3 }
          )(<InputNumber min={1} max={10} />)}
          <span className="ant-form-text"> </span>
        </Form.Item>

        <Form.Item className="flex" label="Language">
          {getFieldDecorator("language_id", {
            initialValue: this.state.course.language_id,
            rules: [
              {
                required: true,
                message: "Please select the language"
              }
            ]
          })(
            <Radio.Group>
              {this.props.languages.map(val => {
                return (
                  <span key={val.id}>
                    <Radio
                      value={val.id}
                      onChange={() => this.onLangChange(val.code)}
                    >
                      {val.name}{" "}
                      {val.code === "zxx" && (
                        <Input
                          name="language_other"
                          defaultValue={this.props.course.language_other}
                          disabled={!this.state.showOtherLang}
                          onBlur={this.onBlur}
                        />
                      )}
                    </Radio>
                  </span>
                );
              })}
            </Radio.Group>
          )}
        </Form.Item>

        <Form.Item className="flex" label="Level">
          {getFieldDecorator("level", {
            initialValue: this.state.course.level,
            rules: [
              {
                required: true,
                message: "Please choose the level of class",
                whitespace: true
              }
            ]
          })(
            <Radio.Group>
              <Radio.Button value="BEGINNER">Beginner</Radio.Button>
              <Radio.Button value="INTERMEDIATE">Intermediate</Radio.Button>
              <Radio.Button value="ADVANCED">Advanced</Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>

        <Row className="pricebox divider">
          <Form.Item className="flex" label="Pricing">
            {getFieldDecorator("price", {
              initialValue: this.state.course.price,
              rules: [
                {
                  required: true,
                  message: "Please enter price"
                }
              ]
            })(
              <Input addonBefore={currencySelector} style={{ width: "100%" }} />
            )}
          </Form.Item>
          <Form.Item className="flex" label="Price Type">
            {getFieldDecorator("price_mode", {
              initialValue: this.state.course.price_mode,
              rules: [
                {
                  required: true,
                  message: "Please select mode"
                }
              ]
            })(
              <Radio.Group>
                <Radio.Button value="HOURLY">Hourly</Radio.Button>
                <Radio.Button value="TOTAL">Total</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>
        </Row>
        <Form.Item className="flex" label="">
          <h3>Requirements & notes:</h3>
          {getFieldDecorator("requirement", {
            initialValue: this.state.course.requirement,
            rules: [
              {
                required: false,
                message: " "
              }
            ]
          })(
            <TextArea rows={4}>
              ex) Bring your own laptop and download Adobe Photoshop
            </TextArea>
          )}
        </Form.Item>

        <p className="note ">
          *Note: If you might have to cancel the class because there are not
          enough students registered or other reasons, YOU MUST WRITE A NOTE.
          Also the class HAVE TO CANCEL before 3 DAYS.
        </p>
        <Form.Item {...tailFormItemLayout}>
          <SaveButtonsSubmit
            isSaving={this.props.isSaving}
            onNext={e => this.handleSubmit(e, true)}
          />
        </Form.Item>
      </Form>
    );
  }
}

const OpenCourseComWrapped = Form.create({ name: "validate_other" })(
  OpenCourseCom
);

OpenCourseCom.propTypes = {
  languages: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  course: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  isSaving: PropTypes.bool.isRequired
};

export default OpenCourseComWrapped;
