import React from "react";
import TextWidget from "../../../components/TextWidget";
import PropTypes from "prop-types";
import { SaveButtonsOnly } from "../../../routes/openCourse/SaveButtons";

import "./Curriculum.css";
class About extends React.Component {
  onChange(data) {
    this.value = data;
  }
  render() {
    const value = this.props.value;
    return (
      <div className="curriculumCon">
        <form>
          <div className="titleCon">
            <h3>About Teacher</h3>
          </div>
          <TextWidget value={value} onChange={data => this.onChange(data)} />

          <SaveButtonsOnly
            isSaving={this.props.isSaving}
            onSave={() => this.props.onSave(this.value)}
            onNext={() => this.props.onSave(this.value, true)}
          />
        </form>
      </div>
    );
  }
}
export default About;

About.propTypes = {
  onSave: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isSaving: PropTypes.bool.isRequired
};
