import React from "react";
import { Col, Row } from "../../../ui";

import CourseThumb from "../../courseList/CourseThumb";
import "./firstSectionThumb.css";

class RecommendedSectionCourses extends React.Component {
  render() {
    const { categoryId, courseList } = this.props;
    const courseListFiltered = courseList.filter(
      c => c.category_id === categoryId
    );
    return (
      <div className="index-thumb-list-right">
        <Row className="">
          {courseListFiltered.map(course => (
            <Col className="oneday-thumb" xs={6} md={3} key={course.id}>
              <CourseThumb
                id={course.id}
                images={course.images}
                imgThumbSize="thumb2"
                title={course.title}
                category={course.category}
                price={course.price}
                rating={course.rating}
                address={course.address.osm_address_town}
                link={`/PostPage/${course.id}`}
              />
            </Col>
          ))}
        </Row>
      </div>
    );
  }
}
export default RecommendedSectionCourses;
