import Icon from "antd/lib/icon";

import React from "react";
import { Link } from "react-router-dom";
import { WishHeart } from "../../components/WishHeart";
import BlankImage from "../../images/blank.jpg";
import { priceOrFree } from "../../services/Utils";

import "./courseList.css";

class CourseThumb extends React.Component {
  render() {
    const {
      images,
      imgThumbSize = "image",
      title,
      category,
      price,
      address,
      id,
      link,
      rating,
      tooLate,
      verified,
      type,
      isNew,
      is_free
    } = this.props;
    const imgCopy = [...images];
    let imgScr = "";
    let imgThumb = "missing";
    if (imgCopy.length > 0) {
      const mainImg = imgCopy.shift();
      imgThumb = ["thumbUrl1", "thumbUrl2", "thumbUrl3"].includes(imgThumbSize)
        ? imgThumbSize
        : "thumbUrl1";
      imgScr = mainImg[imgThumb];
    }
    const rate = rating ? rating.average_rating : false;

    return (
      <div className={`CourseThumb ${tooLate ? "tooLate" : ""}`}>
        <Link to={link}>
          <div className="classThumb onedayClassThumb xxx" data-img={imgThumb}>
            <div className="imgCon">
              {imgScr && <img alt={title} src={imgScr} />}
              {!imgScr && <img alt="" src={BlankImage} />}
            </div>
          </div>
          <div className="thumb-text-con">
            <p className="thumb-category-text">{category}</p>
            {isNew && <div className="thumb-new-tag">New</div>}
            <h3 className="thumb-title">{title}</h3>
            <div className="thumb-address">{address}</div>
            <div>
              <p className="thumb-p">{priceOrFree(price, is_free)}</p>
              <div className="thumb-rate">
                {/* {isNew && <div className="thumb-new-tag">New</div>}
                 */}

                <div className="flex flex-space-between thumb-rate-con">
                  <div className="flex">
                    {rate && (
                      <>
                        <div className="star-con">
                          <Icon type="star" theme="filled" />
                        </div>{" "}
                        <p>{rate} </p>
                      </>
                    )}
                  </div>
                  <div className="verified-tag">
                    {verified && (
                      <>
                        <Icon type="safety" /> Verified
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
        <div className="imgCon">{id && <WishHeart course_id={id} />}</div>
        {price === 0 && <div className="free-tag">Free</div>}
        {type === "ONE_TIME" && <div className="oneday-tag">1 Day</div>}
      </div>
    );
  }
}

export default CourseThumb;
