import React from "react";

import AppleBtn from "../../images/applebtn.png";
import GoogleBtn from "../../images/googlePlaybtn.png";
import AppAdsBanner from "../../images/app-ads.png";

class AppBanner extends React.Component {
  render() {
    return (
      <div className="">
        <div
          className=" "
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <div style={{ marginTop: "30px" }}>
            <p>Your Knowledge, On the Go. </p>

            <h3 style={{ fontSize: "24px" }}>
              The Official Donanum App Coming Soon
            </h3>
            <div className="flex">
              <div style={{ width: "120px", marginRight: "10px" }}>
                <img
                  src={AppleBtn}
                  style={{ width: "100%" }}
                  className=""
                  alt="AppleBtn"
                />
              </div>
              <div style={{ width: "120px" }}>
                <img
                  src={GoogleBtn}
                  style={{ width: "100%" }}
                  className=""
                  alt="GoogleBtn"
                />
              </div>
            </div>
          </div>
          <div>
            <img
              src={AppAdsBanner}
              style={{ width: "100%" }}
              className=""
              alt="VideoBanner"
            />
          </div>
        </div>
      </div>
    );
  }
}
export default AppBanner;
