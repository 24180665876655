import React from "react";
// import { Carousel } from "react-bootstrap";
import Icon from "antd/lib/icon";
import Carousel from "antd/lib/carousel";

import "./imgRotation.css";

class ImgRotationAntd extends React.Component {
  constructor(props) {
    super(props);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.carousel = React.createRef();
  }
  next() {
    this.carousel.next();
  }
  previous() {
    this.carousel.prev();
  }
  render() {
    const corse = this.props.course;
    let props = this.props;
    props = {
      ...props,
      autoplay: true,
      autoplaySpeed: 5000
    };
    const { images } = corse;
    console.log("[ImgRotationAntd.jsx] images", images);
    return (
      <div className="ImgRotation">
        <div className="carouselImg-con">
          <div className="arrow arrow-left" onClick={this.previous}>
            <Icon type="left-circle" />
          </div>
          <Carousel ref={node => (this.carousel = node)} {...props}>
            {images &&
              images.map(img => {
                return (
                  <div className="item" key={img.id}>
                    <img
                      alt=""
                      className="slick-img"
                      //   className="d-block w-100"
                      key={img.id}
                      src={img.thumb1 || img.image}
                    />
                  </div>
                );
              })}
          </Carousel>
          <div className="arrow arrow-right" onClick={this.next}>
            <Icon type="right-circle" />
          </div>
        </div>

        {/* <div className="postImg-con">
          <Carousel>
            {images &&
              images.map(img => (
                <Carousel.Item key={img.id} className="carouselImg-con">
                  <img
                    alt=""
                    className="d-block w-100"
                    key={img.id}
                    src={img.image}
                  />
                </Carousel.Item>
              ))}
          </Carousel>
        </div> */}
      </div>
    );
  }
}
export default ImgRotationAntd;
