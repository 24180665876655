import React from "react";
import { connect } from "react-redux";
import Icon from "antd/lib/icon";

import "./WishHeart.css";
import { DataServiceStatic } from "../services/DataService";

const onClickWish = props => {
  if (props.isOn) {
    DataServiceStatic.delWishlist(props.wishlistObj.id);
  } else {
    DataServiceStatic.addWishlist(props.course_id);
  }
};

const WishHeartIn = props => {
  return (
    <div className="flex wishHeart-con" onClick={() => onClickWish(props)}>
      <div className="WishHeart heart">
        {!props.isOn && <Icon type="heart" alt="wishlist" />}
        {props.isOn && (
          <Icon type="heart" theme="filled" className="filledheart" />
        )}
      </div>
      <p className="saved">Wishlist</p>
    </div>
  );
};

const mapState = (state, ownProps) => {
  return {
    isOn: !!state.data.wishlist.find(d => d.course_id === ownProps.course_id),
    wishlistObj: state.data.wishlist.find(
      d => d.course_id === ownProps.course_id
    )
  };
};

export const WishHeartpp = connect(mapState)(WishHeartIn);
