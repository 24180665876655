import React from "react";
import { Col, Row } from "../../ui";
import { Link } from "react-router-dom";

import RegisterStep from "./RegisterStep";

import AboutusImg1 from "../images/aboutus02.png";
import AboutusImg2 from "../images/aboutus01.png";
import ConnectionIcon from "../images/hand.png";
import MoneyIcon from "../images/money.png";
import TumbImg from "../images/thumb.png";
import "./AboutUs.css";

class AboutUs extends React.Component {
  render() {
    return (
      <div className="aboutUsContainer">
        <div
          className="br herobanner"
          style={{
            background: "url(" + require("../images/herobanner_v7.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat"
          }}
        >
          <div className="container">
            <h1 className="side-hero-title">
              Connecting the World Through Learning
            </h1>
            <p className="">
              Donanum is a streamlined platform to connect the knowledge-hungry
              with talented teachers.
            </p>
            <div className="hero-btn">
              <div className="">
                <Link to="/explore">Explore Classes</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="container margin-con aboutus-aboutus text-center">
          <div className="ourstory">
            <h4 className="md-title">Our Story</h4>
            <p className="des-p-md">
              Our platform connects people who want to learn and talented
              individuals who want to share their knowledge. Anyone can open a
              class as long as they have talent, experience, or skills to share
              with others. Your talent and skills may be wanted by others! Share
              your skills, make new connections, and enjoy helping others in
              need.
            </p>
          </div>
        </div>

        <div className=" container margin-con">
          <Row className="">
            <Col className="m-sm-bottom" xs={12} sm={6}>
              <div className="imgCon sharingIconGif">
                <img src={AboutusImg1} alt="" />
              </div>
            </Col>
            <Col className="text-des" xs={12} sm={6}>
              <h4 className="md-title">The Power of Sharing</h4>
              <p className="md-p">
                Imagine this: There are three people in a city of Vancouver:
                Ann, John, and Tom. Ann is a graphic designer. John is a
                developer. Tom is a baker. Think of the possibilities if Ann,
                John, and Tom share their talents with each other?
              </p>
              <p className="md-p">
                We can all learn new skills using the internet or video
                tutorials, but everyone knows that having hands- on lessons from
                someone knowledgeable can make all the difference in
                understanding new things. We value the connections you make,
                experiences you exchange, and lives you change.
              </p>
            </Col>
          </Row>
        </div>

        <div className="container margin-con">
          <Row className="">
            <Col className="text-des" xs={12} sm={6}>
              <h4 className="md-title">Any Talent Is Needed</h4>
              <p className="a">Learn or Share What You Want!</p>
              <p className="md-p">
                Anyone can learn what they want and anyone can teach what they
                want. Each person has their own special talent or skills. Your
                talent may be in demand by hundreds of people in your city. Go
                ahead and start creating your own class or look for a class to
                join now!
              </p>

              <div className="btn-con flex">
                <div className="startbtn1">
                  <Link to="/explore">Explore Classes</Link>
                </div>
                <div className="startbtn2">
                  <Link to="/BeTutorInfo">Create Your Class</Link>
                </div>
              </div>
            </Col>
            <Col className="" xs={12} sm={6}>
              <div className="imgCon platform-connect-img">
                <img src={AboutusImg2} alt="" />
              </div>
            </Col>
          </Row>
        </div>

        {/* <div className="container margin-con">
          <Row className="">
            <Col className="text-box" xs={12} sm={6}>
              <h5 className="lg-title">Try a class now!</h5>
              <p className="md-p">
                Learn from talented people! Meet a mentor! You can meet people
                who work in the industry you are interested in. You will have a
                good experience at a reasonable price.
              </p>
              <div className="startbtn">
                <Link to="BeTutorInfo">Get Started ></Link>
              </div>
              <div className="startbtn">
                <Link to="BeTutorInfo">Get Started ></Link>
              </div>
            </Col>{" "}
            <Col className="text-box" xs={12} sm={6}>
              <h5 className="lg-title">Become a Tutor now!</h5>
              <p className="md-p">
                Anyone can register for classes and become a tutor. Your talent
                need for someone. Make a new connection with cool people who
                want to learn from you. It will be a wonderful experience.
              </p>
              <div className="startbtn">
                <Link to="BeTutorInfo">Get Started ></Link>
              </div>
            </Col>
          </Row>
        </div> */}
        <RegisterStep />

        <div className=" container margin-con fqa">
          <h3 className="md-title center m-bottom">Your Questions Answered</h3>

          <Row>
            <Col xs={12} sm={6}>
              <div className="text-con">
                <h4 className="md-s-title">
                  How Do I Know Which Class is Right for Me?
                </h4>
                <p className="md-p">
                  Each course should have a concise description that should give
                  you a good idea of what its about. Plus, previous students can
                  leave reviews so you can see what you’re about to experience
                  from a student’s point of view.
                </p>
              </div>
            </Col>

            <Col xs={12} sm={6}>
              <div className="text-con">
                <h4 className="md-s-title">
                  How Can I Trust the Quality of Classes?
                </h4>
                <p className="md-p">
                  We have a system of “Verified” tutors who we have confirmed
                  through their certifications or proof of employment. That way,
                  you can have peace of mind knowing that your tutor knows their
                  stuff!
                </p>
              </div>
            </Col>
          </Row>
        </div>

        <div className="container margin-con text-center">
          <h3 className="md-title center m-bottom">How Our App Works</h3>
          <div className="row">
            <Col className="" xs={12} sm={4}>
              <div className="bti-icon">
                <img src={ConnectionIcon} alt="" />
              </div>
              <h4 className="md-s-title">Connecting People</h4>
              <p className="des-p-sm">
                Makes connecting teachers and students easy
              </p>
            </Col>

            <Col className="app-space" xs={12} sm={4}>
              <div className="bti-icon">
                <img src={MoneyIcon} alt="" />
              </div>
              <h4 className="md-s-title">Free Classes are Always Free</h4>
              <p className="des-p-sm">
                Check out all the free classes in your area. Free means free –
                no hidden fees.
              </p>
            </Col>

            <Col className="" xs={12} sm={4}>
              <div className="bti-icon">
                <img src={TumbImg} alt="" />
              </div>
              <h6 className="md-s-title">Find the Solution You Need</h6>
              <p className="des-p-sm">
                Don’t be frustrated when you get stuck, find a tutor and end the
                struggle.
              </p>
            </Col>
          </div>
        </div>

        <div className="readyCon">
          <div
            className="banner"
            style={{
              background:
                "url(" +
                require("../images/donanum-learn-bottom-banner.jpg") +
                ")",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              backgroundRepeat: "no-repeat"
            }}
          >
            <div className="container fifthMargin">
              <h2 className="">Ready to Learn?</h2>
              <div className="getStart-btn">
                <Link to="/explore">Get Started</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
