import React from "react";
import { Row } from "../../../ui";

import { Link } from "react-router-dom";
import Empty from "antd/lib/empty";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import moment from "moment";
import { getUserImage } from "../../../services/DataService";

const Tbl = ({ data }) => {
  console.log("data", data);
  const columns = [
    {
      key: "course.owner.id",
      dataIndex: "course.owner.id",
      title: "Teacher",
      render: (text, record) => {
        const img = getUserImage(record.course.owner);
        const teacherName =
          record.course.owner.first_name + " " + record.course.owner.last_name;
        return (
          <div>
            <img src={img} className="profile-teacherImg" alt="" />{" "}
            {teacherName}{" "}
          </div>
        );
      }
    },
    {
      key: "title",
      dataIndex: "course.title",
      title: "Title",
      render: (text, record) => (
        <span>
          <Link to={`/PostPage/${record.course.id}`}>{text}</Link>
        </span>
      )
    },
    {
      key: "date_start",
      dataIndex: "date_start",
      title: "Date",
      render: (text, record) => {
        return <>{moment(record.date_start).format("YYYY-MM-DD hh:mm A")}</>;
      }
    },
    {
      key: "online_address",
      dataIndex: "course.address.id",
      title: "Online Address",
      render: (text, record) => (
        <span>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={record.course.address.online_contact}
          >
            {record.course.address.online_contact}
          </a>
        </span>
      )
    }
  ];

  return <Table dataSource={data} columns={columns} rowKey={"id"} />;
};

const ProfileTabsOnlineClassesSimple = ({ list }) => (
  <div className="profile-class-con">
    <div className="justifyRight">
      <Button>
        <Link to="/explore">view more classes</Link>
      </Button>
    </div>
    {list.length !== 0 && <Tbl data={list} />}
    <Row>
      {list.length === 0 && (
        <div className="d-block w-100">
          <Empty
            description={
              <span>
                Hello, there! <a href="#API">Register a class now!</a>
              </span>
            }
          />
        </div>
      )}
    </Row>
  </div>
);

export default ProfileTabsOnlineClassesSimple;
