/* eslint-disable no-unused-vars */
import Button from "antd/lib/button";
import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Upload from "antd/lib/upload";

import "antd/dist/antd.css";
import React from "react";
import "./ApproveMarkCom.css";
import { Link } from "react-router-dom";

import { DataServiceStatic } from "../../services/DataService";

class Demo extends React.Component {
  constructor() {
    super();
    this.fileList = [];
    this.state = {
      uploadActive: false,
      isLoading: false,
      uploaded: false
    };
  }
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      console.log("[ApproveMarkCom.js] fieldsValue", fieldsValue);
      if (!err) {
        this.setState({ isLoading: true });
        const formData = new FormData();
        this.fileList.forEach((file, idx) => {
          formData.append("file" + idx, file);
        });

        DataServiceStatic.uploadFile(formData, (err, data) => {
          console.log("[ApproveMarkCom.js] err, data", err, data);
          this.setState({ isLoading: false, uploaded: true });
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const upProps = {
      beforeUpload: file => {
        this.fileList.push(file);
        this.setState({ uploadActive: true });
        return false;
      }
    };
    return (
      <Form onSubmit={this.handleSubmit} className="uploading-certificate">
        <h4>
          <Icon type="file-protect" className="icon" />
          Proof of employment or proof of enrollment
        </h4>
        <Form.Item label="">
          <p>
            upload image that approves you is currently an employee or graduate
            or student.
          </p>
          {getFieldDecorator("file1", {
            rules: [
              {
                required: false,
                message: "Please upload document"
              }
            ]
          })(
            <Upload {...upProps}>
              <Button>
                <Icon type="upload" /> Upload
              </Button>
            </Upload>
          )}
        </Form.Item>

        <h4 className="extra-file">
          <Icon type="file-protect" className="icon" />
          Extra certificate documents
        </h4>
        <Form.Item>
          <p>you can upload extra certificate documents</p>
          {getFieldDecorator("file2", {
            rules: [
              {
                required: false,
                message: "Please upload document"
              }
            ]
          })(
            <Upload {...upProps}>
              <Button>
                <Icon type="upload" /> Upload
              </Button>
            </Upload>
          )}
        </Form.Item>
        <Form.Item wrapperCol={{}} className="ap-after">
          {this.state.uploaded ? (
            <div>
              <h3>Thank you!</h3>
              <p>
                administration will validate your documents and contact you soon
                as possible.
              </p>
              <br />
              <Link to="/CourseType" className="ap-c-btn">
                Create class first
              </Link>
            </div>
          ) : (
            <Button
              type="primary"
              disabled={!this.state.uploadActive}
              htmlType="submit"
              className="verified-sumbit"
              loading={this.state.isLoading}
            >
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDemo = Form.create({ name: "validate_other" })(Demo);

export default WrappedDemo;
