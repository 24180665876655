import React from "react";
import { connect } from "react-redux";
import Icon from "antd/lib/icon";

import "./WishHeart.css";
import { DataServiceStatic } from "../services/DataService";

const onClickWish = props => {
  if (props.isOn) {
    DataServiceStatic.delWishlist(props.wishlistObj.id);
  } else {
    DataServiceStatic.addWishlist(props.course_id);
  }
};

const WishHeartIn = props => {
  if (!props.user) {
    return <></>;
  }
  return (
    <div
      className="WishHeart heart"
      data-user={props.user}
      onClick={() => onClickWish(props)}
    >
      {!props.isOn && (
        <Icon
          type="heart"
          alt="wishlist"
          theme="twoTone"
          twoToneColor="#white"
        />
      )}
      {props.isOn && (
        <Icon type="heart" theme="filled" className="filledheart" />
      )}
    </div>
  );
};

const mapState = (state, ownProps) => {
  return {
    user: !!state.login.user.id,
    isOn: !!state.data.wishlist.find(d => d.course_id === ownProps.course_id),
    wishlistObj: state.data.wishlist.find(
      d => d.course_id === ownProps.course_id
    )
  };
};

export const WishHeart = connect(mapState)(WishHeartIn);
