import Spin from "antd/lib/spin";
import Anchor from "antd/lib/anchor";
import Rate from "antd/lib/rate";

import React from "react";
import { Col, Row, Grid } from "../../ui";

import TextWidgetPreviews from "../../components/TextWidgetPreview.js";
import CoursesService from "../../services/CoursesService";
import { DataServiceStatic } from "../../services/DataService";
import ProfileService from "../../services/ProfileServise";
import ImgRotation from "./imgRotation/ImgRotationAntd";
import PostContentTop from "./postContentTop/PostContentTop";

import PostRegisterSection from "./postRegisterSection/PostRegisterSection";

import PostRegisterSectionSM from "./postRegisterSectionSM/PostRegisterSectionSM";
import PostReviews, { PostReviewWrite } from "./postReviews/PostReviews";
import PostMap from "./postMap/PostMap";
import "./postPage.css";

const { Link } = Anchor;
class Post extends React.Component {
  constructor(props) {
    super(props);
    this.courses = new CoursesService();
    this.profiles = new ProfileService();
    this.state = {
      course: {},
      isLoading: true,
      profile: {},
      reviews: [],
      positionArr: []
    };
  }
  componentDidMount() {
    const path = window.location.pathname.split("/");
    const courseId = path[2];
    if (courseId) {
      DataServiceStatic.loadCourse(courseId, (err, course) => {
        const profile = this.profiles.getProfile(1);
        this.setState({
          course: course,
          isLoading: false,
          profile: profile
        });
        console.log("course x", course);
      });
      DataServiceStatic.loadReviews(courseId, (err, data) => {
        this.setState({ reviews: data });
      });
    }
  }

  render() {
    return (
      <div className="footer-bottom-margin">
        <PostRegisterSectionSM
          profile={this.state.profile}
          price={this.state.course.price}
          title={this.state.course.title}
          rating={this.state.course.rating}
          courseId={this.state.course.id}
          tooLate={this.state.course.too_late}
          is_free={this.state.course.is_free}
        />
        <Spin spinning={this.state.isLoading} size="large">
          <Grid className="postPage-con">
            <Row>
              <Col className="" sm={12} lg={8}>
                {!this.state.isLoading && (
                  <ImgRotation course={this.state.course} />
                )}
                <Anchor className="topnav">
                  <Link href="#classinfo" title="Info" className="menursvp" />
                  <Link href="#tutor" title="About Tutor" className=" " />
                  <Link href="#curriculum" title="Curriculum" />
                  <Link href="#map" title="Map" className="" />
                </Anchor>
                {!this.state.isLoading && (
                  <PostContentTop
                    category={this.state.course.category}
                    title={this.state.course.title}
                    profile={this.state.profile}
                    price={this.state.course.price}
                    size={this.state.course.size}
                    address={this.state.course.address}
                    language={this.state.course.language}
                    course={this.state.course}
                  />
                )}

                <div className="bar PP-content-padding" id="tutor">
                  <h4 className="md-title">About tutor</h4>
                  <TextWidgetPreviews
                    value={this.state.course.about_teacher_html}
                  />
                </div>
                <div className="bar PP-content-padding" id="classinfo">
                  <h4 className="md-title">Class brief</h4>
                  <TextWidgetPreviews value={this.state.course.brief_html} />
                </div>
                <div className="bar PP-content-padding" id="">
                  <h4 className="md-title">Requirement and Note</h4>
                  <TextWidgetPreviews value={this.state.course.requirement} />
                </div>
                <div className="bar PP-content-padding">
                  <h4 className="md-title">Who can come</h4>
                  <TextWidgetPreviews value={this.state.course.audience_html} />
                </div>
                <div className="bar PP-content-padding" id="curriculum">
                  <h4 className="md-title">Curriculum</h4>
                  <TextWidgetPreviews
                    value={this.state.course.curriculum_html}
                  />
                </div>
                <div className="bar PP-content-padding" id="review">
                  <Row className="">
                    <Col xs={7} sm={9}>
                      <h4 className="md-title">Review</h4>
                    </Col>
                    <Col xs={5} sm={3}>
                      {!this.state.isLoading && (
                        <PostReviewWrite
                          courseTitle={this.state.course.title}
                          courseId={this.state.course.id}
                        />
                      )}
                    </Col>
                  </Row>
                  <div className="totalReview">
                    {!this.state.isLoading && (
                      <div>
                        <Rate
                          allowHalf
                          value={this.state.course.rating.average_rating}
                          disabled
                        />{" "}
                        {this.state.course.rating.average_rating} (
                        {this.state.course.review_count})
                      </div>
                    )}
                  </div>
                  <PostReviews reviews={this.state.reviews} />
                </div>
                <div className="bar PP-content-padding" id="map">
                  <h4 className="md-title">Map</h4>
                  {!this.state.isLoading &&
                  this.state.course.address.osm_lat &&
                  this.state.course.address.osm_lon ? (
                    <PostMap
                      lat={this.state.course.address.osm_lat}
                      lng={this.state.course.address.osm_lon}
                    />
                  ) : (
                    <div>Online Class</div>
                  )}
                  {/* <div className="imgCon">
                  <img src={Map} alt="map" />
                </div> */}
                </div>
              </Col>
              <Col className="" sm={12} lg={4}>
                {!this.state.isLoading && (
                  <PostRegisterSection
                    profile={this.state.profile}
                    course={this.state.course}
                    rating={this.state.course.rating}
                    title={this.state.course.title}
                    category={this.state.course.category}
                    size={this.state.course.size}
                  />
                )}
              </Col>
            </Row>
          </Grid>
        </Spin>
        {/* <Grid className="related-content-con">
          <h4 className="md-title">Similar classes</h4>
          <div className=" ">
            <Row>
              <Col xs={3}>
                <div className="thumb">
                  <div className="imgCon">
                    <img src={Map} alt="map" />
                  </div>
                  <p className="thumb-category-text">Adventure</p>
                  <h4 className="thumb-title">Day shift sailing Adventure</h4>
                  <p>$90 per hour</p>
                </div>
              </Col>
            </Row>
          </div>
        </Grid> */}
        {/* <Grid className="keep-exploring-con">
          <h4 className="md-title">Keep exploring</h4>
          <div className=" ">
            <Row>
              <Col xs={6} sm={3}>
                <div className="thumb">Sport</div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="thumb">Design</div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="thumb">Marketing</div>
              </Col>
              <Col xs={6} sm={3}>
                <div className="thumb"> Show all classes</div>
              </Col>
            </Row>
          </div>
        </Grid> */}
      </div>
    );
  }
}
export default Post;
