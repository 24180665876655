import React from "react";
import "./Verification.css";

import Form from "antd/lib/form";
import Radio from "antd/lib/radio";
import Icon from "antd/lib/icon";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Input from "antd/lib/input";
import DatePicker from "antd/lib/date-picker";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

import moment from "moment";

import { DataServiceStatic } from "../../../services/DataService";
const { MonthPicker } = DatePicker;

class EditEduInner extends React.Component {
  constructor() {
    super();
    this.state = {
      isSaving: false
    };
  }
  componentWillMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    this.setState({ user });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;
      const endDateTmp = fieldsValue["end_date"];
      let end_date = null;
      // TODO: fix dates, set DD to be first of the month, now it's current day.
      if (endDateTmp) {
        end_date = endDateTmp.format("YYYY-MM-DD");
      }

      const values = {
        ...fieldsValue,
        start_date: fieldsValue["start_date"].format("YYYY-MM-DD"),
        end_date: end_date
      };

      if (!err) {
        console.log("Received values of form: ", values);
        this.setState({ isSaving: true });
        DataServiceStatic.userUpdateProfile(values, (err, data) => {
          console.log("[EditEdu.js] data", data);
          this.setState({ isSaving: false });
          if (!err) {
            message.success("Saved!");
          }
        });
      }
    });
  };

  normFile = e => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  initValHelper = name => {
    if (this.state.user[name]) {
      return this.state.user[name];
    }
    return "";
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const configStart = {
      initialValue: moment(this.state.user.start_date || new Date()),
      rules: [
        { type: "object", required: true, message: "Please select date!" }
      ]
    };
    const endDate = this.state.user.end_date
      ? moment(this.state.user.end_date)
      : null;
    const configEnd = {
      initialValue: endDate,
      rules: [{ type: "object" }]
    };

    const still_holding_position2 = this.state.user.still_holding_position
      ? "yes"
      : "no";

    const formItemLayout = {
      labelCol: {
        xs: { span: 6 }
        // sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 18 }
        // sm: { span: 16 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 4
        }
      }
    };

    return (
      <>
        <Form
          {...formItemLayout}
          onSubmit={this.handleSubmit}
          className="EditEduCon"
        >
          <Form.Item className="flex occupation" label="Occupation">
            {getFieldDecorator("occupation", {
              initialValue: this.initValHelper("occupation") || "WORKING",
              rules: [
                {
                  required: true,
                  message: "Please select occupation"
                }
              ]
            })(
              <Radio.Group>
                <Radio value="WORKING">Working</Radio>
                <Radio value="STUDENT">Student</Radio>
                <Radio value="GRADUATE">Graduate</Radio>
              </Radio.Group>
            )}
          </Form.Item>

          <Form.Item className="w60 flex" label="Company or School">
            {getFieldDecorator("occupation_company_name", {
              initialValue: this.initValHelper("occupation_company_name"),
              rules: [
                {
                  required: true,
                  message: "Please enter your company name"
                }
              ]
            })(<Input placeholder="ex) UBC or Microsoft" />)}
          </Form.Item>

          <Form.Item className="w60 flex" label="Position or Major">
            {getFieldDecorator("position_name", {
              initialValue: this.initValHelper("position_name"),
              rules: [
                {
                  required: true,
                  message: "Please enter your major"
                }
              ]
            })(<Input placeholder="ex) Software designer" />)}
          </Form.Item>
          <Form.Item className="flex" label="Start Date">
            {getFieldDecorator("start_date", configStart)(<MonthPicker />)}
          </Form.Item>
          <Form.Item className="flex" label="End Date">
            {getFieldDecorator("end_date", configEnd)(<MonthPicker />)}
          </Form.Item>
          <Form.Item className="flex" label="Still Holding Position?">
            {getFieldDecorator("still_holding_position", {
              initialValue: still_holding_position2
            })(
              <Row>
                <Col>
                  <Radio.Group defaultValue={still_holding_position2}>
                    <Radio.Button value="yes">Yes</Radio.Button>
                    <Radio.Button value="no">No</Radio.Button>
                  </Radio.Group>
                </Col>
              </Row>
            )}
          </Form.Item>

          <Form.Item className="w60 flex" label="Linkedin">
            {getFieldDecorator("linkedin_url", {
              initialValue: this.initValHelper("linkedin_url")
            })(<Input addonAfter={<Icon type="setting" />} />)}
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button
              className="next-btn"
              type=""
              htmlType="submit"
              loading={this.state.isSaving}
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}

const EditEdu = Form.create({ name: "validate_other" })(EditEduInner);

export default EditEdu;
