import Switch from "antd/lib/switch";
import Table from "antd/lib/table";
import Button from "antd/lib/button";
import Popconfirm from "antd/lib/popconfirm";

import moment from "moment";
import React from "react";

import { Link } from "react-router-dom";
import { DataServiceStatic } from "../../../services/DataService";

import "./ProfileTabsOpenedCourses.css";

class ProfileTabsOpenedCourses extends React.Component {
  constructor() {
    super();
    this.columns = [
      {
        key: "title",
        dataIndex: "title",
        title: "Title",
        render: (text, record) => (
          <span>
            <Link to={`/OpenCourse/${record.id}/edit`}>{record.title}</Link>
          </span>
        )
      },
      {
        title: "Status",
        key: "status",
        render: (text, record) => {
          return (
            <div className="status-switch">
              <Switch
                checkedChildren="PUBLISHED"
                unCheckedChildren="DRAFT"
                onChange={on => this.onChange(record, on)}
                defaultChecked={record.status.toUpperCase() === "PUBLISHED"}
                disabled={record.status.toUpperCase() === "DRAFT"}
              />
            </div>
          );
        }
      },
      {
        title: "",
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
          <span>
            <Link to={`/PostPage/${record.id}`}>Preview</Link>
          </span>
        )
      },
      {
        title: "Actions",
        dataIndex: "delete",
        // key: "id",
        render: (text, record) => (
          <span>
            <Popconfirm
              title="Are you sure delete?"
              onConfirm={e => this.onDelete(record.id)}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button type="danger">Delete</Button>
            </Popconfirm>
          </span>
        )
      }
    ];
    this.state = {
      openedCourses: [],
      loading: true
    };
  }
  componentDidMount() {
    DataServiceStatic.loadMyCourses((err, data) => {
      console.log("[UserProfile.js] my courses", data);
      this.setState({ openedCourses: data, loading: false });
    });
  }
  onDelete = id => {
    this.setState({ loading: true });

    DataServiceStatic.deleteCourse(id, (err, _) => {
      if (err) {
        console.error("cant", err);
      } else {
        this.componentDidMount();
      }
    });
  };
  onChange = (record, on) => {
    // We only let to make public from post page, because it course has to be validated before.
    console.log("[ProfileTabsOpenedCourses.js] record, on", record, on);
    if (on) {
    } else {
      if (on) {
        record.status = "PUBLISHED";
      } else {
        record.status = "DRAFT";
      }

      DataServiceStatic.updateCourse(record.id, record, (err, data) => {
        // console.log("[ProfileTabsOpenedCourses.js] data", data);
        const openedCourses = [...this.state.openedCourses];
        const index = this.state.openedCourses.findIndex(
          d => d.id === record.id
        );
        openedCourses[index] = data;
        // console.log("[ProfileTabsOpenedCourses.js] opened", openedCourses);
        this.setState({ openedCourses });
      });
    }
  };
  render() {
    let sortedArray = this.state.openedCourses.sort(
      (b, a) => moment(a.created_on).valueOf() - b.created_on.valueOf()
    );
    return (
      <div className="ProfileTabsOpenedCourses">
        <div className="flex flex-space-between openclass-btn">
          <p className="note">
            *Note: If you might have to cancel the class because there are not
            enough students registered or other reasons, YOU MUST WRITE A NOTE.
            Also the class HAVE TO CANCEL before 3 DAYS.
          </p>
          <Button variant="primary" type="link" style={{ border: "1px solid" }}>
            <Link to="/CourseType">create a class</Link>
          </Button>
        </div>
        <Table
          loading={this.state.loading}
          dataSource={sortedArray}
          columns={this.columns}
          rowKey={"id"}
        />
      </div>
    );
  }
}

export default ProfileTabsOpenedCourses;
