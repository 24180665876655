import React from "react";
import { Col } from "../../ui";

class RegisterStep extends React.Component {
  render() {
    return (
      <div className="container margin-con ">
        <h3 className="md-title center m-bottom">
          Get on the Fast Track to Knowledge
        </h3>
        <div className="row">
          <Col className="m-sm-bottom" xs={12} sm={12} lg={3}>
            <div className="circle">1</div>
            <h5 className="sm-title">Find Your Ideal Class</h5>
            <p className="sm-p">
              Search for any class you desire. Choose from the list of flexible
              options, including normal classes, tutoring sessions, and
              online/offline courses.
            </p>
          </Col>

          <Col className="m-sm-bottom" xs={12} sm={12} lg={3}>
            <div className="circle">2</div>
            <h5 className="sm-title">Register in Seconds</h5>
            <p className="sm-p">
              When you’re ready, log in and register. You’ll be learning new
              experiences in no time.
            </p>
          </Col>
          <Col className="m-sm-bottom" xs={12} sm={12} lg={3}>
            <div className="circle">3</div>
            <h5 className="sm-title">Get Confirmation</h5>
            <p className="sm-p">
              Once you register for your class, your teacher will send you an
              confirmation email so you’ll both be connected.
            </p>
          </Col>
          <Col className="m-sm-bottom" xs={12} sm={12} lg={3}>
            <div className="circle">4</div>
            <h5 className="sm-title">Start Learning!</h5>
            <p className="sm-p">
              Once the class times are set, you’re ready to go!
            </p>
          </Col>
        </div>
      </div>
    );
  }
}

export default RegisterStep;
