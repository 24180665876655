import React from "react";
import PropTypes from "prop-types";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

class TextWidget extends React.Component {
  constructor(props) {
    super(props);
    let st = EditorState.createEmpty();
    if (this.props.value.trim().length > 1) {
      try {
        const obj = JSON.parse(this.props.value);
        st = EditorState.createWithContent(convertFromRaw(obj));
      } catch (e) {
        console.warn("Cant parse json to draftjs", e);
      }
    }
    this.state = {
      editorState: st,
      count: this.getWordCount(st)
    };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      count: this.getWordCount(editorState)
    });
  };
  onBlur() {
    this.props.onChange({
      obj: convertToRaw(this.state.editorState.getCurrentContent()),
      raw: JSON.stringify(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      html: draftToHtml(
        convertToRaw(this.state.editorState.getCurrentContent())
      ),
      wordCount: this.state.count
    });
  }

  getWordCount(editorState) {
    const plainText = editorState.getCurrentContent().getPlainText("");
    const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
    const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
    const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
    return wordArray ? wordArray.length : 0;
  }
  render() {
    // settings https://jpuri.github.io/react-draft-wysiwyg/#/docs?_k=jjqinp
    // TODO on the class breaf add embeded youtube
    const defaultOptions = [
      "inline",
      "blockType",
      "fontFamily",
      "list",
      "link",
      "image"
    ];
    const op = [...defaultOptions, ...this.props.extraOptions];
    const color = this.state.count >= 30 ? "green" : "red";
    return (
      <div>
        <Editor
          toolbar={{
            options: op,
            inline: {
              options: ["bold", "italic", "underline"]
            },
            list: {
              options: ["unordered", "ordered"]
            }
          }}
          editorState={this.state.editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={this.onEditorStateChange}
          onBlur={() => this.onBlur()}
        />
        Words: <strong style={{ color }}>{this.state.count}</strong>, please
        write minimum 30 words.
      </div>
    );
  }
}
export default TextWidget;

TextWidget.defaultProps = {
  extraOptions: []
};

TextWidget.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  extraOptions: PropTypes.array
};

export const getWordCount = plainText => {
  const regex = /(?:\r\n|\r|\n)/g; // new line, carriage return, line feed
  const cleanString = plainText.replace(regex, " ").trim(); // replace above characters w/ space
  const wordArray = cleanString.match(/\S+/g); // matches words according to whitespace
  return wordArray ? wordArray.length : 0;
};
