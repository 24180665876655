import React from "react";
import PropTypes from "prop-types";
import Input from "antd/lib/input";

const { Search } = Input;

export class TextFilter extends React.Component {
  onAntSearch = value => {
    this.props.onSelect(value);
    this.props.onFocus();
  };
  onChange(value) {
    this.props.onChangeValue(value);
  }
  render() {
    return (
      <div className="TextFilter">
        <Search
          placeholder="Search"
          onSearch={value => this.onAntSearch(value)}
          value={this.props.value}
          onChange={e => this.onChange(e.target.value)}
          defaultValue={this.props.defaultValue}
        />
      </div>
    );
  }
}

TextFilter.defaultProps = {
  onFocus: () => {},
  onChangeValue: () => {},
  value: ""
};

TextFilter.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onChangeValue: PropTypes.func,
  value: PropTypes.string
};
