import React from "react";
import { Map as LeafletMap, Marker, TileLayer } from "react-leaflet";
import "./PostMap.css";

const PostMap = ({ lat, lng }) => {
  const position = [lat, lng];
  const zoom = 13;
  return (
    <div className="PostMap">
      <LeafletMap center={position} zoom={zoom}>
        <TileLayer
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
        />
        <Marker position={position} />
      </LeafletMap>
    </div>
  );
};

export default PostMap;
