import React, { Component } from "react";
import {
  CardElement,
  injectStripe,
  StripeProvider,
  Elements
} from "react-stripe-elements";
import Icon from "antd/lib/icon";

import "./stripe.css";

// You can customize your Elements to give it the look and feel of your site.
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: "16px",
        color: "#424770",
        fontFamily: "Open Sans, sans-serif",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#c23d4b"
      }
    }
  };
};

class _CardForm extends Component {
  state = {
    errorMessage: ""
  };

  handleChange = ({ error }) => {
    if (error) {
      this.setState({ errorMessage: error.message });
    }
  };

  handleSubmit = ev => {
    ev.preventDefault();
    const { currentTarget } = ev;
    const fD = new FormData(currentTarget);
    const cardInfo = {
      name: `${fD.get("first-name")} ${fD.get("last-name")}`,
      address_line1: fD.get("address_line1"),
      address_line2: fD.get("address_line2"),
      address_city: fD.get("address_city"),
      address_state: fD.get("address_state"),
      address_zip: fD.get("address_zip"),
      address_country: fD.get("address_country")
    };

    if (this.props.stripe) {
      this.props.stripe
        .createToken({ name: cardInfo.name })
        .then(this.props.handleResult);
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  render() {
    return (
      <div className="CardDemo Stripe">
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div className="group-auto">
            <label>
              Card details
              <CardElement onChange={this.handleChange} {...createOptions()} />
            </label>
          </div>
          <div className="Stripe-custom">
            <div className="group">
              <label>
                <span>First name</span>
                <input
                  id="first-name"
                  name="first-name"
                  className="field"
                  placeholder="Jane"
                />
              </label>
              <label>
                <span>Last name</span>
                <input
                  id="last-name"
                  name="last-name"
                  className="field"
                  placeholder="Doe"
                />
              </label>
            </div>
            <div className="group">
              <label>
                <span>Address</span>
                <input
                  id="address-line1"
                  name="address_line1"
                  className="field"
                  placeholder="77 Winchester Lane"
                />
              </label>
              <label>
                <span>Address (cont.)</span>
                <input
                  id="address-line2"
                  name="address_line2"
                  className="field"
                  placeholder=""
                />
              </label>
              <label>
                <span>City</span>
                <input
                  id="address-city"
                  name="address_city"
                  className="field"
                  placeholder="Coachella"
                />
              </label>
              <label>
                <span>State</span>
                <input
                  id="address-state"
                  name="address_state"
                  className="field"
                  placeholder="CA"
                />
              </label>
              <label>
                <span>ZIP</span>
                <input
                  id="address-zip"
                  name="address_zip"
                  className="field"
                  placeholder="92236"
                />
              </label>
              <label>
                <span>Country</span>
                <input
                  id="address-country"
                  name="address_country"
                  className="field"
                  placeholder="United States"
                />
              </label>
            </div>
          </div>
          <div className="error" role="alert">
            {this.state.errorMessage}
          </div>
          <button className="checkout-btn">
            <Icon type="lock" theme="filled" />
            Confirm and Pay
          </button>
        </form>
      </div>
    );
  }
}

const CardForm = injectStripe(_CardForm);

export class CardDemo extends Component {
  render() {
    return (
      // <StripeProvider apiKey={this.props.stripePublicKey}>
      <StripeProvider apiKey={"pk_test_LoEXiFUYNVfOWEjIVNPB3Q6j00KS0BXA21"}>
        <Elements>
          <CardForm handleResult={this.props.handleResult} />
        </Elements>
      </StripeProvider>
    );
  }
}

export default CardDemo;
