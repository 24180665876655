/* eslint-disable no-unused-vars */
import Icon from "antd/lib/icon";

import React from "react";
import { Col, Row } from "../../../ui";

import { LOCATIONS } from "../../../constants";
import { courseHoursCalc } from "../../../services/Utils";
import { getUserImage } from "../../../services/DataService";
import MessagePopup from "../../../routes/message/MessagePopup";

import "./postContentTop.css";

class PostContentTop extends React.Component {
  render() {
    const {
      images,
      title,
      time,
      size,
      rating,
      address,
      language,
      size_max,
      profile,
      category,
      type,
      date,
      owner,
      requirement
    } = this.props.course;

    const loc = LOCATIONS.find(l => l.key === address.location);
    const hoursTotal = courseHoursCalc(date, type);
    const teacherImg = getUserImage(owner);
    return (
      <div className="brief-Con">
        <Row className="tutor-brief">
          <Col xs={12} md={3}>
            <Row className="profile">
              <Col xs={6} sm={12}>
                <div className="imgCon">
                  <img src={teacherImg} alt="owner" width="50" />
                </div>
              </Col>
              <Col xs={6} sm={12} className="message-btn-name">
                <MessagePopup course={this.props.course} />
              </Col>
            </Row>
          </Col>
          {/* <Col xs={12} sm={1} md={0} /> */}
          <Col xs={12} sm={12} md={9}>
            <Row className="postContentTopCon">
              <Col className="col-6 col-sm-3">
                <div className="iconCon">
                  <Icon type="environment" />
                </div>
                <p className="PR-location-con"> {loc ? loc.name : "N/A"}</p>
              </Col>
              <Col className="col-6 col-sm-3">
                <div className="iconCon">
                  <Icon type="clock-circle" />
                </div>
                <p className="PR-location-con">{hoursTotal}</p>
              </Col>
              <Col className="col-6 col-sm-3">
                <div className="iconCon">
                  <Icon type="global" />
                </div>
                <p className="PR-location-con">{language.name}</p>
              </Col>

              <Col className="col-6 col-sm-3">
                <div className="iconCon">
                  <Icon type="team" />
                </div>
                <p className="PR-location-con"> {size_max} people</p>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default PostContentTop;
