import React from "react";
import { Link } from "react-router-dom";
import "./contactusTag.css";

class Help extends React.Component {
  render() {
    return (
      <div className="help-contatctus-con">
        <div className="flex help-contactus">
          <p>Still need help?</p>
          <Link to="ContactUs">Contact us</Link>
        </div>
      </div>
    );
  }
}
export default Help;
