import React from "react";
import "./Verification.css";

import Form from "antd/lib/form";
import Icon from "antd/lib/icon";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import Input from "antd/lib/input";
import Alert from "antd/lib/alert";

import PhoneVerificationModal from "./PhoneVerificationModal";

import { DataServiceStatic } from "../../../services/DataService";

class VerificationInner extends React.Component {
  constructor() {
    super();
    this.state = {
      isSaving: false,
      phoneVerLoading: false,
      phoneVerVisible: false,
      phoneVerSuccess: 0,
      failed: false,
      failMsg: ""
    };
  }
  componentWillMount() {
    const user = DataServiceStatic.userGetMySelfCache();
    this.setState({ user });
  }

  onPhoneVerCancel = () => {
    this.setState({ phoneVerVisible: false });
  };

  onPhoneVerOk = code => {
    this.setState({ phoneVerLoading: true });
    DataServiceStatic.userSendCode(code, (err, data) => {
      console.info("resp", data);
      let success = 1;
      if (data.phone_status === "approved") {
        success = 1;
        const user = { ...this.state.user };
        user.phone_status = "approved";
        this.setState({ user });
      }
      if (data.phone_status === "fail") {
        success = 3;
      }
      if (data.phone_status === "pending") {
        success = 3;
      }
      if (err) {
        success = 3;
      }
      this.setState({ phoneVerLoading: false, phoneVerSuccess: success });
      if (success === 1) {
        setTimeout(() => {
          this.setState({ phoneVerVisible: false });
        }, 7000);
        this.props.onSuccessCallback();
      }
    });
  };

  sendTextToVerify = () => {
    this.props.form.validateFields((err, fieldsValue) => {
      this.setState({ phoneVerVisible: true });

      DataServiceStatic.userSendText(fieldsValue.phone, true, (error, data) => {
        if (error.status === 400) {
          this.setState({
            failed: true,
            failMsg: error.data[0],
            phoneVerVisible: false
          });
        }
      });
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, fieldsValue) => {
      if (err) return;

      const phone = fieldsValue["phone"];
      const values = {
        phone
      };
      if (!err) {
        this.setState({ isSaving: true });
        DataServiceStatic.userUpdateProfile(values, (err, data) => {
          this.setState({ isSaving: false });
          if (!err) {
            message.success("Saved!");
          }
        });
      }
    });
  };

  initValHelper = name => {
    if (this.state.user[name]) {
      return this.state.user[name];
    }
    return "";
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const phone_disabled = this.state.user.phone_status === "approved";

    const formItemLayout = {
      labelCol: {
        xs: { span: 4 }
        // sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 18 }
        // sm: { span: 16 }
      }
    };

    return (
      <>
        <Form
          onSubmit={this.handleSubmit}
          className="verificationCon"
          {...formItemLayout}
        >
          <div className="phone-status">
            <div>
              {" "}
              {phone_disabled && (
                <>
                  <Icon type="check-circle" style={{ color: "green" }} /> Phone
                  Verified.
                  <div>
                    If you need to update the phone number please contact us.
                  </div>
                </>
              )}
              {!phone_disabled && <h3>Please verify phone number.</h3>}
            </div>
          </div>
          <Form.Item label="Phone Number" className="flex phone">
            {getFieldDecorator("phone", {
              initialValue: this.initValHelper("phone"),
              rules: [
                { required: true, message: "Please enter your phone number." },
                { len: 11, message: "Phone number has to be 11 char long." },
                {
                  pattern: new RegExp("^[0-9]+$"),
                  message: "Please enter numbers only."
                }
              ]
            })(
              <Input style={{ width: "100%" }} disabled={phone_disabled} />
            )}{" "}
            {!phone_disabled && (
              <div>
                <Button onClick={this.sendTextToVerify}>
                  Send Text to Verify
                </Button>
                <em>
                  *Please add 1 in-front of your phone number
                  ex)17780000000(Canada){" "}
                </em>
              </div>
            )}
          </Form.Item>
          <Form.Item>
            {this.state.failed && (
              <>
                <Alert
                  message={this.state.failMsg}
                  type="error"
                  showIcon
                ></Alert>
              </>
            )}
          </Form.Item>
        </Form>
        <PhoneVerificationModal
          visible={this.state.phoneVerVisible}
          loading={this.state.phoneVerLoading}
          onOk={val => this.onPhoneVerOk(val)}
          onCancel={this.onPhoneVerCancel}
          success={this.state.phoneVerSuccess}
        />
      </>
    );
  }
}

const PhoneVerificationPage = Form.create({ name: "validate_other" })(
  VerificationInner
);

export default PhoneVerificationPage;
