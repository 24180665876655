import profile1 from "../routes/images/profile.png";

class ProfileService {
  getProfiles = (limit = 0) => {
    if (limit > 0) {
      return fakeProfile.slice(0, limit);
    }
    return fakeProfile;
  };
  getProfile = id => {
    return fakeProfile.find(item => item.id === id) || {};
  };
  getCurrentUser = () => {
    return this.getProfile(1);
  };
}

export default ProfileService;

const fakeProfile = [
  {
    id: 1,
    user: {
      name: "Jisu",
      id: 1
    },
    interest: [
      {
        id: 1,
        subject: "Art"
      },
      {
        id: 2,
        title: "Design"
      },
      {
        id: 3,
        title: "marketing",
        main: false
      }
    ],
    email: "ejisu@gmail.com",
    phone_number: 604778862,
    profile_img: profile1
  }
];
