import Modal from "antd/lib/modal";
import Form from "antd/lib/form";
import Input from "antd/lib/input";

import React from "react";

export const DeclineConfirmForm = Form.create({ name: "form_in_modal" })(
  // eslint-disable-next-line
  class extends React.Component {
    onConfirm = () => {
      const form = this.props.form;
      form.validateFields((err, values) => {
        if (err) {
          return;
        }

        console.log("Received values of form: ", values);
        form.resetFields();
        this.props.onConfirm(values);
      });
    };
    render() {
      const { onCancel, form } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={true}
          title="Please specify reason"
          okText="Confirm"
          onCancel={onCancel}
          onOk={this.onConfirm}
        >
          <Form layout="vertical">
            <Form.Item label="Reason">
              {getFieldDecorator("reason", {
                rules: [{ required: true, message: "Please fill this field!" }]
              })(<Input type="textarea" />)}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);
