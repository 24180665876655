import dog1 from "../dogs/dog1.jpg";
import dog2 from "../dogs/dog2.jpg";
import cat1 from "../dogs/cat1.jpeg";

class CoursesService {
  getCourses = (limit = 0) => {
    if (limit > 0) {
      return fakeCourses.slice(0, limit);
    }
    return fakeCourses;
  };
  getCourse = id => {
    return fakeCourses.find(item => item.id === id) || {};
  };
  getRecommended = (categoryId = 0) => {
    return fakeCourses.find(item => item.is_recommended === true);
  };
}

export default CoursesService;

const fakeCourses = [
  {
    id: 1,
    owner: "admin",
    title: "Test 1: photoshop begining class ",
    category: "Marketing Writing",
    rating: 4,
    images: [
      {
        id: 1,
        url: dog1,
        main: true
      },
      {
        id: 2,
        url: dog2,
        main: false
      },
      {
        id: 3,
        url: cat1,
        main: false
      }
    ],
    time: [
      {
        id: 1,
        day: "2018-02-01",
        time: "18:00:00"
      },
      {
        id: 2,
        day: "2018-02-03",
        time: "17:00:00"
      },
      {
        id: 3,
        day: "2018-02-05",
        time: "19:00:00"
      }
    ],
    demofield: "demofield",
    language: {
      id: 1,
      name: "English"
    },
    address: {
      is_online: false,
      location: "Van",
      place: "cafe",
      full_address: "Kits, Van"
    },
    size: 2,
    main_image: "abc.png",
    price: 50.99,
    compare_price: 300.99,
    is_discounted: false,
    is_recommended: true,
    extra_charge: 50.12,
    is_free: true,
    requirement: "pencil",
    curriculum: "curriculum",
    brief: "This is begining of photoshop course",
    come: "who are interested in learning photoshop"
  },
  {
    id: 2,
    owner: "admin",
    title: "This is admin test this is admin test",
    rating: 5,
    images: [
      {
        id: 1,
        url: dog1,
        main: false
      },
      {
        id: 2,
        url: dog2,
        main: true
      },
      {
        id: 3,
        url: cat1,
        main: false
      }
    ],
    category: "JavaSript",
    time: [
      {
        id: 4,
        day: "2019-01-19",
        time: "23:36:45"
      },
      {
        id: 3,
        day: "2018-02-05",
        time: "19:00:00"
      }
    ],
    demofield: "demofield",
    language: {
      id: 3,
      name: "Lithuanian"
    },
    address: {
      is_online: false,
      location: "Van",
      place: "cafe",
      full_address: "Kits, Van"
    },
    size: 3,
    main_image: "img.png",
    price: 30.0,
    compare_price: 24.0,
    is_discounted: true,
    is_recommended: false,
    extra_charge: 6.0,
    is_free: false,
    requirement: "pc or mac",
    curriculum: "teach you all",
    brief: "this is begining of photoshop course",
    come: "who are interested in learning photoshop"
  },
  {
    id: 3,
    owner: "admin",
    title: "This is admin test this is admin test",
    rating: 5,
    images: [
      {
        id: 1,
        url: dog1,
        main: false
      },
      {
        id: 2,
        url: dog2,
        main: false
      },
      {
        id: 3,
        url: cat1,
        main: true
      }
    ],
    category: "JavaSript",
    time: [
      {
        id: 4,
        day: "2019-01-19",
        time: "23:36:45"
      }
    ],
    demofield: "demofield",
    language: {
      id: 3,
      name: "Lithuanian"
    },
    address: {
      is_online: false,
      location: "Van",
      place: "cafe",
      full_address: "Kits, Van"
    },
    size: 3,
    main_image: "img.png",
    price: 30.0,
    compare_price: 24.0,
    is_discounted: true,
    is_recommended: false,
    extra_charge: 6.0,
    is_free: false,
    requirement: "pc or mac",
    curriculum: "teach you all",
    brief: "this is begining of photoshop course",
    come: "who are interested in learning photoshop"
  },
  {
    id: 4,
    owner: "admin",
    title: "this is admin test",
    rating: 5,
    images: [
      {
        id: 1,
        url: dog1,
        main: true
      },
      {
        id: 2,
        url: dog2,
        main: false
      },
      {
        id: 3,
        url: cat1,
        main: false
      }
    ],
    category: "JavaSript",
    time: [
      {
        id: 4,
        day: "2019-01-19",
        time: "23:36:45"
      }
    ],
    demofield: "demofield",
    language: {
      id: 3,
      name: "Lithuanian"
    },
    address: {
      is_online: false,
      location: "Van",
      place: "cafe",
      full_address: "Kits, Van"
    },
    size: 3,
    main_image: "img.png",
    price: 30.0,
    compare_price: 24.0,
    is_discounted: true,
    extra_charge: 6.0,
    is_free: false,
    requirement: "pc or mac",
    curriculum: "teach you all",
    brief: "this is begining of photoshop course",
    come: "who are interested in learning photoshop"
  }
];
