import React from "react";
import PropTypes from "prop-types";
import Modal from "antd/lib/modal";
import Button from "antd/lib/button";
import Input from "antd/lib/input";
import Spin from "antd/lib/spin";
import Alert from "antd/lib/alert";

class PhoneVerificationModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  onChange = e => {
    this.val = e.target.value;
  };
  onOk = () => {
    this.props.onOk(this.val);
  };
  render() {
    return (
      <div className="PhoneVerificationModal">
        <Modal
          visible={this.props.visible}
          title="Phone Verification"
          onOk={this.props.onOk}
          onCancel={this.props.onCancel}
          footer={[
            <Button
              key="back"
              disabled={this.props.loading}
              onClick={this.props.onCancel}
            >
              {this.props.success === 1 && <>Close</>}
              {this.props.success !== 1 && <>Cancel</>}
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={this.props.success === 1}
              loading={this.props.loading}
              onClick={this.onOk}
            >
              Verify
            </Button>
          ]}
        >
          <Spin tip="Verification in process..." spinning={this.props.loading}>
            {this.props.success === 1 && (
              <Alert message="Phone Verified!" type="success" showIcon />
            )}
            {this.props.success === 3 && (
              <Alert
                message="Code is not correct, please try again!"
                type="warning"
                showIcon
              />
            )}
            {this.props.success !== 1 && (
              <div>
                Check your phone and enter code:{" "}
                <Input
                  onChange={this.onChange}
                  maxLength={6}
                  style={{ width: "80px" }}
                />
              </div>
            )}
          </Spin>
        </Modal>
      </div>
    );
  }
}

export default PhoneVerificationModal;

PhoneVerificationModal.propsTypes = {
  visible: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  success: PropTypes.number.isRequired, // 1 - ok, 0 - no state, 3 - error
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};
